import React from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Button,
	Container,
	FormControl,
	FormLabel,
	Input,
	Progress,
	Stack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";



import { Layout } from "../components/layout/Index";
import { useAuthorizationState } from "../contexts/AuthorizationContext";
import { TitleAndNav } from "../components/TitleAndNav";
import { useApi } from "../services";
import { Config } from "../config/Config";
import { IUser, isIProblemDetails } from "../models";
import { globalSetError, globalSetInfo, useGlobalDispatch } from "../contexts/GlobalContext";


export type FormValues = {
	userId: string;
	firstName: string;
	lastName: string;
	phone: string;
}


export const UserProfilePage = (): JSX.Element => {
	const { t } = useTranslation();
	const { user } = useAuthorizationState();
	const globalDispatch = useGlobalDispatch();
	const { isLoading, updateProfile } = useApi(Config.baseUrl);

	console.log(user);

	const formDefaults: FormValues = {
		userId: user?.id.toString() || "0",
		firstName: user?.firstName || "",
		lastName: user?.lastName || "",
		phone: user?.phone || "",
	};
	console.log(formDefaults);
	const { register, handleSubmit } = useForm<FormValues>({ defaultValues: formDefaults });

	const onSubmit = handleSubmit(async (data) => {
		console.log(data);
		const updates: IUser = {
			id: user?.id || 0,
			firstName: data.firstName,
			lastName: data.lastName,
			phone: data.phone,
			email: "",
			userName: "",
			companyId: 0,
		};

		const result = await updateProfile(updates);
		if (isIProblemDetails(result)) {
			globalDispatch(globalSetError(t("userProfile.unableToUpdate"), t("userProfile.errorTitle")));
			return;
		}

		globalDispatch(globalSetInfo(t("userProfile.profileUpdated"), t("userProfile.infoTitle"), 5000));
	});

	return (
		<Layout>
			<Container>
				<Stack spacing="10" mt="10">
					<TitleAndNav to={"/"} title={t("userProfile.title")} />
					<form onSubmit={onSubmit}>
						<FormControl mt="10">
							<Input
								id="firstName"
								size="md"
								placeholder=" "
								data-peer
								type="text"
								{...register("firstName")}
							/>
							<FormLabel htmlFor="firstName" variant="floating" size="md">
								{t("userProfile.labelFirst")}
							</FormLabel>
						</FormControl>
						<FormControl mt="10">
							<Input
								id="lastName"
								size="md"
								placeholder=" "
								data-peer
								type="text"
								{...register("lastName")}
							/>
							<FormLabel htmlFor="lastName" variant="floating" size="md">
								{t("userProfile.labelLast")}
							</FormLabel>
						</FormControl>
						<FormControl mt="10">
							<Input
								id="phone"
								size="md"
								placeholder=" "
								data-peer
								type="text"
								{...register("phone")}
							/>
							<FormLabel htmlFor="phone" variant="floating" size="md">
								{t("userProfile.labelPhone")}
							</FormLabel>
						</FormControl>
						<input type="hidden" {...register("userId")} />
						{ !isLoading && <Button mt="10" colorScheme="blue" type="submit">{t("userProfile.buttonUpdate")}</Button> }
						{ isLoading && <Progress w="100%" mt="2em" isIndeterminate colorScheme="blue" hasStripe /> }
					</form>
				</Stack>
			</Container>
		</Layout>
	);
};


