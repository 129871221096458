export enum GlobalActionTypes {
	SET_ERROR = "@global/setError",
	SET_INFO = "@global/setInfo",
	CLEAR_ERROR = "@global/clearError",
	SET_ADMIN_ADDED_COMPANY = "@global/adminAddedCompany",
}

export type GlobalAction = {
	type: GlobalActionTypes;
	errorTitle?: string;
	errorMessage?: string;
	isErrorSet: boolean;
	isInfo: boolean;
	duration?: number;
	adminAddedCompany?: boolean;
}


export const globalSetError = (errorMessage: string, errorTitle: string, duration?: number): GlobalAction => ({
	type: GlobalActionTypes.SET_ERROR,
	errorTitle,
	errorMessage,
	duration,
	isInfo: false,
	isErrorSet: true,
});

export const globalClearError = (): GlobalAction => ({
	type: GlobalActionTypes.CLEAR_ERROR,
	isErrorSet: false,
	isInfo: false,
});

export const globalSetInfo = (errorMessage: string, errorTitle: string, duration?: number): GlobalAction => ({
	type: GlobalActionTypes.SET_INFO,
	errorTitle, 
	errorMessage,
	duration,
	isInfo: true,
	isErrorSet: true,
});


export const globalSetAdminAddedCompany = (adminAddedCompany: boolean): GlobalAction => ({
	type: GlobalActionTypes.SET_ADMIN_ADDED_COMPANY,
	isErrorSet: false,
	isInfo: false,
	adminAddedCompany,
});
