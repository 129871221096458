import { Avatar, Box, HStack, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

interface UserProfileProps {
	name: string;
	image?: string;
	email: string;
}

export const UserProfile = (props: UserProfileProps) => {
	const navigate = useNavigate();
	const { name, image, email } = props;
	const imageSrc = image ? image : "/user-light.svg";
	return (
		<HStack spacing="3" ps="2" onClick={() => navigate("/profile")} cursor="pointer">
			<Avatar name={name} src={imageSrc} boxSize="10" />
			<Box>
				<Text fontWeight="medium" fontSize="sm">
					{name}
				</Text>
				<Text color="muted" fontSize="sm">
					{email}
				</Text>
			</Box>
		</HStack>
	);
};
