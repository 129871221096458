import { useTranslation } from "react-i18next";

import {
	Accordion,
	Box,
	Card,
	Container,
	Heading,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	SimpleGrid,
	Stack,
	Text,
} from "@chakra-ui/react";

import { ProductCardHerbResistance, ProductCardWeedPrediction } from "./Products";
import { NavLink } from "react-router-dom";


export const GetStartedSteps = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Container mt="-1em" py={{ base: "16", md: "12" }}>
			<Card>
				<Box
					bg="bg.surface"
					boxShadow="sm"
					borderRadius="lg"
					p={{ base: "4", md: "6" }}
				>
					<Stack spacing="4">
						<Heading size="sm">{t("getStarted.stepsTitle")}</Heading>
						<Text>{t("getStarted.stepsInstructions")}</Text>
						<Accordion allowMultiple>
							<AccordionItem>
								<h2>
									<AccordionButton>
										<Box as="span" flex="1" textAlign="left">
											{t("getStarted.step1Title")}
										</Box>
										<AccordionIcon />
									</AccordionButton>
								</h2>
								<AccordionPanel pb={4}>
									<NavLink to="/contact-us" style={{ textDecoration: "underline" }}>
										{t("getStarted.step1Description")}
									</NavLink>
								</AccordionPanel>
							</AccordionItem>

							<AccordionItem>
								<h2>
									<AccordionButton>
										<Box as="span" flex="1" textAlign="left">
											{t("getStarted.step2Title")}
										</Box>
										<AccordionIcon />
									</AccordionButton>
								</h2>
								<AccordionPanel pb={4}>
									{t("getStarted.step2Description")}
								</AccordionPanel>
							</AccordionItem>

							<AccordionItem>
								<h2>
									<AccordionButton>
										<Box as="span" flex="1" textAlign="left">
											{t("getStarted.step3Title")}
										</Box>
										<AccordionIcon />
									</AccordionButton>
								</h2>
								<AccordionPanel pb={4}>
									{t("getStarted.step3Description")}
								</AccordionPanel>
							</AccordionItem>
						</Accordion>
					</Stack>
				</Box>
			</Card>
		</Container>
	);
};
