import { ICompany, IFarmPermission, IUser } from "../../models";

export enum AuthActionTypes {
	SET_AUTH = "@auth/setAuth",
	CLEAR_AUTH = "@auth/clearAuth",
	UPDATE_COMPANY = "@auth/updateCompany",
	UPDATE_ADMIN_COMPANY = "@auth/updateAdminCompany",
}

export type AuthAction = {
	type: AuthActionTypes;
	user?: IUser | null;
	company?: ICompany | null;
	permissions?: IFarmPermission[];
	isCompanyAdmin?: boolean;
	isGecoAdmin?: boolean;
	companyName?: string;
	companyLocation?: string;
	adminCompanyId?: number;
};


export const setAuth = (user: IUser, company: ICompany, permissions: IFarmPermission[], isCompanyAdmin: boolean, isGecoAdmin: boolean): AuthAction => ({
	type: AuthActionTypes.SET_AUTH,
	user,
	company,
	permissions,
	isCompanyAdmin,
	isGecoAdmin,
});

export const clearAuth = (): AuthAction => ({
	type: AuthActionTypes.CLEAR_AUTH,
	user: null,
	company: null,
	permissions: [],
});


export const updateCompanyInfo = (companyName: string, companyLocation?: string): AuthAction => ({
	type: AuthActionTypes.UPDATE_COMPANY,
	companyName,
	companyLocation,
});


export const setAdminCompany = (adminCompanyId: number): AuthAction => ({
	type: AuthActionTypes.UPDATE_ADMIN_COMPANY,
	adminCompanyId,
});
