import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Container, FormControl, FormLabel, Input, Progress, Select, Stack, Tabs, TabList, TabPanels, Tab, TabPanel, useToast } from "@chakra-ui/react";

import { Card } from "./layout/Card";
import { ICompany, IField, IFieldYearData } from "../models";
import { useApi, useAuthorization } from "../services";
import { Config } from "../config/Config";
import { UpdateButton } from "./UpdateButton";
import { useAuthorizationState } from "../contexts/AuthorizationContext";
import { FieldDetailsHistory } from "./FieldDetailsHistory";



function createFieldObject(f: IField): IField {
	return {
		id: f.id,
		companyId: f.companyId,
		farmId: f.farmId,
		name: f.name,
		createdBy: f.createdBy,
		createDateUtc: f.createDateUtc,
		description: f.description,
		latitude: f.latitude,
		longitude: f.longitude,
		length: f.length,
		width: f.width,
		acres: f.acres,
		dueDate: f.dueDate,
		status: f.status,
	};
}

function isNumber(s: string): number | null {
	const n = parseFloat(s);
	return isNaN(n) ? null : n;
}


function getDate(d: number | string): Date | null | undefined {
	if (d === "") {
		console.log("no date");
		return null;
	}

	return new Date(d);
}

interface IProps {
	field: IField | undefined | null;
	company: ICompany | undefined | null;
	history?: IFieldYearData[];
}


const FieldTabData = ({ field }: IProps): JSX.Element => {
	const { t } = useTranslation();
	const [ editName, setEditName ] = useState("");
	const [ editDescription, setEditDescription ] = useState("");
	const [ editAcres, setEditAcres ] = useState<number>(NaN);
	const [ editDueDate, setEditDueDate ] = useState<string>("");
	const [ editStatus, setEditStatus ] = useState("notStarted");
	const { isLoading, updateFieldDetails } = useApi(Config.baseUrl);
	const [ isValid, setIsValid ] = useState<boolean>(true);
	const toast = useToast();
	const { isGecoAdmin } = useAuthorizationState();

	useEffect(() => {
		console.log(field?.dueDate);
		const dd = field?.dueDate ? new Date(field.dueDate).toISOString().split("T")[0] : "";
		console.log(dd);
		setEditName(field?.name || "");
		setEditDescription(field?.description || "");
		setEditAcres(field?.acres || NaN);
		setEditDueDate(dd);
		setEditStatus(field?.status || "notStarted");
	}, [ field ]);

	useEffect(() => {
		setIsValid(editName !== "");
	}, [ editName ])

	const handleClick = async (): Promise<void> => {
		if (isValid && field) {
			const f = createFieldObject(field);
			f.name = editName;
			f.description = editDescription;
			f.acres = isNaN(editAcres) ? null : editAcres;
			if (isGecoAdmin) {
				const d = getDate(editDueDate);
				f.dueDate = d;
				console.log(editStatus);
				f.status = editStatus;
			}
			await updateFieldDetails(f);
		} else {
			toast({
				description: t("field.toastNameRequired"),
				position: "top",
				status: "error",
				isClosable: true,
			});
		}
	};

	return (
		<Container>
			<Stack spacing="10" mt="10">
				<FormControl>
					<Input
						id="fieldName"
						size="md"
						placeholder=" "
						data-peer
						value={editName}
						errorBorderColor="red.300"
						isInvalid={editName === ""}
						onChange={e => setEditName(e.target.value)}
					/>
					<FormLabel htmlFor="fieldName" variant="floating" size="md">
						{t("field.labelName")}
					</FormLabel>
				</FormControl>
				<FormControl>
					<Input
						id="description"
						size="md"
						placeholder=" "
						data-peer
						value={editDescription}
						onChange={e => setEditDescription(e.target.value)}
					/>
					<FormLabel htmlFor="description" variant="floating" size="md">
						{t("field.labelDescription")}
					</FormLabel>
				</FormControl>
				<FormControl>
					<Input
						id="acres"
						size="md"
						placeholder=" "
						data-peer
						value={editAcres}
						type="number"
						onChange={e => setEditAcres(parseInt(e.target.value))}
					/>
					<FormLabel htmlFor="acres" variant="floating" size="md">
						{t("field.labelAcres")}
					</FormLabel>
				</FormControl>
				{
					isGecoAdmin &&
					<FormControl>
						<Input
							id="dueDate"
							size="md"
							value={editDueDate}
							type="date"
							placeholder=" "
							data-peer
							onChange={e => setEditDueDate(e.target.value)}
						/>
						<FormLabel htmlFor="dueDate" variant="floating" size="md">
							{t("field.labelDueDate")}
						</FormLabel>
					</FormControl>
				}
				{
					isGecoAdmin &&
					<FormControl>
						<FormLabel htmlFor="fieldStatus" size="md">
							{t("field.labelStatus")}
						</FormLabel>
						<Select id="fieldStatus" size="md" value={editStatus} onChange={e => setEditStatus(e.target.value)}>
							<option value="notStarted">{t("fieldStatus.notStarted")}</option> 
							<option value="inProgress">{t("fieldStatus.inProgress")}</option> 
							<option value="done">{t("fieldStatus.done")}</option> 
							<option value="noWork">{t("fieldStatus.noWork")}</option> 
						</Select>
					</FormControl>
				}
				<UpdateButton isLoading={isLoading} onClick={handleClick} />
			</Stack>
		</Container>
	);
};

const FieldTabLocation = ({ field }: IProps): JSX.Element => {
	const { t } = useTranslation();
	const [ editLatitude, setEditLatitude ] = useState("");
	const [ editLongitude, setEditLongitude ] = useState("");
	const { isLoading, updateFieldLocation } = useApi(Config.baseUrl);
	const toast = useToast();

	useEffect(() => {
		if (field?.latitude) { setEditLatitude(field.latitude.toString()); }
		if (field?.longitude) { setEditLongitude(field.longitude.toString()); }
	}, [ field ]);

	const handleClick = async (): Promise<void> => {
		if (field) {
			const f = createFieldObject(field);
			let doUpdate = true;
			if (editLatitude === "") {
				f.latitude = null;
			} else {
				const lat = isNumber(editLatitude);
				if (lat) {
					f.latitude = lat;
				} else {
					doUpdate = false;
				}
			}
			if (editLongitude === "") {
				f.longitude = null;
			} else {
				const lng = isNumber(editLongitude);
				if (lng) {
					f.longitude = lng;
				} else {
					doUpdate = false;
				}
			}

			if (doUpdate) {
				await updateFieldLocation(f);
			} else {
				toast({
					description: t("field.toastNumberOrBlank"),
					position: "top",
					status: "error",
					isClosable: true,
				});
			}
		}
	};

	return (
		<Container>
			<Stack spacing="10" mt="10">
				<FormControl>
					<Input
						id="fieldLat"
						size="md"
						placeholder=" "
						data-peer
						value={editLatitude}
						onChange={e => setEditLatitude(e.target.value)}
					/>
					<FormLabel htmlFor="fieldLat" variant="floating" size="lg">
						{t("field.labelLatitude")}
					</FormLabel>
				</FormControl>
				<FormControl>
					<Input
						id="fieldLong"
						size="md"
						placeholder=" "
						data-peer
						value={editLongitude}
						onChange={e => setEditLongitude(e.target.value)}
					/>
					<FormLabel htmlFor="fieldLong" variant="floating" size="lg">
						{t("field.labelLongitude")}
					</FormLabel>
				</FormControl>
				<UpdateButton isLoading={isLoading} onClick={handleClick} />
			</Stack>
		</Container>
	);
};


export const FieldDetails = ({ field, company, history = [] }: IProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Card p="4">
			<Tabs>
				<TabList>
					<Tab>{t("field.tabDetails")}</Tab>
					<Tab>{t("field.tabLocation")}</Tab>
					<Tab>{t("field.tabHistory")}</Tab>
				</TabList>
				<TabPanels>
					<TabPanel><FieldTabData field={field} company={company} /></TabPanel>
					<TabPanel><FieldTabLocation field={field} company={company} /></TabPanel>
					<TabPanel><FieldDetailsHistory field={field} history={history} /></TabPanel>
				</TabPanels>
			</Tabs>
		</Card>
	);
};
