import React from "react";
import { Box, BoxProps, Button, Progress } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";


interface IProps extends BoxProps {
	onClick?: () => void;
	isLoading?: boolean;
	caption?: string;
}

export const UpdateButton = ({ isLoading, onClick, caption, ...rest }: IProps): JSX.Element => {
	const { t } = useTranslation();

	if (isLoading) {
		return (
			<Box w="100%">
				<Progress isIndeterminate size="lg" colorScheme="blue" hasStripe w="100%" />
			</Box>
		);
	}

	return (
		<Box w="100%" textAlign="center" { ...rest }>
			<Button
				variant="primary"
				type="button"
				onClick={onClick}
				w="150px"
				h="50px"
			>
				{ caption || t("field.buttonUpdate") }				
			</Button>
		</Box>
	);
};
