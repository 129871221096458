import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { 
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
} from "@chakra-ui/react";


import { useAuthorization } from "../services";
import { Layout } from "../components/layout/Index";
import { Config } from "../config/Config";
import { AdminCompanyList } from "../components/AdminCompanyList";
import { AddIcon } from "@chakra-ui/icons";
import { CompanySetup } from "../components/CompanySetup";

export const AdministratorHomePage = (): JSX.Element => {
	const { t } = useTranslation();
	const [ isAdmin, setIsAdmin ] = useState(false);
	const auth = useAuthorization();


	useEffect(() => {
		setIsAdmin(auth.isAdministrator());
	}, [ setIsAdmin, auth ]);


	if (!isAdmin) {
		return (<div>{t("adminPage.notPermitted")}</div>);
	}


	return (
		<Layout>
			<div>
				{ Config.enableAdminCompanyList && <AdminCompanyList /> }
				<Accordion allowToggle mt="1em">
					<AccordionItem>
						<h2>
							<AccordionButton>
								<Box as="span" flex="1" textAlign="left">
									<AddIcon mr="0.5em" />
									{t("adminPage.onBoard")}
								</Box>
							</AccordionButton>
						</h2>
						<AccordionPanel>
							<CompanySetup isInAdmin />
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			</div>
		</Layout>
	)
};
