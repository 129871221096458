import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";


interface IProps extends BoxProps {
	width?: string;
	height?: string;
	src: string;
	title?: string;
	center?: boolean;
}

export const YouTubeEmbed = ({
	width = "560px",
	height = "315px",
	src,
	title = "",
	center = false,
	...rest
}: IProps): JSX.Element => {
	const { t } = useTranslation();

	const playerTitle = title || t("youTubeTitle");

	const marginProps = {
		marginLeft: center ? "auto" : "0",
		marginRight: center ? "auto" : "0",
	};

	return (
		<Box p="2" boxShadow="lg" {...rest}>
			<iframe
				style={marginProps}
				width={width}
				height={height}
				src={src}
				title={playerTitle}
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowFullScreen
			>
			</iframe>
		</Box>
	);
};
