import React from "react";
import { Box, BoxProps, Image } from "@chakra-ui/react";


interface IProps extends BoxProps {
	width?: string;
	height?: string;
	url: string;
	alt?: string;
	center?: boolean;
}

export const ImageContainer = ({
	width,
	height,
	url,
	alt,
	center = false,
	...rest
}: IProps): JSX.Element => (
	<Box borderWidth="1px" borderRadius="xl" p="2" boxShadow="lg" {...rest}>
		<Image
			fit="contain"
			ml={center ? "auto" : "0"}
			mr={center ? "auto" : "0"}
			width={width}
			height={height}
			src={url}
			alt={alt}
		/>
	</Box>
);
