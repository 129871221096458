import React from "react";
import { Box, Button, ButtonGroup, Flex, Select } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { IUserFarmPermission } from "../models";
import { useApi } from "../services";
import { Config } from "../config/Config";




interface IProps {
	user: IUserFarmPermission,
	index: number,
	onCancel?: (index: number, user: IUserFarmPermission) => void;
	onEdit?: (index: number, user: IUserFarmPermission, permission: string) => void;
}

export const UserListEdit = ({
	user,
	index,
	onCancel = (index: number, user: IUserFarmPermission) => {},
	onEdit = (index: number, user: IUserFarmPermission, permission: string) => {},
}: IProps): JSX.Element => {
	const { t } = useTranslation();

	const handleEditClick = (): void => {
		console.log("edit user");
		console.log(user);
		onEdit(index, user, "");
	};

	const handleCancelClick = (): void => {
		console.log("cancel edit");
		console.log(user);
		onCancel(index, user);
	};

	const onChangeValue = (e: any): void => {
		const newPermission = e.target.value;
		onEdit && onEdit(index, user, newPermission);
	};


	return (
		<Flex>
			<Box>{t("userList.accessCaption")}</Box>
			<Box flex="1" ml="0.5em" mr="0.5em">
				<Select size="xs" defaultValue={user.permission} onChange={onChangeValue}>
					<option value="read">{t("userList.read")}</option>
					<option value="write">{t("userList.write")}</option>
					<option value="admin">{t("userList.admin")}</option>
				</Select>
			</Box>
			<ButtonGroup>
				<Button variant="link" onClick={handleEditClick}>{t("userList.edit")}</Button>
				<Button variant="link" onClick={handleCancelClick}>{t("userList.cancel")}</Button>
			</ButtonGroup>
		</Flex>
	);
};
