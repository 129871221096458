import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
	Box,
	Button,
	Card,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Progress,
	Stack,
} from "@chakra-ui/react";


import { useAuthorizationDispatch, useAuthorizationState, updateCompanyInfo } from "../contexts/AuthorizationContext";
import { Config } from "../config/Config";
import { useApi } from "../services";
import { ICompanyUpdate } from "../models";


export type FormValues = {
	companyName: string;
	companyLocation?: string;
}

export const CompanyEditor = (): JSX.Element => {
	const { t } = useTranslation();
	const { isGecoAdmin, isCompanyAdmin } = useAuthorizationState();
	const authDispatch = useAuthorizationDispatch();
	const { isLoading, updateCompany } = useApi(Config.baseUrl);
	const { company, user } = useAuthorizationState();


	const defaultValues = {
		companyName: company?.name || "",
		companyLocation: company?.location || "",
	};

	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>({ defaultValues });

	const onSubmit = handleSubmit((data) => {
		const companyData: ICompanyUpdate = {
			id: company?.id || -1,
			name: data.companyName,
			location: data.companyLocation || "",
		};

		console.log(company);
		updateCompany(companyData);
		authDispatch(updateCompanyInfo(companyData.name, companyData?.location || undefined));
	});

	return (
		<Stack spacing="10" mt="10">
			<form onSubmit={onSubmit}>
				<Card p="2em">
					<Heading size="sm">{t("companyEditor.title")}</Heading>
					<FormControl mt="10" isInvalid={!!errors.companyName} isRequired>
						<Input
							id="companyName"
							size="md"
							type="text"
							placeholder=" "
							data-peer
							isReadOnly={!isCompanyAdmin && !isGecoAdmin}
							{ ...register("companyName")}
						/>
						<FormLabel htmlFor="companyName" variant="floating" size="md">
							{t("companyEditor.labelCompanyName")}
						</FormLabel>
					</FormControl>
					<FormControl mt="10">
						<Input
							id="companyLocation"
							size="md"
							placeholder=" "
							type="text"
							isReadOnly={!isCompanyAdmin && !isGecoAdmin}
							data-peer
							{ ...register("companyLocation")}
						/>
						<FormLabel htmlFor="companyLocation" variant="floating" size="md">
							{t("companyEditor.labelCompanyLocation")}
						</FormLabel>
					</FormControl>
					<Box w="100%" textAlign="center">
						{
							(!isLoading && (isCompanyAdmin || isGecoAdmin)) &&
							<>
								<Button mt="4" variant="primary" w="150px" h="50px" mr="2em" ml="2em" type="submit">{t("companyEditor.save")}</Button>
							</>
						}
						{ isLoading && <Progress isIndeterminate mt="2em" size="lg" colorScheme="blue" hasStripe w="100%" /> }
					</Box>
				</Card>
			</form>
		</Stack>
	);
};
