import React from "react";
import { Box, Button, Icon } from "@chakra-ui/react";
import { FaFileAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";

interface IImageContainerProps {
	children: JSX.Element;
}

const ImageContainer = ({ children }: IImageContainerProps): JSX.Element => (
	<Box
		w="130px"
		h="85px"
		backgroundSize="cover"
		borderRadius="5px"
		marginRight="15px"
		backgroundColor="#eaecf1"
		display="flex"
		alignItems="center"
		justifyContent="center"
		fontSize="30px"
		color="#475f7b"
		padding="3px"
	>
		{children}
	</Box>
);

export interface IDropzonePreviewItem {
	id: string;
	fileImage: any;
	alt: string;
	filename: string;
	filetype: string;
	datetime: Date;
	fileSize: string;
	deleteHandler?: (id: string) => void;
	file: File;
}

export const DropzonePreviewItem = ({
	id,
	fileImage,
	alt,
	filename,
	filetype,
	datetime,
	fileSize,
	deleteHandler
}: IDropzonePreviewItem): JSX.Element => {
	const { t } = useTranslation();

	const handleDeleteClick = (): void => {
		deleteHandler && deleteHandler(id);
	};

	return (
		<Box display="flex" alignItems="center" mt="0.5em">
			{
				filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
					<ImageContainer><img src={fileImage} alt={alt} /></ImageContainer> :
					<ImageContainer><Icon as={FaFileAlt} /></ImageContainer>
			}
			<Box flex="1" w="calc(100%-210px)">
				<Box as="h6" wordBreak="break-all" fontSize="13px" fontWeight="500" lineHeight="20px" mb="8px">
					{filename}
				</Box>
				<p>
					<Box as="span">{t("dropzonePreview.size")} {fileSize}</Box>
					<Box as="span" ml="3px">{t("dropzonePreview.modified")} {datetime.toString()}</Box>
				</p>
				<Box display="flex" flexWrap="wrap" alignItems="center">
					<Button variant="link" onClick={handleDeleteClick}>{t("dropzonePreview.buttonDelete")}</Button>
				</Box>
			</Box>
		</Box>
	);
};
