import React, { Dispatch, createContext, useReducer, useContext, useState } from "react";

import { ITenantState, initialTenantState } from "./ITenantState";
import { TenantAction } from "./actions";
import { reducer } from "./reducer";


const TenantStateContext = createContext<ITenantState | undefined>(undefined);
const TenantDispatchContext = createContext<Dispatch<TenantAction> | undefined>(undefined);


interface IProps {
	data?: ITenantState;
	children: JSX.Element;
}


export const TenantProvider = ({ data = initialTenantState, children }: IProps): JSX.Element => {
	const [ state, dispatch ] = useReducer(reducer, data);

	return (
		<TenantStateContext.Provider value={state}>
			<TenantDispatchContext.Provider value={dispatch}>
				{children}
			</TenantDispatchContext.Provider>
		</TenantStateContext.Provider>
	);
};

export const useTenantState = (): ITenantState => {
	const context = useContext(TenantStateContext);
	if (context === undefined) { throw new Error("must be used within the provider"); }
	return context;
};

export const useTenantDispatch = (): Dispatch<TenantAction> => {
	const context = useContext(TenantDispatchContext);
	if (context === undefined) { throw new Error("must be used within the provider"); }
	return context;
};


