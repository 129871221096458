import React, { useEffect, useState } from "react";


import { Layout } from "../components/layout/Index";
import { CompanyEditor } from "../components/CompanyEditor";


export const CompanyPage = (): JSX.Element => {


	return (
		<Layout>
			<CompanyEditor />
		</Layout>
	);
};
