import React, { Dispatch, createContext, useReducer, useContext, useState } from "react";

import { IAdminUserState, initialState } from "./IAdminUserState";
import { AdminUserAction } from "./actions";
import { reducer } from "./reducer";


export const AdminUserStateContext = createContext<IAdminUserState | undefined>(undefined);
export const AdminUserDispatchContext = createContext<Dispatch<AdminUserAction> | undefined>(undefined);


interface IProps {
	data?: IAdminUserState;
	children: JSX.Element;
}


export const AdminUserProvider = ({ data = initialState, children }: IProps): JSX.Element => {
	const [ state, dispatch ] = useReducer(reducer, data);

	return (
		<AdminUserDispatchContext.Provider value={dispatch}>
			<AdminUserStateContext.Provider value={state}>
				{children}
			</AdminUserStateContext.Provider>
		</AdminUserDispatchContext.Provider>
	);
};



export const useAdminUserState = (): IAdminUserState => {
	const context = useContext(AdminUserStateContext);
	if (context === undefined) { throw new Error("must be used within the provider"); }
	return context;
};

export const useAdminUserDispatch = (): Dispatch<AdminUserAction> => {
	const context = useContext(AdminUserDispatchContext);
	if (context === undefined) { throw new Error("must be used within the provider"); }
	return context;
};





