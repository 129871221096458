

export interface IUseAuthorization {
	isAdministrator: () => boolean;
}


export const useAuthorization = (): IUseAuthorization => {

	const isAdministrator = (): boolean => true;



	return {
		isAdministrator,
	};
};
