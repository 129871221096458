import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";


import { IContactRequest, IContactRequestResult } from "../models/IContactRequest";
import {
	IArticle,
	ICompany,
	ICompanyAdminExt,
	ICompanyContainer,
	ICompanyUpdate,
	ICompanyUserList,
	IFarm,
	IField,
	IFieldAdd,
	IFieldAllData,
	IFieldFile,
	IFieldYearData,
	IProblemDetails,
	ISetupTenant,
	IUser,
	IUserAllData,
	IUserAuthInfo,
	IUserInviteEdit,
	IUserInviteResult,
	IUserTenantData,
	isIProblemDetails
} from "../models";
import { resolve } from "path";


export interface IApi {
	isLoading: boolean;
	//isInitialized: boolean;
	getAccessToken: () => Promise<string | null>;
	getUserData: () => Promise<IUserAllData | null | undefined>;
	getAccount: () => AccountInfo | null;
	setupTenant: (tenant: ISetupTenant) => Promise<IUserAllData | IProblemDetails | null | undefined>;
	loadTenant: () => Promise<IUserTenantData | IProblemDetails>;
	loadFieldData: (fieldId: number) => Promise<IFieldAllData | IProblemDetails>
	updateFieldDetails: (f: IField) => Promise<IField | IProblemDetails>;
	updateFieldLocation: (f: IField) => Promise<IField | IProblemDetails>;
	updateFieldDimensions: (f: IField) => Promise<IField | IProblemDetails>;
	uploadFile: (f: FormData) => Promise<IFieldFile | IProblemDetails>;
	uploadFiles: (fd: FormData) => Promise<IFieldFile[] | IProblemDetails>;
	downloadFile: (f: IFieldFile) => Promise<Blob | null | undefined | IProblemDetails>;
	sendContactRequest: (r: IContactRequest) => Promise<IContactRequestResult | IProblemDetails>;
	getCompany: (companyId: number) => Promise<ICompany | null | undefined>;
	updateCompany: (company: ICompanyUpdate) => Promise<ICompany | IProblemDetails>;
	adminGetAllCompanies: (sortField: "Name", sortAscending: boolean, page: number, pageSize: number) => Promise<ICompanyAdminExt[]>;
	adminGetAllCompanyData: (companyId: number) => Promise<ICompanyContainer | null | undefined | IProblemDetails>;
	fieldAdd: (fa: IFieldAdd) => Promise<IField | IProblemDetails>;
	updateFarm: (f: IFarm) => Promise<IFarm | IProblemDetails>;
	deleteFile: (f: IFieldFile) => Promise<null | IProblemDetails>;
	getArticles: (maxCount: number) => Promise<IArticle[]>;
	getDashboard: () => Promise<any>;
	inviteNewUser: (companyId: number, invite: IUserInviteEdit) => Promise<IUserInviteResult | IProblemDetails>;
	getUsersForCompany: (companyId: number) => Promise<ICompanyUserList | IProblemDetails>;
	getUsersForFarm: (companyId: number, farmId: number) => Promise<ICompanyUserList | IProblemDetails>;
	getFileData: (companyId: number, farmId: number, fieldId: number, fileId: number) => Promise<IFieldFile | IProblemDetails | null | undefined>;
	updateFileData: (f: IFieldFile) => Promise<IFieldFile | IProblemDetails | null>;
	updateProfile: (user: IUser) => Promise<IUser | IProblemDetails>;
	updateFieldYearData: (year: IFieldYearData) => Promise<IFieldYearData | IProblemDetails>;
	insertFieldYearData: (year: IFieldYearData) => Promise<IFieldYearData | IProblemDetails>;
	readFieldYearData: (fieldId: number) => Promise<IFieldYearData[] | IProblemDetails>;
	userRemoveFromForm: (farmId: number, userId: number) => Promise<IUserInviteResult | IProblemDetails>;
	userUpdateForFarm: (companyId: number, farmId: number, userId: number, permission: string) => Promise<IUserInviteResult | IProblemDetails>;
}


export const useApi = (baseUrl: string): IApi => {
	const _baseUrl = baseUrl;
	const { instance } = useMsal();

	const [ isLoading, setIsLoading ] = useState(false);


	const getAccessToken = async (): Promise<string | null> => {
		let activeAccount = instance.getActiveAccount();
		if (!activeAccount) {
			const allAccounts = instance.getAllAccounts();
			if (allAccounts && allAccounts.length > 0) { activeAccount = allAccounts[0]; }
		}

		if (!activeAccount) {
			// something bad happened should probably throw an error message and
			// log out.
			alert("There was an error in authentication, logging out");
			instance.logoutRedirect();
		}

		try {
			const r = await instance.acquireTokenSilent({
				account: activeAccount || undefined,
				scopes: [ "https://gecodata.onmicrosoft.com/api/manage.farm", "openid", "offline_access" ],
			});
			console.log(r);
			if (!r?.accessToken) { throw new Error("unable to get access token"); }
			return r.accessToken;
		} catch (error) {
			const r = await instance.acquireTokenPopup({
				account: activeAccount || undefined,
				scopes: [ "https://gecodata.onmicrosoft.com/api/manage.farm", "openid", "offline_access" ],
			});
			console.log("silent failed");
			console.log(r);
			if (!r?.accessToken) { throw new Error("Unable to get access token"); }
			return r.accessToken;
		}
	};

	const getAccount = (): AccountInfo | null => instance.getActiveAccount();


	
	const getUserData = async (): Promise<IUserAllData | null | undefined> => {
		setIsLoading(true);
		const url = `${_baseUrl}api/v1/user/getUserData`;
		const token = await getAccessToken();
		const headers = {
			accept: "application/json",
			authorization: `Bearer ${token}`,
		};
		const response = await fetch(url, { method: "GET", headers });
		console.log(response);
		if (response.status === 200) {
			const data = await response.json();
			setIsLoading(false);
			return data;
		}

		setIsLoading(false);
		return null;
	};


	const setupTenant = async (tenant: ISetupTenant): Promise<IUserAllData | IProblemDetails | null | undefined> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/tenantSetup`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", "content-type": "application/json", authorization: `Bearer ${token}` };
		const response = await fetch(url, { method: "POST", headers, body: JSON.stringify(tenant) });

		setIsLoading(false);
		
		if (response.ok) {
			const data = await response.json();
			return data as IUserAllData;
		}

		const errorData = response.json();
		return errorData as IProblemDetails;
	};


	const loadTenant = async (): Promise<IUserTenantData | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/tenant`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", authorization: `Bearer ${token}` };
		const response = await fetch(url, { method: "GET", headers, });

		setIsLoading(false);

		if (response.ok) {
			const data = await response.json();
			console.log(data);
			return data as IUserTenantData;
		}

		const errorData = response.json();
		return errorData as IProblemDetails;
	};


	const loadFieldData = async (fieldId: number): Promise<IFieldAllData | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/tenant/field/${fieldId}`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", authorization: `Bearer ${token}`};
		const response = await fetch(url, { method: "GET", headers });
		const data = await response.json();

		setIsLoading(false);

		if (response.ok) {
			return data as IFieldAllData;
		}

		return data as IProblemDetails;
	};


	const updateFieldDetails = async (f: IField): Promise<IField | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/tenant/field/details`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", authorization: `Bearer ${token}`, "Content-Type": "application/json" };
		const response = await fetch(url, { method: "PUT", headers, body: JSON.stringify(f)});
		const data = await response.json();

		setIsLoading(false);

		if (response.ok) {
			return data as IField;
		}

		return data as IProblemDetails;
	};


	const updateFieldDimensions = async (f: IField): Promise<IField | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/tenant/field/dimensions`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", authorization: `Bearer ${token}`, "Content-Type": "application/json" };
		const response = await fetch(url, { method: "PUT", headers, body: JSON.stringify(f)});
		const data = await response.json();

		setIsLoading(false);

		if (response.ok) {
			return data as IField;
		}

		return data as IProblemDetails;
	};

	const updateFieldLocation = async (f: IField): Promise<IField | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/tenant/field/location`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", authorization: `Bearer ${token}`, "Content-Type": "application/json" };
		const response = await fetch(url, { method: "PUT", headers, body: JSON.stringify(f)});
		const data = await response.json();

		setIsLoading(false);

		if (response.ok) {
			return data as IField;
		}

		return data as IProblemDetails;
	};

	const uploadFile = async (f: FormData): Promise<IFieldFile | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/fieldfile`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", authorization: `Bearer ${token}`, };
		const response = await fetch(url, { method: "POST", headers, body: f });
		const data = await response.json();

		setIsLoading(false);

		if (response.ok) {
			return data as IFieldFile;
		}

		return data as IProblemDetails;
	};

	const uploadFiles = async (fd: FormData): Promise<IFieldFile[] | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v2/fieldFile`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", Authorization: `Bearer ${token}` };
		const response = await fetch(url, { method: "POST", headers, body: fd });
		const data = await response.json();

		setIsLoading(false);

		if (response.ok) {
			return data as IFieldFile[];
		}

		return data as IProblemDetails;
	};

	const downloadFile = async (f: IFieldFile): Promise<Blob | null | undefined | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/fieldFile/${f.companyId}/${f.farmId}/${f.fieldId}/${f.id}`;
		const token = await getAccessToken();
		const headers = { accept: "application/octet-stream", authorization: `Bearer ${token}` };
		const response = await fetch(url, { method: "GET", headers });

		setIsLoading(false);
		
		if (!response.ok) {
			const json = await response.json() as IProblemDetails;
			return json;
		}

		const blob = await response.blob();
		return blob;
	};


	const sendContactRequest = async (r: IContactRequest): Promise<IContactRequestResult | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/ContactRequest`;
		const headers = { accept: "application/json", "Content-Type": "application/json" };
		const response = await fetch(url, { method: "POST", headers });

		setIsLoading(false);

		if (!response.ok) {
			const json = await response.json() as IProblemDetails;
			return json;
		}

		const results = await response.json() as IContactRequestResult;
		return results;
	}


	const getCompany = async (companyId: number): Promise<ICompany | null | undefined> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/company/${companyId}`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", authorization: `Bearer ${token}` };
		const response = await fetch(url, { method: "GET", headers });

		setIsLoading(false);

		if (!response.ok) { return null; }
		const data = await response.json() as ICompany;
		return data;
	};

	const updateCompany = async (company: ICompanyUpdate): Promise<ICompany | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/company/update`;
		const token = await getAccessToken();
		const body = JSON.stringify(company);
		const headers = { accept: "application/json", "Content-Type": "application/json", authorization: `Bearer ${token}` };
		const response = await fetch(url, { method: "POST", headers, body });

		setIsLoading(false);

		if (!response.ok) {
			const errData = await response.json() as IProblemDetails;
			return errData;
		}

		const data = await response.json() as ICompany;
		return data;
	}


	const adminGetAllCompanies = async (sortField: "Name", sortAscending: boolean, page: number, pageSize: number): Promise<ICompanyAdminExt[]> => {
		setIsLoading(true);

		const sortAscendingStr = sortAscending ? "true" : "false";
		const url = `${_baseUrl}api/v1/company/admin?sortField=${sortField}&sortAscending=${sortAscendingStr}&page=${page}&pageSize=${pageSize}`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", authorization: `Bearer ${token}`};
		const response = await fetch(url, { method: "GET", headers, });

		setIsLoading(false);
		if (!response.ok) {
			const errData = await response.json() as IProblemDetails;
			console.log(response.status);
			console.log(errData);
			return [];
		}

		const data = await response.json() as ICompanyAdminExt[];
		return data;
	}


	const adminGetAllCompanyData = async (companyId: number): Promise<ICompanyContainer | null | undefined | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/admin/company/${companyId}`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", authorization: `Bearer ${token}` };
		const response = await fetch(url, { method: "GET", headers });

		setIsLoading(false);

		if (!response.ok) {
			const errData = await response.json() as IProblemDetails;
			console.error(errData);
			return errData;
		}

		const data = await response.json() as ICompanyContainer;
		return data;
	};

	const fieldAdd = async (fa: IFieldAdd): Promise<IField | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/tenant/field/add`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${token}` };
		const body = JSON.stringify(fa);
		const response = await fetch(url, { method: "POST", headers, body });

		setIsLoading(false);

		if (!response.ok) {
			const errData = await response.json() as IProblemDetails;
			console.error(errData);
			return errData;
		}

		const data = await response.json() as IField;
		return data;
	};

	const updateFarm = async (f: IFarm): Promise<IFarm | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/tenant/farm`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${token}` };
		const body = JSON.stringify(f);
		const response = await fetch(url, { method: "PUT", headers, body });

		setIsLoading(false);

		if (!response.ok) {
			const errData = await response.json() as IProblemDetails;
			console.error(errData);
			return errData;
		}

		const data = await response.json() as IFarm;
		return data;
	};


	const deleteFile = async (f: IFieldFile): Promise<null | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/fieldfile`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${token}` };
		const body = JSON.stringify(f);
		const response = await fetch(url, { method: "DELETE", headers, body });

		setIsLoading(false);

		if (!response.ok) {
			const errData = await response.json() as IProblemDetails;
			console.error(errData);
			return errData;
		}

		return null;
	};


	const getArticles = async (maxCount: number): Promise<IArticle[]> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/article?max=${maxCount}`;
		const headers = { accept: "application/json" };
		const response = await fetch(url, { method: "GET", headers });

		setIsLoading(false);

		if (!response.ok) { return []; }

		const data = await response.json() as IArticle[];
		return data;
	};

	const getDashboard = async (): Promise<any> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/tenant/dashboard`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", Authorization: `Bearer ${token}` };
		const response = await fetch(url, { method: "GET", headers });

		setIsLoading(false);

		if (!response.ok) {
			const errData = await response.json() as IProblemDetails;
			console.log(errData);
			return errData;
		}

		const data = await response.json();
		return data;
	};


	const inviteNewUser = async (companyId: number, invite: IUserInviteEdit): Promise<IUserInviteResult | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/user/company/${companyId}`;
		const token = await getAccessToken();
		const body = JSON.stringify(invite);
		const headers = { accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${token}` };
		const response = await fetch(url, { method: "POST", body, headers });

		setIsLoading(false);

		if (!response.ok) {
			const errData = await response.json() as IProblemDetails;
			console.log(errData);
			return errData;
		}

		const data = await response.json() as IUserInviteResult;
		return data;
	};

	const getUsersForCompany = async (companyId: number): Promise<ICompanyUserList | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/user/company/${companyId}`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", Authorization: `Bearer ${token}` };
		const response = await fetch(url, { method: "GET", headers, });

		setIsLoading(false);

		if (!response.ok) {
			const errData = await response.json() as IProblemDetails;
			console.log(errData);
			return errData;
		}

		const data = await response.json() as ICompanyUserList;
		return data;
	};


	const getUsersForFarm = async (companyId: number, farmId: number): Promise<ICompanyUserList | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/user/farm/${companyId}/${farmId}`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", Authorization: `Bearer ${token}` };
		const response = await fetch(url, { method: "GET", headers, });

		setIsLoading(false);

		if (!response.ok) {
			const errData = await response.json() as IProblemDetails;
			console.log(errData);
			return errData;
		}

		const data = await response.json() as ICompanyUserList;
		return data;
	};


	const getFileData = async (companyId: number, farmId: number, fieldId: number, fileId: number): Promise<IFieldFile | IProblemDetails | null | undefined> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/fieldFile/meta/${companyId}/${farmId}/${fieldId}/${fileId}`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", Authorization: `Bearer ${token}` };
		const response = await fetch(url, { method: "GET", headers });

		setIsLoading(false);

		if (!response.ok) {
			if (response.status === 404) { return null; }
			const errorData = await response.json() as IProblemDetails;
			return errorData;
		}

		const data = await response.json() as IFieldFile;
		return data;	
	};

	const updateFileData = async (f: IFieldFile): Promise<IFieldFile | IProblemDetails | null> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/fieldFile/meta`;
		const token = await getAccessToken();
		const body = JSON.stringify(f);
		const headers = { accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${token}` };
		const response = await fetch(url, { method: "PUT", headers, body });

		setIsLoading(false);

		if (response.ok) {
			const data = await response.json() as IFieldFile;
			return data;
		}

		if (response.status === 404) { return null; }

		const errorData = await response.json() as IProblemDetails;
		return errorData;
	}

	const updateProfile = async (user: IUser): Promise<IUser | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/user/profile`;
		const token = await getAccessToken();
		const body = JSON.stringify(user);
		const headers = { accept: "application/json", "content-type": "application/json", Authorization: `Bearer ${token}` };
		const response = await fetch(url, { method: "PUT", headers, body });

		setIsLoading(false);

		if (!response.ok) {
			const errData = await response.json() as IProblemDetails;
			return errData;
		}

		const data = await response.json() as IUser;
		return data;
	};

	const updateFieldYearData = async (year: IFieldYearData): Promise<IFieldYearData | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/tenant/field/year`;
		const token = await getAccessToken();
		const body = JSON.stringify(year);
		const headers = { accept: "application/json", "content-type": "application/json", Authorization: `Bearer ${token}` };
		const response = await fetch(url, { method: "PUT", headers, body });

		setIsLoading(false);

		if (!response.ok) {
			const errData = await response.json() as IProblemDetails;
			return errData;
		}

		const data = await response.json() as IFieldYearData;
		return data;
	};


	const insertFieldYearData = async (year: IFieldYearData): Promise<IFieldYearData | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/tenant/field/year`;
		const token = await getAccessToken();
		const body = JSON.stringify(year);
		const headers = { accept: "application/json", "content-type": "application/json", Authorization: `Bearer ${token}` };
		const response = await fetch(url, { method: "POST", headers, body });

		setIsLoading(false);

		if (!response.ok) {
			const errData = await response.json() as IProblemDetails;
			return errData;
		}

		const data = await response.json() as IFieldYearData;
		return data;
	};

	const readFieldYearData = async (fieldId: number): Promise<IFieldYearData[] | IProblemDetails> => {
		setIsLoading(true);

		const url = `${_baseUrl}api/v1/tenant/field/year/${fieldId}`;
		const token = await getAccessToken();
		const headers = { accept: "application/json", Authorization: `Bearer ${token}` };
		const response = await fetch(url, { method: "GET", headers });

		setIsLoading(false);

		if (!response.ok) {
			const errData = await response.json() as IProblemDetails;
			return errData;
		}

		const data = await response.json() as IFieldYearData[];
		return data;
	};

	const userRemoveFromForm = async (farmId: number, userId: number): Promise<IUserInviteResult | IProblemDetails> => {
		setIsLoading(true);

		const data: IUserInviteEdit = {
			email: "",
			userName: "",
			firstName: "",
			lastName: "",
			editUserIds: [ userId ],
			farmIds: [ farmId ],
			permissions: [ {
				companyId: 0,
				farmId: 0,
				userId: 0,
				permission: ""
			} ],
		};

		const url = `${_baseUrl}api/v1/user/farm`;
		const token = await getAccessToken();
		const body = JSON.stringify(data);
		const headers = { accept: "application/json", Authorization: `Bearer ${token}`, "Content-Type": "application/json" };
		const response = await fetch(url, { method: "DELETE", body, headers });

		setIsLoading(false);

		if (!response.ok) {
			const errData = await response.json() as IProblemDetails;
			return errData;
		}

		const result = await response.json() as IUserInviteResult;
		return result;
	};

	const userUpdateForFarm = async (companyId: number, farmId: number, userId: number, permission: string): Promise<IUserInviteResult | IProblemDetails> => {
		setIsLoading(true);

		const data: IUserInviteEdit = {
			email: "",
			userName: "",
			firstName: "",
			lastName: "",
			editUserIds: [ userId ],
			farmIds: [ farmId ],
			permissions: [{
				companyId,
				farmId,
				userId,
				permission,
			}],
		};

		const url = `${_baseUrl}api/v1/user/farm`;
		const token = await getAccessToken();
		const body = JSON.stringify(data);
		const headers = { accept: "application/json", Authorization: `Bearer ${token}`, "Content-Type": "application/json" };
		const response = await fetch (url, { method: "PUT", body, headers });

		setIsLoading(false);

		if (!response.ok) {
			const errData = await response.json() as IProblemDetails;
			return errData;
		}

		const result = await response.json() as IUserInviteResult;
		return result;
	};


	return {
		isLoading,
		getAccessToken,
		getUserData,
		getAccount,
		loadFieldData,
		loadTenant,
		setupTenant,
		updateFieldDetails,
		updateFieldLocation,
		updateFieldDimensions,
		uploadFile,
		uploadFiles,
		downloadFile,
		sendContactRequest,
		getCompany,
		updateCompany,
		adminGetAllCompanies,
		adminGetAllCompanyData,
		fieldAdd,
		updateFarm,
		deleteFile,
		getArticles,
		getDashboard,
		inviteNewUser,
		getUsersForCompany,
		getUsersForFarm,
		getFileData,
		updateFileData,
		updateProfile,
		updateFieldYearData,
		insertFieldYearData,
		readFieldYearData,
		userRemoveFromForm,
		userUpdateForFarm,
	};
};
