
const sidebar = {
	"sidebar.home": "Home",
	"sidebar.fields": "FIELDS",
	"sidebar.info": "INFORMATION",
	"sidebar.users": "USERS",
	"sidebar.logout": "LOGOUT",
	"sidebar.admin": "ADMIN DB",
	"sidebar.customerData": "YOUR INFO",
	"sidebar.farms": "FARMS",
	"sidebar.login": "Login",
};


const addField = {
	"addField.labelDescription": "DESCRIPTION",
	"addField.labelName": "NAME",
	"addField.title": "ADD NEW FIELD",
	"addField.save": "SAVE",
	"addField.cancel": "CANCEL",
	"addField.fieldAdded": "Field added to farm",
	"addField.successTitle": "Success",
	"addField.addSuccess": "Successfully added field to farm",
	"addField.errorTitle": "Error",
	"addField.errorAdding": "Unable to add field to farm",
};

const adminPage = {
	"adminPage.title": "",
	"adminPage.notPermitted": "NOT PERMITTED",
	"adminPage.onBoard": "ON BOARD",
};

const adminCompany = {
	"adminCompany.noName": "No Name",
	"adminCompany.farmsTitle": "Farms:",
	"adminCompany.fieldsTitle": "Fields:",
};

const articles = {
	"articles.heading": "NEWS",
};


const companyEditor = {
	"companyEditor.title": "COMPANY INFORMATION",
	"companyEditor.labelCompanyName": "NAME",
	"companyEditor.labelCompanyLocation": "LOCATION",
	"companyEditor.editCompany": "EDIT",
	"companyEditor.save": "SAVE",
	"companyEditor.cancel": "CANCEL",
};

const companyList = {
	"companyList.title": "COMPANIES",
	"companyList.headerId": "ID",
	"companyList.headerName": "NAME",
	"companyList.headerLocation": "LOCATION",
	"companyList.headerNumFarms": "FARMS",
	"companyList.headerNumFields": "FIELDS",
	"companyList.headerAdmin": "ADMIN",
	"companyList.headerActionEdit": "edit",
	"companyList.headerNumFiles": "FILES",
	"companyList.headerStatus": "STATUS",
	"companyList.headerDate": "DUE",
};

const companySetup = {
	"companySetup.sectionTitle": "SETUP YOUR ACCOUNT",
	"companySetup.sectionTitleAdmin": "ONBOARD NEW COMPANY",
	"companySetup.labelCompanyName": "COMPANY NAME",
	"companySetup.labelLocation": "LOCATION",
	"companySetup.buttonSave": "SAVE",
	"companySetup.companyIsRequired": "company name is required",
	"companySetup.labelUserName": "NAME",
	"companySetup.labelEmail": "EMAIL",
	"companySetup.emailIsRequired": "email address is required",
	"companySetup.locationIsOptional": "optionally add a location",
	"companySetup.labelFirstName": "First Name",
	"companySetup.labelLastName": "Last Name",
	"companySetup.labelPhone": "Phone",
	"companySetup.phoneIsRequired": "phone number is required",
	"companySetup.notSetupYet": "Tenant not setup yet",
	"companySetup.setup": "Setup",
	"companySetup.userInfoTitle": "USER INFORMATION",
	"companySetup.companyInfoTitle": "COMPANY INFORMATION",
	"companySetup.farmInfoTitle": "FARM INFORMATION",
	"companySetup.labelFarmName": "Farm Name",
	"companySetup.labelNumberOfFields": "Number of Fields",
	"companySetup.labelTotalAcres": "Total Acres",
	"companySetup.farmNameIsRequired": "farm name is required",
	"companySetup.numFieldsRequired": "number of fields is required",
	"companySetup.numAcresRequired": "number of acres is required",
	"companySetup.invalidFieldsAndOrAcres": "Invalid fields or acres set",
	"companySetup.labelFarmAddress": "Farm Address",
	"companySetup.addressRequired": "farm address is required",
	"companySetup.defaultToFarmName": "defaults to name of farm",
};

const dropZone = {
	"dropZone.clickUpload": "Click to Upload",
	"dropZone.drop": "or drag and drop",
	"dropZone.limits": "what file types?",
}

const dropzonePreview = {
	"dropzonePreview.instructions1": "Drag and drop or ",
	"dropzonePreview.instructions2": "Choose your files",
	"dropzonePreview.size": "Size: ",
	"dropzonePreview.modified": "Modified: ",
	"dropzonePreview.buttonDelete": "delete",
};

const editFile = {
	"editFile.title": "FILE EDITS",
	"editFile.fileNotFound": "File does not exist",
	"editFile.errorTitle": "Error",
	"editFile.unableToRetrieve": "Unable to retrieve the file",
	"editFile.labelDescription": "DESCRIPTION",
	"editFile.labelCustomerDueDate": "CUSTOMER DUE DATE",
	"editFile.labelGecoDueDate": "GECO DUE DATE",
	"editFile.labelGecoSupplied": "GECO SUPPLIED",
	"editFile.labelCustomerSupplied": "CUSTOMER SUPPLIED",
	"editFile.labelGecoPrivate": "GECO PRIVATE",
	"editFile.updateButton": "UPDATE",
	"editFile.updateFailed": "Update of field data has failed",
}

const farm = {
	"farm.edit": " - Edit",
	"farm.addField": "ADD NEW FIELD",
	"farm.fieldList": "FIELD LIST",
};

const farmEdit = {
	"farmEdit.title": "FARM EDITOR",
	"farmEdit.labelName": "NAME",
	"farmEdit.labelAddress": "ADDRESS",
	"farmEdit.labelLatitude": "LATITUDE",
	"farmEdit.labelLongitude": "LONGITUDE",
	"farmEdit.labelAcres": "ACRES",
	"farmEdit.labelFields": "FIELDS",
	"farmEdit.saveButton": "SAVE",
};


const farms = {
	"farms.title": "FARMS",
};

const farmsList = {
	"farmsList.tableCaption": "Your Farms",
	"farmsList.columnId": "ID",
	"farmsList.columnName": "NAME",
	"farmsList.columnLocation": "LOCATION",
	"farmsList.columnLat": "LAT",
	"farmsList.columnLng": "LNG",
	"farmsList.columnNumFields": "FIELDS",
	"farmsList.columnAcres": "ACRES",
	"farmsList.columnDueDate": "DUE",
};

const field = {
	"field.noFieldData": "No Field Data",
	"field.labelName": "NAME OF FIELD",
	"field.labelDescription": "DESCRIPTION",
	"field.labelLength": "LENGTH",
	"field.labelWidth": "WIDTH",
	"field.labelLatitude": "LATITUDE",
	"field.labelLongitude": "LONGITUDE",
	"field.buttonUpdate": "UPDATE",
	"field.buttonAddFile": "UPLOAD",
	"field.buttonAddGecoPrivate": "UPLOAD GECO PRIVATE",
	"field.tabDetails": "DETAILS",
	"field.tabLocation": "LOCATION",
	"field.tabHistory": "HISTORY",
	"field.tabDimensions": "DIMENSIONS",
	"field.toastNameRequired": "Name is required",
	"field.toastNumberOrBlank": "Fields must be numbers of empty",
	"field.labelAcres": "Acres (number only):",
	"field.labelDueDate": "Due Date:",
	"field.labelStatus": "STATUS",
	"field.headerYear": "YEAR",
	"field.headerCrop": "CROP",
	"field.headerPlantDate": "PLANTED",
	"field.headerHarvestDate": "HARVESTED",
	"field.buttonEdit": "edit",
	"field.labelYear": "YEAR",
	"field.labelCrop": "CROP",
	"field.labelPlantDate": "PLANT DATE",
	"field.labelHarvestDate": "HARVEST DATE",
	"field.buttonAddYear": "ADD",
	"field.buttonClearYear": "CLEAR",
	"field.buttonEditYear": "EDIT",
	"field.errorTitle": "ERROR OCCURRED",
	"field.errorAddingHistory": "Unable to add crop history",
	"field.errorUpdatingHistory": "Unable to update crop history",
};

const fieldFiles = {
	"fieldFiles.custFilesTitle": "FILES",
	"fieldFiles.gecoFilesTitle": "GECO PRIVATE",
	"fieldFiles.buttonUpload": "UPLOAD",
	"fieldFiles.headerId": "ID",
	"fieldFiles.headerFilename": "FILENAME",
	"fieldFiles.headerFileType": "FILETYPE",
	"fieldFiles.headerDescription": "DESCRIPTION",
	"fieldFiles.headerStatus": "STATUS",
	"fieldFiles.headerDate": "DATE",
	"fieldFiles.download": "download",
	"fieldFiles.headerSource": "SOURCE",
	"fieldFiles.sourceGeco": "GECO",
	"fieldFiles.sourceCustomer": "CUST",
	"fieldFiles.sourceGecoPrivate": "GECO PRIVATE",
	"fieldFiles.delete": "delete",
	"fieldFiles.edit": "edit",
	"fieldFiles.errorDeleting": "There was an error deleting the file",
	"fieldFiles.errorTitle": "ERROR",
	"fieldFiles.deletedFile": "File deleted",
	"fieldFiles.successTitle": "SUCCESS",
	"fieldFiles.noFilesForFolder": "No files in folder",
};

const fieldFolder = {
	"fieldFolder.root": "Root",
	"fieldFolder.imagery": "Imagery",
	"fieldFolder.reports": "Reports",
	"fieldFolder.output": "Output",
	"fieldFolder.miscellaneous": "Miscellaneous",
	"fieldFolder.code": "Code",
	"fieldFolder.shapeFiles": "Shape Files",
	"fieldFolder.sprayMaps": "Spray Maps",
};


const fields = {
	"fields.title": "FARM FIELDS",
	"fields.caption": "Your Fields",
	"fields.idHeader": "ID",
	"fields.nameHeader": "NAME",
	"fields.descriptionHeader": "DESCRIPTION",
	"fields.latHeader": "LAT",
	"fields.lngHeader": "LNG",
	"fields.fileStatusHeader": "STATUS",
	"fields.acresHeader": "ACRES",
	"fields.dueDateHeader": "DUE",
};


const fieldStatus = {
	"fieldStatus.notStarted": "Not Started",
	"fieldStatus.inProgress": "In Progress",
	"fieldStatus.done": "Done",
	"fieldStatus.noWork": "No Work",
};


const inviteUser = {
	"inviteUser.title": "Add User to Farm",
	"inviteUser.emailLabel": "Email",
	"inviteUser.firstNameLabel": "First name",
	"inviteUser.lastNameLabel": "Last name",
	"inviteUser.usernameLabel": "Username",
	"inviteUser.sendInvite": "INVITE",
	"inviteUser.farmList": "Farms",
	"inviteUser.permissions": "Permissions",
	"inviteUser.none": "None",
	"inviteUser.read": "Read",
	"inviteUser.write": "Write",
	"inviteUser.admin": "Admin",
	"inviteUser.farmIdColumn": "Id",
	"inviteUser.farmNameColumn": "Farm",
	"inviteUser.permissionColumn": "Permission",
	"inviteUser.userNotSpecified": "User has not been specified",
	"inviteUser.errorTitle": "Error in Invite",
	"inviteUser.noFarmsSpecified": "A user must be assigned to a farm",
	"inviteUser.userAdded": "User successfully added",
	"inviteUser.infoTitle": "SUCCESS",
	"inviteUser.cantAddUser": "Unable to add user to farm",
};

const userDash = {
	"userDash.title": "USER DASHBOARD",
	"userDash.farmSelect": "-- SELECT FARM --",
	"userDash.load": "LOAD",
	"userDash.selectFarm": "Select Farm",
	"userDash.noFarmSelected": "Please select a farm from above",
	"userDash.inviteNewUser": "Invite a User",
	"userDash.tabAdd": "ADD USER",
	"userDash.tabManage": "MANAGE USERS",
};


const upload = {
	"upload.custDueDate": "DUE DATE",
	"upload.gecoDueDate": "GECO DATE",
	"upload.gecoSupplied": "GECO SUPPLIED",
	"upload.keepPrivate": "PRIVATE FOR GECO",
	"upload.nameRequired": "field name is required",
	"upload.fileIsRequired": "A file is required",
	"upload.fileMaxSize": "The max size for the file is 10mb",
	"upload.title": "UPLOAD FILES FOR FIELD",
	"upload.selectFile": "SELECT FILE",
	"upload.nothingSelected": "No file selected",
	"upload.uploadButton": "UPLOAD",
	"upload.description": "DESCRIPTION",
	"upload.fieldCardTitle": "FIELD INFORMATION",
	"upload.errorTitle": "Error",
	"upload.errorUploadingFiles": "There was an error uploading files",
	"upload.uploadFolder": "UPLOAD FOLDER",
};


const userList = {
	"userList.email": "EMAIL",
	"userList.firstName": "FIRST",
	"userList.lastName": "LAST",
	"userList.permission": "ACCESS",
	"userList.noUsersToDisplay": "There are no users or no farm is selected",
	"userList.editUser": "edit",
	"userList.deleteUser": "delete",
	"userList.errorTitle": "ERROR",
	"userList.errorDeletingUser": "Error deleting the user from farm",
	"userList.edit": "edit",
	"userList.cancel": "cancel",
	"userList.accessCaption": "select access",
	"userList.read": "READ",
	"userList.write": "WRITE",
	"userList.admin": "ADMIN",
	"userList.errorUpdating": "Unable to set user permission",
};

const errors = {
	"auth.titleNoUserData": "Error",
	"auth.messageNoUserData": "There was an error retrieving the user information",
	"setupTenant.error": "The tenant information is either incomplete or user already exists",
	"api.noResponse": "The API is not responding or otherwise unavailable",
	"info.errorTitle": "ERROR",
	"info.infoTitle": "INFO",
};

const landing = {
	"landing.signIn": "Sign In",
	"landing.learnMore": "Learn More",
	"landing.homeLink": "Home",
	"landing.getStartedLink": "Get Started",
	"landing.weedLink": "Weed Prediction",
	"landing.herbicideLink": "Herbicide Resistance",
	"landing.contactUsLink": "Contact Us",
	"landing.ctaRightButton": "Try It Now",
	"landing.aboutUsLink": "About Us",
	"landing.weedProtectionAbout": "Find and target weed hotspots with customized spray maps whether weeds are hidden by crop, soil, or dust!",
	"landing.weedProtectionAlt": "Weed Prediction Screenshot",
	"landing.weedProtectionTitle": "Weed Prediction",
	"landing.herbResistTitle": "Herbicide Resistance Detection",
	"landing.herbResistAbout": "Direct your scouts to the areas where herbicide resistant weeds may be emerging.",
	"landing.herbResistAlt": "Herbicide Resistance Screenshot",
	"landing.title": "Get ahead of problem weeds",
	"landing.description": "Geco leverages all available data on and above farm fields into agronomic models so you can nip weed problems in the bud. We are working with North American farms on your most challenging weed issues.",
};

const mruFields = {
	"mruFields.id": "ID",
	"mruFields.farmName": "FARM",
	"mruFields.fieldName": "NAME",
	"mruFields.createdBy": "CREATOR",
	"mruFields.createDate": "DATE",
	"mruFields.numFiles": "FILES",
	"mruFields.hasGecoAnalysis": "GECO",
	"mruFields.hasGecoPrivate": "PRIVATE",
	"mruFields.description": "DESCRIPTION",
	"mruFields.acres": "ACRES",
	"mruFields.dueDate": "DUE",
};

const notAuthorized = {
	"notAuthorized.title": "Not Authorized",
	"notAuthorized.title2": "You do not have authorization to see any tenants",
	"notAuthorized.logout": "LOGOUT",
};

const getStarted = {
	"getStarted.ctaTitle": "Get Started Today",
	"getStarted.ctaDescription": "Collaborate with Geco to get actionable results in 3 easy steps. The process is outlined below.",
	"getStarted.ctaLeftButton": "Try It Now",
	"getStarted.ctaRightButton": "About Us",
	"getStarted.ctaImageAlt": "Get Started Image",
	"getStarted.stepsTitle": "Geco's 3-Step Process",
	"getStarted.stepsInstructions": "Expand the items below for details on the 3 steps to get your actionable results.",
	"getStarted.step1Title": "1 - Contact Geco",
	"getStarted.step1Description": "Speak with a Geco team member.",
	"getStarted.step2Title": "2 - Geco Analysis of your Fields",
	"getStarted.step2Description": "Geco accesses and analyzes data for your fields.",
	"getStarted.step3Title": "3 - You Get the Results",
	"getStarted.step3Description": "For each field you receive (1) a predictive weed map for use as a sprayer script, and (2) a map of areas with high risk of herbicide resistance to use for directed scouting.",
};

const weedPredict = {
	"weedPredict.ctaTitle": "Weed Prediction",
	"weedPredict.ctaDescription": "Find and target hotspots with customized spray maps whether weeds are hidden by crop, soil, or dust! Estimate how much herbicide you will need to spot spray your field before you spray it.",
	"weedPredict.ctaDescription2": "Details on the Geco approach are described below.",
	"weedPredict.ctaLeftText": "Get Started",
	"weedPredict.ctaRightText": "Try It Now",
	"weedPredict.ctaImageAlt": "Weed Prediction Image",

	"weedPredict.detailsPara1": "Geco's weed prediction enables site-specific action in common or challenging cases:",
	"weedPredict.detailsItem1": "Residual herbicides - weeds hidden by soil",
	"weedPredict.detailsItem2": "Dense crops (e.g., cereals) - weeds hidden by crop",
	"weedPredict.detailsItem3": "Challenging environments - weeds hidden by dust",
	"weedPredict.comparisonImageAlt": "Weed Prediction vs Actual",

	"weedPredict.detailsPara2": "Prediction of weed emergence is difficult & depends on many factors each season.",
	"weedPredict.predictOverYearsAlt": "Weed prediction over years",

	"weedPredict.detailsPara3": "Geco provides back-casting along with the forecast prediction that you can visualize the performance over previous years. Users will select one of three strategies based on farm preference:",
	"weedPredict.list2Item1": "Minimize chemical",
	"weedPredict.list2Item2": "Equal priority",
	"weedPredict.list2Item3": "Minimize weed escapes",

	"weedPredict.backCastingAlt": "Weed prediction backcasting image",
	"weedPredict.predictionMapAlt": "Weed prediction map image",
};


const herbResist = {
	"herbResist.ctaTitle": "Herbicide Resistance",
	"herbResist.ctaDescription1": "Direct your scouts to areas where resistant weeds may be emerging.",
	"herbResist.ctaDescription2": "Details on the Geco approach to help you manage herbicide resistance are provided below.",
	"herbResist.ctaLeftButton": "Get Started",
	"herbResist.ctaRightButton": "Try It Now",
	"herbResist.ctaImageAlt": "Herbicide Resistance Image",

	"herbResist.detail1Title": "THE PROBLEM",
	"herbResist.detail1": "Herbicide resistant weeds only become visually evident to human scouting once they have firmly established in a field.",
	"herbResist.detail2Title": "HOW WE CAN HELP",
	"herbResist.detail2": "Geco helps you find resistance early and nip it in the bud.",
	"herbResist.scoutAlt": "Herbicide resistance scouting image",
	"herbResist.resistScoutAlt": "Herbicide resistance scouting image",

	"herbResist.videoDemoText": "See video demo",
};

const contactUs = {
	"contactUs.ctaTitle": "Try It Now",
	"contactUs.ctaDescription": "Have a question or ready to get started? Fill out the form below and one of our team members will contact you within a business day.",
	"contactUs.ctaLeftButton": "Get Started",
	"contactUs.ctaRightButton": "About Us",
	"contactUs.ctaImageAlt": "Try It Now Image",

	"contactUs.submissionSuccess": "Thanks for contacting Geco, we will be in touch soon.",
	"contactUs.formLabelEmail": "Email",
	"contactUs.formLabelPhone": "Phone Number (Optional)",
	"contactUs.formLabelMessage": "Message",
	"contactUs.formButtonSubmit": "Submit",
	"contactUs.formButtonSending": "Sending",
	"contactUs.neverShare": "We will never share your information.",
};

const aboutUs = {
	"aboutUs.ctaTitle": "Geco Agriculture",
	"aboutUs.ctaDescription": "We are passionate about solving some of the agriculture industry's toughest problems. Meet the team below.",
	"aboutUs.ctaLeftButton": "Get Started",
	"aboutUs.ctaRightButton": "Try It Now",
	"aboutUs.ctaImageAlt": "About Us Image",
};

const userProfile = {
	"userProfile.title": "EDIT PROFILE",
	"userProfile.labelFirst": "First Name",
	"userProfile.labelLast": "Last Name",
	"userProfile.labelPhone": "Phone",
	"userProfile.buttonUpdate": "UPDATE",
	"userProfile.errorTitle": "Error",
	"userProfile.unableToUpdate": "There was an error updating profile",
	"userProfile.infoTitle": "Success",
	"userProfile.profileUpdated": "Profile updated!",
};


export const enUS = {
	title: "farmer",
	youTubeTitle: "YouTube Video Player",
	addButton: "ADD",
	loading: "Loading",
	saving: "Saving",
	
	...addField,
	...adminCompany,
	...adminPage,
	...articles,
	...companyEditor,
	...companyList,
	...companySetup,
	...dropZone,
	...dropzonePreview,
	...editFile,
	...errors,
	...farm,
	...farmEdit,
	...farms,
	...farmsList,
	...field,
	...fieldFolder,
	...fieldFiles,
	...fields,
	...fieldStatus,
	...getStarted,
	...herbResist,
	...inviteUser,
	...contactUs,
	...aboutUs,
	...landing,
	...mruFields,
	...notAuthorized,
	...sidebar,
	...upload,
	...userDash,
	...userList,
	...userProfile,
	...weedPredict,
};
