import React, { Dispatch, createContext, useReducer, useContext } from "react";

import { IGlobalState, initialGlobalState } from "./IGlobalState";
import { GlobalAction } from "./actions";
import { reducer } from "./reducer";



interface IProps {
	data?: IGlobalState;
	children: JSX.Element;
}


const GlobalStateContext = createContext<IGlobalState | undefined>(undefined);
const GlobalDispatchContext = createContext<Dispatch<GlobalAction> | undefined>(undefined);


export const GlobalProvider = ({ data = initialGlobalState, children }: IProps): JSX.Element => {
	const [ state, dispatch ] = useReducer(reducer, data);

	return (
		<GlobalStateContext.Provider value={state}>
			<GlobalDispatchContext.Provider value={dispatch}>
				{children}
			</GlobalDispatchContext.Provider>
		</GlobalStateContext.Provider>
	);
};


export const useGlobalState = (): IGlobalState => {
	const context = useContext(GlobalStateContext);
	if (context === undefined) { throw new Error("must be in GlobalContext"); }
	return context;
};

export const useGlobalDispatch = (): Dispatch<GlobalAction> => {
	const context = useContext(GlobalDispatchContext);
	if (context === undefined) { throw new Error("must be in GlobalContext"); }
	return context;
};

