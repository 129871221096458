import { useTranslation } from "react-i18next";

import { CallToAction } from "../components/marketing-layout/CallToAction";
import { Products } from "../components/marketing-layout/Products"
import { GetStartedSteps } from "../components/marketing-layout/GetStartedSteps"
import { MarketingLayout } from "../components/marketing-layout";

export const MarketingHomePage = () => {
	const { t } = useTranslation();

	return (
		<MarketingLayout>
			<>
				<CallToAction
					headingTitle={t("landing.title")}
					headingDescription={t("landing.description")}
					leftButtonLink="/get-started"
					leftButtonText={t("landing.getStartedLink") || "Get Started"}
					rightButtonLink="/contact-us"
					rightButtonText={t("landing.ctaRightButton") || "Try It Now"}
					ctaImageAlt={t("landing.ctaImageAlt") || ""}
					ctaImageUrl="mainpagecta.png"
				/>
				<Products />
				<GetStartedSteps />
			</>
		</MarketingLayout>
	);
};
