import {
	Box,
	Button,
	ButtonGroup,
	Container,
	Drawer,
	DrawerContent,
	DrawerOverlay,
	Flex,
	Image,
	Link,
	HStack,
	useBreakpointValue,
	useDisclosure,
} from "@chakra-ui/react";

import { FiMenu } from "react-icons/fi";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

import { Link as ReactLink } from "react-router-dom";
import { loginRequest } from "../../config/msalConfig";
import { ToggleButton } from "../layout/ToggleButton";
import { Sidebar } from "./Sidebar";
import { Config } from "../../config/Config";

export const Navbar = () => {
	const { isOpen, onToggle, onClose } = useDisclosure();
	const { instance } = useMsal();
	const { t } = useTranslation();

	const handleLoginClick = (): void => {
		instance.loginRedirect(loginRequest).catch((error) => console.log(error));
	};

	const isDesktop = useBreakpointValue({ base: false, lg: true });
	return (
		<Box as="section">
			<Box as="nav" bg="bg.surface" boxShadow="sm">
				<Container py={{ base: "4", lg: "5" }}>
					<HStack spacing="6" justify="space-between">
						<Link as={ReactLink} to="/">
							<Image
								src="gecomobile.png"
								alt={t("logo") || ""}
								height="30px"
								width="120px"
							/>
						</Link>
						{isDesktop ? (
							<Flex justify="space-between" flex="1">
								<ButtonGroup variant="text" colorScheme="gray" spacing="8">
									<Button>
										<Link as={ReactLink} to="/">
											{t("landing.homeLink")}
										</Link>
									</Button>
									<Button>
										<Link as={ReactLink} to="/get-started">
											{t("landing.getStartedLink")}
										</Link>
									</Button>
									<Button>
										<Link as={ReactLink} to="/weed-prediction">
											{t("landing.weedLink")}
										</Link>
									</Button>
									<Button>
										<Link as={ReactLink} to="/herbicide-resistance">
											{t("landing.herbicideLink")}
										</Link>
									</Button>
									<Button>
										<Link as={ReactLink} to="/contact-us">
											{t("landing.contactUsLink")}
										</Link>
									</Button>
									<Button>
										<Link as={ReactLink} to="/about-us">
											{t("landing.aboutUsLink")}
										</Link>
									</Button>
									{
										Config.enableLogin &&
										<Button type="button" colorScheme="blue" onClick={handleLoginClick}>LOGIN</Button>
									}
								</ButtonGroup>
							</Flex>
						) : (
							<Flex justify="space-between">
								<ToggleButton
									isOpen={isOpen}
									aria-label="Open Menu"
									onClick={onToggle}
								/>
								<Drawer
									isOpen={isOpen}
									placement="left"
									onClose={onClose}
									isFullHeight
									preserveScrollBarGap
									trapFocus={false}
								>
									<DrawerOverlay />
									<DrawerContent>
										<Sidebar />
									</DrawerContent>
								</Drawer>
							</Flex>
						)}
					</HStack>
				</Container>
			</Box>
		</Box>
	);
};
