import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { enUS } from "./en-us";


const resources= {
	"en-US": {
		translation: enUS,
	},
};

const dateOptions = {
	short: { year: "numeric", month: "short", day: "numeric" },
	long: { year: "numeric", month: "long", day: "numeric" },
};


const rtf1 = new Intl.RelativeTimeFormat("en-US", { numeric: "auto" });

const format = (value, options) => {
	if (options === "relative") {
		const timeDifference = value.getTime() - Date.now();
		const daysDifference = parseInt(timeDifference / (1000 * 3600 * 24), 10);

		return rtf1.format(daysDifference, "day");
	}
	if (options === "fullDate") {
		return value.toLocaleDateString("en-US", dateOptions.short);
	}

	return value;
};


i18n.use(initReactI18next).init({
	resources,
	lng: "en-US",
	nsSeparator: ".",
	interpolation: { escapeValue: false, format },
});

export default i18n;
