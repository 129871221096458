import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, TableCaption } from "@chakra-ui/react";

import { useTenantState, setSelectedFarm, useTenantDispatch } from "../contexts/TenantContext";
import { IFarmWithField } from "../models";



export const FarmList = (): JSX.Element => {
	const { t } = useTranslation();
	const { farmFields, selectedFarm } = useTenantState();
	const tenantDispatch = useTenantDispatch();


	useEffect(() => {
		console.log(selectedFarm);
	}, [ selectedFarm ]);

	const handleClick = (f: IFarmWithField): void => {
		tenantDispatch(setSelectedFarm(f));
	};

	const renderTableRow = (f: IFarmWithField): JSX.Element => {
		return (
			<Tr key={f.id.toString()}>
				<Td><NavLink onClick={() => handleClick(f)} to={`/farm?id=${f.id}`}>{f.id}</NavLink></Td>
				<Td><NavLink onClick={() => handleClick(f)} to={`/farm?id=${f.id}`}>{f.name}</NavLink></Td>
				<Td>{f.farmAddress}</Td>
				<Td>{f.latitude}</Td>
				<Td>{f.longitude}</Td>
				<Td>{f.fields.length}</Td>
				<Td>{f.totalAcres}</Td>
			</Tr>
		);
	};

	return (
		<TableContainer>
			<Table variant="striped" size="md">
				<TableCaption>{t("farmsList.tableCaption")}</TableCaption>
				<Thead>
					<Tr>
						<Th>{t("farmsList.columnId")}</Th>
						<Th>{t("farmsList.columnName")}</Th>
						<Th>{t("farmsList.columnLocation")}</Th>
						<Th>{t("farmsList.columnLat")}</Th>
						<Th>{t("farmsList.columnLng")}</Th>
						<Th>{t("farmsList.columnNumFields")}</Th>
						<Th>{t("farmsList.columnAcres")}</Th>
						<Th>&nbsp;</Th>
					</Tr>
				</Thead>
				<Tbody>
					{ farmFields.map(f => renderTableRow(f))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};



