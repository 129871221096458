import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	AccordionPanel,
	Box,
	BoxProps,
	Button,
	Heading,
	Progress,
	Table,
	TableContainer,
	Tag,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react";



import { FieldFileList } from "./FieldFileList";
import { ICompany, IField, IFieldAllData, IFieldFile, IFolderContents } from "../models";
import { useApi } from "../services";
import { Config } from "../config/Config";
import { useGlobalDispatch, globalSetError, globalSetInfo } from "../contexts/GlobalContext";
import { isIProblemDetails } from "../models";
import { useAuthorizationState } from "../contexts/AuthorizationContext";



interface IFolderListProps {
	field?: IField | null | undefined;
	company?: ICompany | null | undefined;
	folder: IFolderContents;
	isCompanyAdmin?: boolean;
	isGecoAdmin?: boolean;
	isGecoPrivate?: boolean;
	onUpdate?: (f: IFieldFile) => void;
	onDelete?: (f: IFieldFile) => void;
}

interface IFoldersListProps extends BoxProps {
	title: string;
	field?: IField | null | undefined;
	company?: ICompany | null | undefined;
	folders?: IFolderContents[];
	isCompanyAdmin?: boolean;
	isGecoAdmin?: boolean;
	isGecoPrivate?: boolean;
	onUpdate?: (f: IFieldFile) => void;
	onDelete?: (f: IFieldFile) => void;
}





const FolderList = ({
	field,
	company,
	folder,
	isCompanyAdmin = false,
	isGecoAdmin = false,
	isGecoPrivate = false,
	onUpdate = () => {},
	onDelete = () => {},
}: IFolderListProps): JSX.Element => {
	const { t } = useTranslation();
	const { downloadFile, isLoading, deleteFile } = useApi(Config.baseUrl);
	const navigate = useNavigate();
	const globalDispatch = useGlobalDispatch();
	const { user } = useAuthorizationState();
	const [ currentFile, setCurrentFile ] = useState(-1);

	const handleClick = (): void => {
		const url = `/upload?user=${user?.id}&company=${user?.companyId}&farm=${field?.farmId}&field=${field?.id}&isGecoAdmin=${isGecoAdmin}&isGecoPrivate=${isGecoPrivate}`;
		navigate(url);
	};

	const handleDownload = async (f: IFieldFile): Promise<void> => {
		setCurrentFile(f.id);
		const blob = await downloadFile(f);
		if (!blob || isIProblemDetails(blob)) {
			setCurrentFile(-1);
			return;
		}

		const url = window.URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.download = f.filename;
		link.click();

		window.URL.revokeObjectURL(url);
		setCurrentFile(-1);
	};

	const handleDelete = async (f: IFieldFile): Promise<void> => {
		setCurrentFile(f.id);
		const result = await deleteFile(f);
		console.log(result);
		if (isIProblemDetails(result)) {
			// show error message
			globalDispatch(globalSetError(t("fieldFiles.errorDeleting"), t("fieldFiles.errorTitle")));
			return;
		}

		globalDispatch(globalSetInfo(t("fieldFiles.deletedFile"), t("fieldFiles.successTitle")));
		onDelete(f);
		setCurrentFile(-1);
	};

	const handleEditClick = (f: IFieldFile): void => {
		console.log(f);
		const url = `/editFile?user=${user?.id}&company=${f?.companyId}&farm=${f?.farmId}&field=${f?.fieldId}&file=${f.id}&isGecoAdmin=${isGecoAdmin}&isGecoPrivate=${isGecoPrivate}`;
		navigate(url);
	}

	const renderSourceTag = (f: IFieldFile): JSX.Element => {
		if (f.gecoPrivate) {
			return <Tag variant="solid" colorScheme="red">{t("fieldFiles.sourceGecoPrivate")}</Tag>
		}

		return f.gecoSupplied
			? <Tag variant="solid" colorScheme="blue">{t("fieldFiles.sourceGeco")}</Tag>
			: <Tag variant="subtle" colorScheme="black">{t("fieldFiles.sourceCustomer")}</Tag>;
	};

	const renderEdit = (f: IFieldFile): JSX.Element | null => {
		if (isLoading) { return null; }

		console.log(`checking for geco admin: ${isGecoAdmin}`);
		if (isGecoAdmin) {
			console.log("isgecoadmin");
			return <Button variant="link" onClick={() => handleEditClick(f)}>{t("fieldFiles.edit")}</Button>
		}

		// customers can't edit geco supplied files
		if (f.gecoSupplied || f.gecoPrivate) { return null; }
		// only company admins can edit
		if (!isCompanyAdmin) { return null; }
		return <Button variant="link" onClick={() => handleEditClick(f)}>{t("fieldFiles.edit")}</Button>;
	};

	const renderDelete = (f: IFieldFile): JSX.Element | null => {
		if (isLoading) { return null; }

		if (isGecoAdmin) {
			return <Button variant="link" onClick={() => handleDelete(f)}>{t("fieldFiles.delete")}</Button>
		}

		// customers can't edit geco supplied files
		if (f.gecoSupplied || f.gecoPrivate) { return null; }
		// only company admins can edit
		console.log(isCompanyAdmin);
		if (!isCompanyAdmin) { return null; }
		return isLoading && f.id === currentFile
			? <Progress isIndeterminate width="100px" hasStripe />
			: <Button variant="link" onClick={() => handleDelete(f)}>{t("fieldFiles.delete")}</Button>;
	};

	const renderDownload = (f: IFieldFile): JSX.Element => {
		return isLoading && f.id === currentFile
			? <Progress isIndeterminate width="100px" />
			: <Button variant="link" onClick={() => handleDownload(f)}>{t("fieldFiles.download")}</Button>
	};

	const renderSeparator = (f: IFieldFile): JSX.Element | null => {
		if (isLoading) { return null; }

		if (isGecoAdmin) {
			return <span> | </span>
		}

		if (f.gecoSupplied || f.gecoPrivate) { return null; }
		if (!isCompanyAdmin) { return null; }
		return <span> | </span>;
	};
	const renderFileRow = (key: string, f: IFieldFile): JSX.Element => {
		const d = f.createDateUtc.toString();
		return (
			<Tr key={key}>
				<Td>{f.id}</Td>
				<Td>{f.filename}</Td>
				<Td>{f.fileType}</Td>
				<Td>{f.description}</Td>
				<Td>{renderSourceTag(f)}</Td>
				<Td>{f.status}</Td>
				<Td>{d}</Td>
				<Td>
					{ renderDownload(f) } { renderSeparator(f) } { renderEdit(f) } { renderSeparator(f) } { renderDelete(f) }
				</Td>
			</Tr>
		);
	};

	return (
		<AccordionItem>
			<h2>
				<AccordionButton>
					<Box as="span" flex="1" textAlign="left" fontSize="18px" fontWeight="bold">
						{t(`fieldFolder.${folder.name}`)}
					</Box>
					<AccordionIcon />
				</AccordionButton>
			</h2>
			<AccordionPanel>
				{
					(folder.files.length === 0) && <Box p="1em">{t("fieldFiles.noFilesForFolder")}</Box>
				}
				{
					(folder.files.length > 0) &&
					<TableContainer>
						<Table variant="striped" size="md">
							<Thead>
								<Tr>
									<Th>{t("fieldFiles.headerId")}</Th>
									<Th>{t("fieldFiles.headerFilename")}</Th>
									<Th>{t("fieldFiles.headerFileType")}</Th>
									<Th>{t("fieldFiles.headerDescription")}</Th>
									<Th>{t("fieldFiles.headerSource")}</Th>
									<Th>{t("fieldFiles.headerStatus")}</Th>
									<Th>{t("fieldFiles.headerDate")}</Th>
									<Th>&nbsp;</Th>
								</Tr>
							</Thead>
							<Tbody>
								{
									folder.files.map((f, i) => renderFileRow(`${folder.name}-${i}`, f))
								}
							</Tbody>
						</Table>
					</TableContainer>
				}
			</AccordionPanel>
		</AccordionItem>
	);
};



export const FoldersList = ({
	title,
	field,
	company,
	folders = [],
	isCompanyAdmin = false,
	isGecoAdmin = false,
	isGecoPrivate = false,
	onUpdate = () => {},
	onDelete = () => {},
	...rest
}: IFoldersListProps): JSX.Element => {
	console.log(folders);

	return (
		<Box {...rest}>
			<Heading size="md" color="blue">{title}</Heading>
			<Accordion allowMultiple>
				{
					folders.map((f, index) => 
						<FolderList
							key={`folder-${index}`}
							field={field}
							company={company}
							folder={f}
							isCompanyAdmin={isCompanyAdmin}
							isGecoAdmin={isGecoAdmin}
							isGecoPrivate={isGecoPrivate}
							onDelete={onDelete}
							onUpdate={onUpdate}
						/>
					)
				}
			</Accordion>
		</Box>		
	);
};
