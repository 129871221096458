import { IFarm, IFarmWithField, IField, IUserAuthInfo } from "../../models";


export interface ITenantState {
	tenantSetup: boolean;
	farms: IFarm[];
	fields: IField[];
	farmFields: IFarmWithField[];
	authInfo: IUserAuthInfo;
	selectedFarm: IFarmWithField | null | undefined;
	selectedField: IField | null | undefined;
}



export const initialTenantState: ITenantState = {
	tenantSetup: false,
	farms: [],
	fields: [],
	farmFields: [],
	authInfo: {
		isGecoAdmin: false,
		isCompanyAdmin: false,
		isCompanyMember: false,
		permissions: [],
		companyId: -1,
		userId: -1,
	},
	selectedFarm: null,
	selectedField: null,
};
