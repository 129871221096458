import React from "react";
import { IconButton } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


interface IProps {
	to: string;
}


export const BackButton = ({ to }: IProps): JSX.Element => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleBack = (): void => {
		navigate(to);
	};


	return (
		<IconButton
			colorScheme="blue"
			icon={<ArrowBackIcon />}
			onClick={handleBack}
			aria-label={t("backButton")}
		/>
	);
};
