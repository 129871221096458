import React from "react";
import { Box, Flex, Heading } from "@chakra-ui/react";
import { BackButton } from "./BackButton";


interface IProps {
	to: string;
	title: string;
	size?: string;
}


export const TitleAndNav = ({ to, title, size = "sm" }: IProps): JSX.Element => {
	return (
		<Flex>
			<Box>
				<BackButton to={to} />
			</Box>
			<Box ml="1em">
				<Heading size={size}>{title}</Heading>
			</Box>
		</Flex>
	);
};

