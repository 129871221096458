import React, { useState } from "react";
import {
	Box,
	BoxProps,
	Button,
	ButtonGroup,
	Card,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Progress,
	Spacer,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Config } from "../config/Config";
import { useApi } from "../services";
import { IField, IFieldAdd, isIProblemDetails } from "../models";
import { globalSetError, globalSetInfo, useGlobalDispatch } from "../contexts/GlobalContext";


type FormValues = {
	name: string;
	description: string;
}

interface IProps extends BoxProps {
	companyId: number;
	farmId: number;
	showHeader?: boolean;
	onSave?: (f: IField) => void;
	onCancel?: () => void;
}


export const AddFieldForm = ({ companyId, farmId, showHeader = false, onCancel = () => {}, onSave = (f: IField) => {}, ...rest }: IProps): JSX.Element => {
	const { t } = useTranslation();
	const { isLoading, fieldAdd } = useApi(Config.baseUrl);
	const globalDispatch = useGlobalDispatch();

	const {
		register,
		handleSubmit,
		setValue,
	} = useForm<FormValues>({ defaultValues: { name: "", description: "" }});

	const onSubmit = handleSubmit(async data => {
		const fa: IFieldAdd = {
			companyId,
			farmId,
			name: data.name,
			description: data.description,
		};

		try {
			const field = await fieldAdd(fa);
			globalDispatch(globalSetInfo(t("addField.addSuccess"), t("addField.successTitle"), 5000));
			if (isIProblemDetails(field)) { throw new Error("error adding field"); }
			onSave(field as IField);
			setValue("name", "");
			setValue("description", "");
		} catch (e) {
			globalDispatch(globalSetError(t("addField.errorAdding"), t("addField.errorTitle")));
		}
	});

	const cardProps = {
		p: "2em",
		pt: "2em",
	};
	if (showHeader) { cardProps.pt = "0"; }

	return (
		<Box {...rest}>
			<Card {...cardProps}>
				<form onSubmit={onSubmit}>
					{ showHeader && <Heading size="sm">{t("addField.title")}</Heading>}
					<FormControl mt="10" isRequired>
						<Input
							id="name"
							size="md"
							type="text"
							data-peer
							placeholder=" "
							{ ...register("name")}
						/>
						<FormLabel htmlFor="name" variant="floating" size="md">
							{t("addField.labelName")}
						</FormLabel>
					</FormControl>
					<FormControl mt="10">
						<Input
							id="description"
							size="md"
							type="text"
							data-peer
							placeholder=" "
							{ ...register("description")}
						/>
						<FormLabel htmlFor="description" variant="floating" size="md">
							{t("addField.labelDescription")}
						</FormLabel>
					</FormControl>
					{
						!isLoading &&
						<Flex alignItems="center">
							<Spacer />
							<ButtonGroup mt="2em" textAlign="center">
								<Button variant="solid" type="submit" w="150px" h="40px" colorScheme="blue">
									{t("addField.save")}
								</Button>
								<Button variant="outline" type="button" w="150px" h="40px" colorScheme="blue" onClick={() => onCancel()}>
									{t("addField.cancel")}
								</Button>
							</ButtonGroup>
							<Spacer />
						</Flex>
					}
					{
						isLoading &&
						<Box p="2em">
							<Progress isIndeterminate hasStripe size="lg" colorScheme="blue" />
						</Box>
					}
				</form>
			</Card>
		</Box>
	);
};
