import { IFarmWithField } from "./IFarm";
import { IUserAuthInfo } from "./IUserAuthInfo";

export interface ICompany {
	id: number;
	name: string;
	location: string | null | undefined;
	adminId: number;
	adminEmail?: string;
}

export const emptyCompany: ICompany = {
	id: 0,
	name: "",
	location: undefined,
	adminId: -1,
};


export interface ICompanyUpdate {
	id: number;
	name: string;
	location: string | null | undefined;
}


export interface ICompanyContainer {
	company: ICompany | null | undefined;
	farms: IFarmWithField[];
	users: IUserAuthInfo[];
}


export interface ICompanyAdminExt extends ICompany {
	farmCount: number;
	fieldCount: number;
	fileCount: number;
	status?: string;
	due?: Date;
}
