import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Badge,
	Box,
	Button,
	Checkbox,
	Container,
	FormControl,
	FormLabel,
	Input,
	Progress,
	Select,
	Stack,
} from "@chakra-ui/react";


import { useApi } from "../services";
import { Config } from "../config/Config";
import { useGlobalDispatch, globalSetError } from "../contexts/GlobalContext";
import { useForm } from "react-hook-form";
import { isIProblemDetails } from "../models";
import { Layout } from "../components/layout/Index";
import { TitleAndNav } from "../components/TitleAndNav";
import { useAuthorizationState } from "../contexts/AuthorizationContext";


export type FormValues = {
	description: string;
	customerDueDate: Date | null | undefined;
	gecoDueDate: Date | null | undefined;
	isGecoSupplied: boolean;
	isGecoPrivate: boolean;
	userId: number;
	companyId: number;
	farmId: number;
	fieldId: number;
	fileId: number;
	folder: string;
}





export const EditFilePage = (): JSX.Element => {
	const { t } = useTranslation();
	const url = new URLSearchParams(window.location.search);
	const { isLoading, getFileData, updateFileData } = useApi(Config.baseUrl);
	const { isGecoAdmin } = useAuthorizationState();
	const globalDispatch = useGlobalDispatch();

	const formDefaults: FormValues = {
		description: "",
		customerDueDate: null,
		gecoDueDate: null,
		isGecoSupplied: false,
		isGecoPrivate: url.get("isGecoPrivate") === "true",
		userId: parseInt(url.get("user") || "0"),
		companyId: parseInt(url.get("company") || "0"),
		farmId: parseInt(url.get("farm") || "0"),
		fieldId: parseInt(url.get("field") || "0"),
		fileId: parseInt(url.get("file") || "0"),
		folder: "root",
	};
	console.log(formDefaults);

	const { register, handleSubmit, setValue } = useForm<FormValues>({ defaultValues: formDefaults });

	useEffect(() => {
		const load = async () => {
			const f = await getFileData(formDefaults.companyId, formDefaults.farmId, formDefaults.fieldId, formDefaults.fileId);
			if (!f) {
				globalDispatch(globalSetError(t("editFile.fileNotFound"), t("editFile.errorTitle")));
				return;
			}

			if (isIProblemDetails(f)) {
				globalDispatch(globalSetError(t("editFile.unableToRetrieve"), t("editFile.errorTitle")));
				return;
			}

			setValue("description", f.description || "");
			setValue("customerDueDate", f.customerDueDateUtc);
			setValue("gecoDueDate", f.gecoDueDateUtc);
			setValue("isGecoPrivate", f.gecoPrivate);
			setValue("isGecoSupplied", f.gecoSupplied);
			setValue("folder", f.folder);
		}

		load();
	}, []);

	const onSubmit = handleSubmit(async (data) => {
		const ff = await getFileData(data.companyId, data.farmId, data.fieldId, data.fileId);
		if (!ff || isIProblemDetails(ff)) {
			globalDispatch(globalSetError(t("editFile.unableToRetrieveFile"), t("editFile.errorTitle")));
			return;
		}

		ff.description = data.description;
		ff.folder = data.folder;
		ff.customerDueDateUtc = data.customerDueDate;
		if (isGecoAdmin) {
			ff.gecoPrivate = data.isGecoPrivate;
			ff.gecoDueDateUtc = data.gecoDueDate;
		}

		const result = await updateFileData(ff);
		if (!result || isIProblemDetails(result)) {
			globalDispatch(globalSetError(t("editFile.updateFailed"), t("editFile.errorTitle")));
			return;
		}
	});

	return (
		<Layout>
			<Container>
				<Stack spacing="10" mt="10">
					<TitleAndNav to={`/field?id=${formDefaults.fieldId}`} title={t("editFile.title")} />
					<form onSubmit={onSubmit}>
						<FormControl mt="10">
							<Input
								id="fieldDescription"
								size="md"
								type="text"
								data-peer
								placeholder=" "
								{...register("description")}
							/>
							<FormLabel htmlFor="fieldDescription" variant="floating" size="md">
								{t("editFile.labelDescription")}
							</FormLabel>
						</FormControl>

						<FormControl mt="10">
							<FormLabel htmlFor="folderSelect" variant="floating" size="lg">
								{t("upload.uploadFolder")}
							</FormLabel>
							<Select id="folderSelect" size="md" { ...register("folder")}>
								<option value="root">{t("fieldFolder.root")}</option>
								<option value="code">{t("fieldFolder.code")}</option>
								<option value="imagery">{t("fieldFolder.imagery")}</option>
								<option value="miscellaneous">{t("fieldFolder.miscellaneous")}</option>
								<option value="outputs">{t("fieldFolder.outputs")}</option>
								<option value="reports">{t("fieldFolder.reports")}</option>
								<option value="shapeFiles">{t("fieldFolder.shapeFiles")}</option>
								<option value="sprayMaps">{t("fieldFolder.sprayMaps")}</option>
							</Select>
						</FormControl>

						<FormControl mt="10">
							<Input
								id="customerDueDate"
								type="datetime-local"
								size="md"
								data-peer
								placeholder=" "
								{...register("customerDueDate")}
							/>
							<FormLabel htmlFor="customerDueDate" variant="floating" size="md">
								{t("editFile.labelCustomerDueDate")}
							</FormLabel>
						</FormControl>
						{
							isGecoAdmin &&
							<FormControl mt="10">
								<Input
									id="gecoDueDate"
									type="datetime-local"
									size="md"
									data-peer
									placeholder=" "
									{...register("gecoDueDate")}
								/>
								<FormLabel htmlFor="gecoDueDate" variant="floating" size="md">
									{t("editFile.labelGecoDueDate")}
								</FormLabel>
							</FormControl>
						}
						<Box mt="10">
							{
								formDefaults.isGecoSupplied || formDefaults.isGecoPrivate
									? <Badge variant="solid" size="lg" colorScheme="blue">{t("editFile.labelGecoSupplied")}</Badge>
									: <Badge variant="outline" size="lg" colorScheme="blue">{t("editFile.labelCustomerSupplied")}</Badge>
							}
						</Box>
						{
							isGecoAdmin &&
							<FormControl mt="10">
								<Checkbox size="lg" colorScheme="red" {...register("isGecoPrivate")}>
									{t("editFile.labelGecoPrivate")}
								</Checkbox>
							</FormControl>
						}

						<input type="hidden" {...register("userId")} />
						<input type="hidden" {...register("companyId")} />
						<input type="hidden" {...register("farmId")} />
						<input type="hidden" {...register("fieldId")} />
						<input type="hidden" {...register("fileId")} />

						{ !isLoading && <Button mt="4" colorScheme="blue" type="submit">{t("editFile.updateButton")}</Button> }
						{ isLoading && <Progress w="100%" mt="2em" isIndeterminate colorScheme="blue" hasStripe /> }
					</form>
				</Stack>
			</Container>
		</Layout>
	);
};
