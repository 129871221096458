import React, { Dispatch, createContext, useReducer, useContext, useState } from "react";

import { IAuthorizationState, initialAuthorizationState } from "./IAuthorizationState";
import { AuthAction } from "./actions";
import { reducer } from "./reducer";


interface IProps {
	data?: IAuthorizationState;
	children: JSX.Element;
}


export const AuthorizationStateContext = createContext<IAuthorizationState | undefined>(undefined);
export const AuthorizationDispatchContext = createContext<Dispatch<AuthAction> | undefined>(undefined);


export const AuthorizationProvider = ({ data = initialAuthorizationState, children }: IProps): JSX.Element => {
	const [ state, dispatch ] = useReducer(reducer, data);

	return (
		<AuthorizationStateContext.Provider value={state}>
			<AuthorizationDispatchContext.Provider value={dispatch}>
				{children}
			</AuthorizationDispatchContext.Provider>
		</AuthorizationStateContext.Provider>
	);
};


export const useAuthorizationState = (): IAuthorizationState => {
	const context = useContext(AuthorizationStateContext);
	if (context === undefined) { throw new Error("must be used within the provider"); }
	return context;
};

export const useAuthorizationDispatch = (): Dispatch<AuthAction> => {
	const context = useContext(AuthorizationDispatchContext);
	if (context === undefined) { throw new Error("must be used within the provider"); }
	return context;
};


