import {
    ChangeEvent,
    FormEvent,
    useState
} from 'react'

import {
    Box,
    Button,
    Card,
    Container,
    FormControl,
    FormLabel,
    Input,
    Text,
    Textarea
} from '@chakra-ui/react'
import { EmailIcon } from '@chakra-ui/icons'

import axios from 'axios';
import { useTranslation } from "react-i18next";

import { Config } from "../../config/Config";
import { IContactRequest } from '../../models/IContactRequest'


export const ContactUsDetails = () => {

    const { t } = useTranslation();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const [formData, setFormData] = useState<IContactRequest>({
        email: '',
        phoneNumber: '',
        message: ''
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setIsSending(true);
        const jsonData = JSON.stringify(formData);
        axios.post(`${Config.baseUrl}api/v1/contactrequest`, jsonData, { headers: { "Content-Type": "application/json" }})
            .then((response) => {
                console.log(response.data);
                setIsSubmitted(true);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setIsSending(false);
            });
    };


    return (
		<Container mt="-1em" py={{ base: "16", md: "12" }}>
			<Card>
				<Box
					bg="bg.surface"
					boxShadow="sm"
					borderRadius="lg"
					p={{ base: "4", md: "6" }}
				>
                {isSubmitted ? (
						<Text>{t('contactUs.submissionSuccess')}</Text>
                    ) : (                    
                    <form onSubmit={handleSubmit}>

                        <FormControl isRequired>
                            <FormLabel>{t('contactUs.formLabelEmail')}</FormLabel>
                            <Input
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </FormControl>

                        <FormControl mt={6}>
                            <FormLabel>{t('contactUs.formLabelPhone')}</FormLabel>
                            <Input
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                            />
                        </FormControl>

                        <FormControl mt={6} isRequired>
                            <FormLabel>{t('contactUs.formLabelMessage')}</FormLabel>
                            <Textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                            />
                        </FormControl>

                        <Text mt={6}>
                            {t('contactUs.neverShare')}
                        </Text>

                        <Button leftIcon={<EmailIcon />} variant="primary" mt={4} type="submit" isLoading={isSending} loadingText={t('contactUs.formButtonSending')}>
                            {t('contactUs.formButtonSubmit')}
                        </Button>
                        
                    </form>
                )}
                </Box>
            </Card>
        </Container>
    );
  }