import { IFarmWithField } from "./IFarm";
import { IUserAuthInfo } from "./IUserAuthInfo";

export interface IUserTenantData {
	authInfo: IUserAuthInfo;
	farms: IFarmWithField[];
}



export const isIUserTenantData = (i: any): i is IUserTenantData => {
	if (!i) { return false; }
	return (
		"farms" in i &&
		"authInfo" in i
	);
};
