import { IFarm, IFarmWithField, IField, IUserAuthInfo } from "../../models";


export enum TenantActionTypes {
	SET_TENANT_DATA = "@tenant/set",
	SET_TENANT_AUTH_DATA = "@tenant/setTenAuth",
	SET_SELECTED_FARM = "@tenant/setSelectedFarm",
	SET_SELECTED_FIELD = "@tenant/setSelectedField",
}


export type TenantAction = {
	type: TenantActionTypes;
	fields?: IField[];
	farms?: IFarm[];
	farmFields?: IFarmWithField[];
	authInfo?: IUserAuthInfo;
	selectedFarm?: IFarmWithField;
	selectedField?: IField;
}


export const setTenantData = (farms: IFarm[], fields: IField[]): TenantAction => ({
	type: TenantActionTypes.SET_TENANT_DATA,
	farms,
	fields,
});


export const setTenantAuthData = (farmFields: IFarmWithField[], authInfo: IUserAuthInfo): TenantAction => ({
	type: TenantActionTypes.SET_TENANT_AUTH_DATA,
	farmFields,
	authInfo,
});


export const setSelectedFarm = (f: IFarmWithField): TenantAction => ({
	type: TenantActionTypes.SET_SELECTED_FARM,
	selectedFarm: f,
});


export const setSelectedField = (f: IField): TenantAction => ({
	type: TenantActionTypes.SET_SELECTED_FIELD,
	selectedField: f,
});


