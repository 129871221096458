import React from "react";
import { Box, Button, Center, Heading, } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";


export const NotAuthorizedPage = (): JSX.Element => {
	const { t } = useTranslation();
	const { instance } = useMsal();

	const handleLogout = (): void => {
		instance?.logoutRedirect();
	};

	return (
		<Box mt="3em">
			<Center>
				<Heading size="md">
					{t("notAuthorized.title")}
				</Heading>
			</Center>
			<Center mt="0.5em">
				<Heading size="sm">
					{t("notAuthorized.title2")}
				</Heading>
			</Center>
			<Center>
				<Button variant="primary" onClick={handleLogout} w="150px" h="40px" mt="2.5em">
					{t("notAuthorized.logout")}
				</Button>
			</Center>
		</Box>
	);
};
