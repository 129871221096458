let baseUrl = "https://geco-api.azurewebsites.net/";	// the base URL to connect to for api calls
let enableLogin = true;									// permit users to login or not
let enableCompanyEdit = true;							// feature to edit company information
let enableAdminCompanyList = true;						// feature to allow geco admin to view all companies
let enableAdminUserInvite = true;						// feature to invite a new user

if (window.origin === "http://localhost:3000") {
	baseUrl = "https://localhost:44383/";
	baseUrl = "https://geco-api-staging.azurewebsites.net/";
	enableLogin = true;
	enableCompanyEdit = true;
	enableAdminCompanyList = true;
	enableAdminUserInvite = true;
}

if (window.origin.includes("purple-beach")) {
	// this is the staging environment static app
	// use the staging api for it
	baseUrl = "https://geco-api-staging.azurewebsites.net/";
}

if (window.origin.includes("https://blue-smoke-054b86a1e-77.westus2.3.azurestaticapps.net")) {
	enableLogin = true;
}


export const Config = {
	baseUrl,
	enableMultiFields: false,
	enableMultiFarms: false,
	enableUsers: false,
	enableLogin,
	enableCompanyEdit,
	enableAdminCompanyList,
	enableAdminUserInvite,
};
