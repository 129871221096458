import {
	Box,
	Drawer,
	DrawerContent,
	DrawerOverlay,
	Flex,
	useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { Logo } from "./Logo";
import { Sidebar } from "./Sidebar";
import { ToggleButton } from "./ToggleButton";

export const Navbar = () => {
	const { t } = useTranslation();
	const { isOpen, onToggle, onClose } = useDisclosure();

	return (
		<Box
			width="full"
			py="4"
			px={{ base: "4", md: "8" }}
			bg="bg-surface"
			boxShadow="sm"
		>
			<Flex justify="space-between">
				{/* <Logo /> */}
				<img
					src="gecomobile.png"
					alt={t("logo") || ""}
					height="40px"
					width="164px"
				/>
				<ToggleButton
					isOpen={isOpen}
					aria-label="Open Menu"
					onClick={onToggle}
				/>
				<Drawer
					isOpen={isOpen}
					placement="left"
					onClose={onClose}
					isFullHeight
					preserveScrollBarGap
					// Only disabled for showcase
					trapFocus={false}
				>
					<DrawerOverlay />
					<DrawerContent>
						<Sidebar />
					</DrawerContent>
				</Drawer>
			</Flex>
		</Box>
	);
};
