import { Box, Card, CardBody, Container, ListItem, Text, UnorderedList, useBreakpointValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { ImageContainer } from "../ImageContainer";
import { BottomOfPageContactUs } from "../BottomOfPageContactUs";

export const WeedPredictionDetails = (): JSX.Element => {
	const { t } = useTranslation();
	const isDesktop = useBreakpointValue({ base: false, md: true });

	// this is here as a reminder to do something about the images being displayed in
	// mobile so that they are viewable.
	//const weedVsActual = isDesktop ? "weed-prediction-vs-actual.png" : "weed-prediction-vs-actual-mobile.png";
	const weedVsActual = "weed-prediction-vs-actual.png";

	return (
		<Container py={{ base: "16", md: "12" }}>
			<Card>
				<CardBody>
					<Text>{t("weedPredict.detailsPara1")}</Text>
					<Box mb="1em">
						<UnorderedList>
							<ListItem>{t("weedPredict.detailsItem1")}</ListItem>
							<ListItem>{t("weedPredict.detailsItem2")}</ListItem>
							<ListItem>{t("weedPredict.detailsItem3")}</ListItem>
						</UnorderedList>
					</Box>
					<ImageContainer
						width="665px"
						height="240px"
						url={weedVsActual}
						alt={t("weedPredict.comparisonImageAlt") || "predict vs actual"}
						center
					/>
					<Text mt="1em" mb="1em">
						{t("weedPredict.detailsPara2")}
					</Text>
					<ImageContainer
						center
						url="weed-prediction-years.png"
						alt={t("weedPredict.predictOverYearsAlt") || "Weed Prediction over Years"}
					/>
					<Text mt="1em" mb="1em">{t("weedPredict.detailsPara3")}</Text>
					<ImageContainer
						center
						width="900px"
						height="415px"
						url="weed-prediction-choices.png"
						alt={t("weedPredict.backCastingAlt") || "Weed Prediction Backcasting"}
					/>
					<ImageContainer
						mt="1em"
						pt="1em"
						center
						width="1166px"
						height="1203px"
						url="weed-prediction-uses.png"
						alt={t("weedPredict.predictionMapAlt") || "Weed Prediction Map"}
					/>
				</CardBody>
			</Card>

			<BottomOfPageContactUs />

		</Container>
	);
};
