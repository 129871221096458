import React, { useEffect, useState } from "react";
import {
	Box,
	Card,
	CircularProgress,
	Heading,
	Progress,
	Stack,
} from "@chakra-ui/react";

import { Layout } from "../components/layout/Index";
import { useAuthorizationState } from "../contexts/AuthorizationContext";
import { useTranslation } from "react-i18next";
import { CompanySetup } from "../components/CompanySetup";
import { FieldList } from "../components/FieldList";
import { useApi } from "../services";
import { Config } from "../config/Config";
import { ICompany, IUser, IMruField, isIProblemDetails, isIUserTenantData } from "../models";
import { setTenantAuthData, useTenantDispatch } from "../contexts/TenantContext";
import { CompanyEditor } from "../components/CompanyEditor";
import { Loading } from "../components/Loading";
import { DashboardFieldList } from "../components/DashboardFieldList";
import { useNavigate } from "react-router-dom";

export const MainPage = (): JSX.Element => {
	const { t } = useTranslation();
	const { user, company } = useAuthorizationState();
	const { isLoading, loadTenant, getDashboard } = useApi(Config.baseUrl);
	const [ tenantSetup, setTenantSetup ] = useState(user !== null && company !== null);
	const [ isInitialLoad, setIsInitialLoad ] = useState(true);
	const [ fields, setFields ] = useState<IMruField[]>([]);
	const tenantDispatch = useTenantDispatch();
	const navigate = useNavigate();

	// useEffect(() => {
	// 	const load = async () => {
	// 		const data = await loadTenant();
	// 		if (isIUserTenantData(data)) {
	// 			tenantDispatch(setTenantAuthData(data.farms, data.authInfo));
	// 			setTenantSetup(true);
	// 			setIsInitialLoad(false);
	// 		}
	// 	}

	// 	load();
	// }, [ ]);


	useEffect(() => {
		console.log("inside of user, company changed");
		const load = async () => {
			const data = await loadTenant();
			if (isIUserTenantData(data)) {
				console.log(data.authInfo);
				if (data.authInfo.isCompanyAdmin || data.authInfo.isCompanyMember) {
					tenantDispatch(setTenantAuthData(data.farms, data.authInfo));
				} else {
					navigate("/notAuthorized");
				}
			}
		};

		if (user && company) { load(); }
	}, [ user, company ]);

	useEffect(() => {
		const load = async () => {
			const data = await getDashboard();
			console.log(data);
			if (isIProblemDetails(data)) {
				return;
			}

			setFields(data.fields);
		}

		load();
	}, [ user, company ]);


	const isCompanySetup = (u: IUser | null, c: ICompany | null): boolean =>
		(c !== null && u !== null);

	if (isLoading && isInitialLoad) { return <Loading fullScreen />; }

	return (
		<Layout>
			<Stack spacing={{ base: "8", lg: "6" }}>
				{ (!isLoading && isCompanySetup(user, company)) && <Heading size={{ base: "md"}}>{t("userDash.title")}</Heading> }
				{ (!isLoading && !isCompanySetup(user, company)) && <CompanySetup /> }
				{
					(!isLoading && isCompanySetup(user, company)) &&
					<>
						{ Config.enableCompanyEdit && <CompanyEditor /> }
						<Card mt="1em" p="1em">
							<Heading size="sm" mb="1em">{t("fields.title")}</Heading>
							<DashboardFieldList fields={fields} />
						</Card>
					</>
				}
				{ isLoading && <Progress isIndeterminate width="100%" colorScheme="blue" size="lg" hasStripe /> }
			</Stack>
		</Layout>
	);
};
