import { AdminUserAction, AdminUserActionTypes } from "./actions";
import { initialState, IAdminUserState } from "./IAdminUserState";


export const reducer = (state: IAdminUserState = initialState, action: AdminUserAction): IAdminUserState => {
	switch (action.type) {
		case AdminUserActionTypes.SET_FARMS:
			return {
				...state,
				farms: action.farms || state.farms,
			};
		case AdminUserActionTypes.SET_SELECTED_FARM:
			return {
				...state,
				selectedFarm: action.selectedFarm === undefined ? state.selectedFarm : action.selectedFarm,
			};
		case AdminUserActionTypes.SET_USERS:
			return {
				...state,
				users: action.users !== undefined ? action.users : state.users,
			};
		case AdminUserActionTypes.SET_NEED_UPDATE:
			return {
				...state,
				needUpdate: action.needUpdate !== undefined ? action.needUpdate : state.needUpdate,
			};
		case AdminUserActionTypes.SET_USER_PERMISSIONS:
			return {
				...state,
				userPermissions: action.userPermissions !== undefined ? action.userPermissions : state.userPermissions,
			};
		default:
			return state;
	}
};
