import React from "react";
import { Button, Table, TableContainer, Tbody, Thead, Th, Td, Tr } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { IField } from "../models";

interface IProps {
	fields: IField[];
	isInAdmin?: boolean;
}

export const FieldList = ({ fields, isInAdmin = false }: IProps): JSX.Element => {
	const { t } = useTranslation();

	const renderFieldRow = (key: string, f: IField): JSX.Element => {

		return (
			<Tr key={key}>
				<Td>
					<NavLink to={`/${isInAdmin ? "admin" : ""}field?id=${f.id}`}>{f.id}</NavLink>
				</Td>
				<Td>
					<NavLink to={`/${isInAdmin ? "admin" : ""}field?id=${f.id}`}>{f.name}</NavLink>
				</Td>
				<Td>{f.description}</Td>
				<Td>{f.latitude}</Td>
				<Td>{f.longitude}</Td>
				<Td>{f.acres || "0"}</Td>
				<Td>{f.dueDate?.toString()}</Td>
				<Td>{t(`fieldStatus.${f.status}`)}</Td>
			</Tr>
		);
	};

	return (
		<TableContainer>
			<Table variant="simple" size="md">
				<Thead>
					<Tr>
						<Th>{t("fields.idHeader")}</Th>
						<Th>{t("fields.nameHeader")}</Th>
						<Th>{t("fields.descriptionHeader")}</Th>
						<Th>{t("fields.latHeader")}</Th>
						<Th>{t("fields.lngHeader")}</Th>
						<Th>{t("fields.acresHeader")}</Th>
						<Th>{t("fields.dueDateHeader")}</Th>
						<Th>{t("fields.fileStatusHeader")}</Th>
					</Tr>
				</Thead>
				<Tbody>
					{
						fields.map((f, i) => renderFieldRow(i.toString(), f))
					}
				</Tbody>
			</Table>
		</TableContainer>
	);
};

