export interface IProblemDetails {
	type?: string;
	title?: string;
	status?: number;
	detail?: string;
	instance?: string;
}


export const isIProblemDetails = (i: any): i is IProblemDetails => {
	if (!i) { return false; }
	return (
		"title" in i &&
		"status" in i &&
		"detail" in i &&
		"instance" in i
	);
};

