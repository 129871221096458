import {
	Container,
	SimpleGrid,
	Stack,
} from "@chakra-ui/react";

import { ITeamMemberHighlight, TeamMember } from "./TeamMember";

const devin: ITeamMemberHighlight[] = [
	{
		highlight:
			"International expert in ecology and evolutionary biology, population modelling, data science",
		subHighlights: [],
	},
	{
		highlight: "14 peer-reviewed publications",
		subHighlights: [],
	},
	{
		highlight: "2 patents",
		subHighlights: [],
	},
	{
		highlight:
			"Postdoctoral appointments with Stanford University and University of British Columbia",
		subHighlights: [],
	},
];

const greg: ITeamMemberHighlight[] = [
	{
		highlight: "24 years creating novel data solutions",
		subHighlights: [],
	},
	{
		highlight: "Honeywell - 20 years",
		subHighlights: [],
	},
	{
		highlight: "Agtech - 4 years",
		subHighlights: [
			"Ecoation - VP Data Science",
			"Advisor to 4 agtech startups",
		],
	},
	{
		highlight: "Deployed into production:",
		subHighlights: [
			"12 commercial greenhouses",
			"350 paper machines",
			"35,000 production vehicles",
			"$40M/year manufacturing",
		],
	},
	{
		highlight: "51 patents, 58 technical articles",
		subHighlights: [],
	},
];

export const Team = (): JSX.Element => {
	return (
		<Container py={{ base: "16", md: "24" }}>
			<Stack spacing={{ base: "12", md: "16" }}>
				<SimpleGrid
					columns={{ base: 1, md: 2, lg: 3 }}
					columnGap="8"
					rowGap={{ base: "6", md: "8", lg: "16" }}
				>
					<TeamMember
						name="Greg Stewart, PhD"
						title="CEO, Founder"
						avatarSrc="greg-stewart.jpg"
						highlights={greg}
						linkedIn="https://www.linkedin.com/in/greg-stewart-91780b/"
						email="mailto: greg.stewart@geco-ag.com"
						phone="tel: +1-604-809-0430"
					/>

					<TeamMember
						name="Devin Kirk, PhD"
						title="Senior Data Scientist"
						avatarSrc="devin-kirk.jpg"
						highlights={devin}
						email="mailto:devin.kirk@geco-ag.com"
						linkedIn="https://www.linkedin.com/in/devin-kirk-467409114/"
					/>
				</SimpleGrid>
			</Stack>
		</Container>
	);
};
