import { IUser, ICompany, IFarmPermission } from "../../models";


export interface IAuthorizationState {
	user: IUser | null;
	company: ICompany | null;
	permissions: IFarmPermission[];
	isCompanyAdmin: boolean;
	isGecoAdmin: boolean;
	adminCompanyId?: number;
}


export const initialAuthorizationState: IAuthorizationState = {
	user: null,
	company: null,
	permissions: [],
	isCompanyAdmin: false,
	isGecoAdmin: false,
};

