import {
    Avatar,
    Box,
    HStack,
    Icon,
    Link,
    ListItem,
    Stack,
    Text,
    UnorderedList,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaLinkedin } from "react-icons/fa";
import { IconType } from "react-icons/lib";
import { MdOutlineEmail, MdPhoneInTalk, MdVerifiedUser } from "react-icons/md";

// no recursion. only support one sub level of highlights
export interface ITeamMemberHighlight {
	highlight: string;
	subHighlights: string[];
}

interface ITeamMemberProps {
	avatarSrc?: string;
	name: string;
	title: string;
	highlights?: ITeamMemberHighlight[];
	email?: string;
	phone?: string;
	linkedIn?: string;
}

export const TeamMember = ({
	avatarSrc,
	name,
	title,
	highlights,
	email,
	phone,
	linkedIn,
}: ITeamMemberProps): JSX.Element => {
	const { t } = useTranslation();


	const renderHighlight = (h: ITeamMemberHighlight, i: number): JSX.Element => {
		const key = i.toString();
		return (
			<ListItem key={key}>
				{h.highlight}
				{
					h.subHighlights.length > 0 &&
					<UnorderedList>
						{ h.subHighlights.map((s, i) => <ListItem key={`${key}.${i.toString()}`}>{s}</ListItem>)}
					</UnorderedList>
				}
			</ListItem>
		)
	};

	const renderContactLink = (iconType: IconType, url: string, openNew: boolean): JSX.Element => {
		const attribs: any = {};
		if (openNew) { attribs.target = "_blank" };
		return (
			<Link href={url} { ...attribs }>
				<Icon as={iconType} boxSize="5" />
			</Link>
		);
	};

	return (
		<Box bg="bg.surface" p="6" boxShadow="sm" borderRadius="md">
			<Stack spacing="4" align="center">
				<Stack>
					<Stack spacing={{ base: "4", md: "5" }} align="center">
						{
							avatarSrc ?
							<Avatar src={avatarSrc} boxSize={{ base: "16", md: "20" }} /> :
							<Icon as={MdVerifiedUser} boxSize={{ base: "16", md: "20" }} />
						}
						<Box>
							<Text fontWeight="medium" fontSize="lg" align="center">{name}</Text>
							<Text color="accent" align="center">{title}</Text>
						</Box>
					</Stack>
					{
						highlights &&
						<UnorderedList>
							{
								highlights.map((h, i) => renderHighlight(h, i))
							}
						</UnorderedList>
					}
				</Stack>
				<HStack spacing="4" color="fg.subtle">
					{ linkedIn && renderContactLink(FaLinkedin, linkedIn, true) }
					{ email && renderContactLink(MdOutlineEmail, email, false) }
					{ phone && renderContactLink(MdPhoneInTalk, phone, false) }
				</HStack>
			</Stack>
		</Box>
	);
};
