import { ICompany, ICompanyAdminExt, IFarmWithField, IUserAuthInfo } from "../../models";

export interface IAdminState {
	companies: ICompanyAdminExt[];
	company: ICompany | undefined;
	farms: IFarmWithField[];
	users: IUserAuthInfo[];
	selectedFarm: IFarmWithField | null | undefined;
}



export const initialAdminState: IAdminState = {
	companies: [],
	company: undefined,
	farms: [],
	users: [],
	selectedFarm: null,
};
