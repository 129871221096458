import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Box, Card, Heading } from "@chakra-ui/react";

import { Layout } from "../components/layout/Index";
import { useApi } from "../services";
import { Config } from "../config/Config";
import { AdminProvider, setAllCompanyData, setSelectedFarm, useAdminDispatch, useAdminState } from "../contexts/AdminContext";
import { IField, emptyCompany, isIProblemDetails } from "../models";
import { AdminFarmList } from "../components/AdminFarmList";
import { FieldList } from "../components/FieldListProps";
import { AddFieldForm } from "../components/AddFieldForm";
import { setAdminCompany, useAuthorizationDispatch, useAuthorizationState } from "../contexts/AuthorizationContext";
import { AdminCompanyEditor } from "../components/AdminCompanyEditor";



const AdministratorCompanyInnerPage = (): JSX.Element => {
	const { t } = useTranslation();
	const { isLoading, adminGetAllCompanyData } = useApi(Config.baseUrl);
	const adminDispatch = useAdminDispatch();
	const { company, farms, selectedFarm } = useAdminState();
	const { adminCompanyId } = useAuthorizationState();
	const [ selectedFarmId, setSelectedFarmId ] = useState(0);
	const authDispatch = useAuthorizationDispatch();


	useEffect(() => {
		const load = async (id: number) => {
			const urls = new URLSearchParams(window.location.search);
			const farmId = urls.get("farm");
			const data = await adminGetAllCompanyData(id);
			if (!isIProblemDetails(data)) {
				adminDispatch(setAllCompanyData(
					data?.company || emptyCompany,
					data?.farms || [],
					data?.users || []
				));
				if (farmId) {
					const farm = data?.farms.find(f => f.id === parseInt(farmId));
					if (farm) { setSelectedFarmId(farm.id); }
				}
			}
			console.log(data);
		};

		const urlParams = new URLSearchParams(window.location.search);
		const idString = urlParams.get("id");
		console.log(`loading for company ${idString}`);
		if (!isLoading && idString) {
			const parsedId = parseInt(idString);
			authDispatch(setAdminCompany(parsedId));
			load(parsedId);
		}
	}, [ ]);

	const updatedAfterSave = (f: IField): void => {
		console.log("udpateaftersave");
		const load = async (id: number, farmId: number) => {
			setSelectedFarmId(farmId);
			const data = await adminGetAllCompanyData(id);
			if (!isIProblemDetails(data)) {
				adminDispatch(setAllCompanyData(
					data?.company || emptyCompany,
					data?.farms || [],
					data?.users || []
				));
				console.log(farms);
				const farm = farms.find(cf => cf.id === farmId);
				if (farm) {
					console.log(farm);
					adminDispatch(setSelectedFarm(farm));
				}
			}
			console.log(data);
		};

		if (!selectedFarm) { return; }
		const selectedFarmId = selectedFarm.id;
		const urlParams = new URLSearchParams(window.location.search);
		const idString = urlParams.get("id");

		if (!isLoading && idString) {
			const parsedId = parseInt(idString);
			authDispatch(setAdminCompany(parsedId));
			load(parsedId, selectedFarmId);
		}
	};

	useEffect(() => {
		console.log("updating field list");
		if (selectedFarmId === 0) { return; }
		const newSelectedFarm = farms.find(d => d.id === selectedFarmId);
		if (newSelectedFarm) { adminDispatch(setSelectedFarm(newSelectedFarm)); }
	}, [ selectedFarmId, farms, adminDispatch ]);

	return (
		<Layout>
			<>
				<Box>
					<AdminCompanyEditor />
				</Box>
				<Card p="1em" mt="1em">
					<Heading size="sm">{`${t("adminCompany.farmsTitle")} ${company?.name}`}</Heading>
					<AdminFarmList />
				</Card>
				{
					selectedFarm &&
					<>
						<Card mt="1em" p="1em">
							<Heading size="sm">{`${t("adminCompany.fieldsTitle")} ${selectedFarm?.name}`}</Heading>
							<FieldList fields={selectedFarm.fields} isInAdmin={true} />
						</Card>
						<Box mt="1em">
							<AddFieldForm
								companyId={adminCompanyId || 0}
								farmId={selectedFarm?.id || 0}
								onSave={updatedAfterSave}
							/>
						</Box>
					</>
				}
			</>
		</Layout>
	);
};


export const AdministratorCompanyPage = (): JSX.Element => {

	return (
		<AdminProvider>
			<AdministratorCompanyInnerPage />
		</AdminProvider>
	);
};
