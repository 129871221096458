import React, { useEffect, useState } from "react";
import {
	Box,
	BoxProps,
	Button,
	Card,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	CardHeader,
	CardBody,
	CardFooter,
	Heading,
	ButtonGroup,
	Stack,
	Flex,
	Accordion,
	AccordionItem,
	Spacer,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { set, useForm } from "react-hook-form";

import { Config } from "../config/Config";
import { useApi } from "../services";
import { useTenantState } from "../contexts/TenantContext";
import { IFarm, IFarmWithField } from "../models";

type FormValues = {
	name: string;
	address: string;
	latitude?: number;
	longitude?: number;
	acres?: number;
	fields?: number;
}

interface IProps extends BoxProps {
	onSave?: () => void;
	onCancel?: () => void;
	selectedFarm: IFarmWithField | null | undefined;
}


export const FarmEditor = ({ selectedFarm, onSave = () => {}, onCancel = () => {}, ...rest }: IProps): JSX.Element => {
	const { t } = useTranslation();
	const { isLoading, updateFarm } = useApi(Config.baseUrl);
	const [ defaultValues, setDefaultValues ] = useState<any>({
		name: selectedFarm?.name || "",
		address: selectedFarm?.farmAddress,
		latitude: selectedFarm?.latitude,
		longitude: selectedFarm?.longitude,
		acres: selectedFarm?.totalAcres,
		fields: selectedFarm?.numberOfFields,
	});


	const {
		control,
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<FormValues>({ defaultValues });

	useEffect(() => {
		console.log("inside of update selected farm effect");
		setValue("name", selectedFarm?.name || "");
		setValue("address", selectedFarm?.farmAddress || "");
		setValue("latitude", selectedFarm?.latitude);
		setValue("longitude", selectedFarm?.longitude);
		setValue("acres", selectedFarm?.totalAcres);
		setValue("fields", selectedFarm?.numberOfFields);
	}, [ selectedFarm, setValue ]);


	const onSubmit = handleSubmit(async (data) => {
		console.log("update data");
		const f: IFarm = {
			id: selectedFarm?.id || 0,
			companyId: selectedFarm?.companyId || 0,
			name: data.name,
			farmAddress: data.address,
			latitude: data.latitude,
			longitude: data.longitude,
			totalAcres: data.acres || 1,
			numberOfFields: data.fields || 1,
		};

		const updatedFarm = await updateFarm(f);
		console.log(updatedFarm);
		onSave();
	});

	return (
		<Box {...rest}>
			<Card p="1em" pt="0">
				<CardBody>
					<form onSubmit={onSubmit}>
						<FormControl mt="10" isRequired>
							<Input
								id="name"
								size="md"
								type="text"
								placeholder=" "
								data-peer
								{ ...register("name")}
							/>
							<FormLabel htmlFor="name" variant="floating" size="md">
								{t("farmEdit.labelName")}
							</FormLabel>
						</FormControl>
						<FormControl mt="10">
							<Input
								id="address"
								size="md"
								type="text"
								placeholder=" "
								data-peer
								{ ...register("address")}
							/>
							<FormLabel htmlFor="address" variant="floating" size="md">
								{t("farmEdit.labelAddress")}
							</FormLabel>
						</FormControl>
						<Flex flexDirection="row">
							<FormControl mt="10" mr="0.5em">
								<Input
									id="latitude"
									size="md"
									type="number"
									placeholder=" "
									data-peer
									{ ...register("latitude")}
								/>
								<FormLabel htmlFor="latitude" variant="floating" size="md">
									{t("farmEdit.labelLatitude")}
								</FormLabel>
							</FormControl>
							<FormControl mt="10" ml="0.5em">
								<Input
									id="longitude"
									size="md"
									type="number"
									placeholder=" "
									data-peer
									{ ...register("longitude")}
								/>
								<FormLabel htmlFor="longitude" variant="floating" size="md">
									{t("farmEdit.labelLongitude")}
								</FormLabel>
							</FormControl>
						</Flex>
						<Flex flexDirection="row">
							<FormControl mt="10" mr="0.5em">
								<Input
									id="acres"
									size="md"
									placeholder=" "
									data-peer
									type="number"
									{...register("acres")}
								/>
								<FormLabel htmlFor="acres" variant="floating" size="md">
									{t("farmEdit.labelAcres")}
								</FormLabel>
							</FormControl>
							<FormControl mt="10" ml="0.5em">
								<Input
									id="fields"
									size="md"
									placeholder=" "
									data-peer
									type="number"
									{...register("fields")}
								/>
								<FormLabel htmlFor="fields" variant="floating" size="md">
									{t("farmEdit.labelFields")}
								</FormLabel>
							</FormControl>
						</Flex>
						<Flex mt="2em">
							<Spacer />
							<ButtonGroup>
								<Button type="submit" w="150px" h="50px" variant="solid" colorScheme="blue">
									{t("farmEdit.saveButton")}
								</Button>
							</ButtonGroup>
							<Spacer />
						</Flex>
					</form>
				</CardBody>
			</Card>
		</Box>
	);
};
