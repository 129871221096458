import { useTranslation } from "react-i18next";

import { CallToAction } from "../components/marketing-layout/CallToAction";
import { WeedPredictionDetails } from "../components/marketing-layout/WeedPredictionDetails"
import { MarketingLayout } from "../components/marketing-layout";

export const MarketingWeedPrediction = () => {
	const { t } = useTranslation();

	return (
		<MarketingLayout>
			<>
				<CallToAction
					headingTitle={t("weedPredict.ctaTitle")}
					headingDescription={t("weedPredict.ctaDescription")}
					headingDescription2={t("weedPredict.ctaDescription2") || undefined}
					leftButtonLink="/get-started"
					leftButtonText={t("weedPredict.ctaLeftText") || "Get Started"}
					rightButtonLink="/contact-us"
					rightButtonText={t("weedPredict.ctaRightText") || "Contact Us"}
					ctaImageAlt={t("weedPredict.ctaImageAlt") || ""}
					ctaImageUrl="weed-prediction-cta.png"
				/>
				<WeedPredictionDetails />
			</>
		</MarketingLayout>
	);
};
