import {
	Box,
	Button,
	Container,
	Heading,
	Img,
	Link,
	Stack,
	Text,
} from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProps {
	headingTitle: string;
	headingDescription: string;
	headingDescription2?: string;
	leftButtonLink?: string;
	leftButtonText?: string;
	rightButtonLink?: string;
	rightButtonText?: string;
	ctaImageUrl?: string;
	ctaImageAlt?: string;
}

export const CallToAction = ({
	headingTitle,
	headingDescription,
	headingDescription2,
	leftButtonLink,
	leftButtonText,
	rightButtonLink,
	rightButtonText,
	ctaImageUrl,
	ctaImageAlt,
}: IProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Box position="relative" height={{ lg: "400px" }}>
			<Container py={{ base: "16", md: "24" }} height="full">
				<Stack
					direction={{ base: "column", lg: "row" }}
					spacing={{ base: "16" }}
					align={{ lg: "center" }}
					height="full"
				>
					<Stack spacing={{ base: "8", md: "12" }}>
						<Stack spacing="4">
							<Stack
								spacing={{ base: "4", md: "6" }}
								maxW={{ md: "xl", lg: "md", xl: "xl" }}
							>
								<Heading size={{ base: "md", md: "xl" }}>
									{headingTitle}
								</Heading>
								<Text fontSize={{ base: "lg", md: "xl" }} color="fg.muted">
									{headingDescription}
								</Text>
								{
									headingDescription2 && 
									<Text fontSize={{ base: "lg", md: "xl" }} color="fg.muted">
										{headingDescription2}
									</Text>
								}
							</Stack>
						</Stack>

						<Stack direction={{ base: "column", md: "row" }} spacing="3">
							<Link as={ReactLink} to={leftButtonLink || "/contact-us"}>
								<Button variant="primary" size={{ base: "lg", md: "xl" }}>
									{leftButtonText || t("landing.contactUsLink")}
								</Button>
							</Link>
							<Link as={ReactLink} to={rightButtonLink || "/about-us"}>
								<Button variant="secondary" size={{ base: "lg", md: "xl" }}>
									{rightButtonText || t("landing.aboutUsLink")}
								</Button>
							</Link>
						</Stack>
					</Stack>

					<Box
						pos={{ lg: "absolute" }}
						right="0"
						bottom="0"
						w={{ base: "full", lg: "50%" }}
						height={{ base: "96", lg: "full" }}
						sx={{
							clipPath: { lg: "polygon(7% 0%, 100% 0%, 100% 100%, 0% 100%)" },
						}}
					>
						<Img
							boxSize="full"
							objectFit="cover"
							src={ctaImageUrl || "get-started-cta.png"}
							alt={ctaImageAlt || t("landing.getStartedLink") || "Get started"}
						/>
					</Box>
				</Stack>
			</Container>
		</Box>
	);
};
