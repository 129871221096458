import React, { useEffect } from "react";
import {
	Container,
	Flex,
	useBreakpointValue,
	useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


import { Navbar } from "./Navbar";
import { Sidebar } from "./Sidebar";
import { useAuthorizationDispatch, useAuthorizationState, setAuth, clearAuth } from "../../contexts/AuthorizationContext";
import { useApi } from "../../services";
import { Config } from "../../config/Config";
import { useGlobalDispatch, globalSetError, globalClearError, useGlobalState, globalSetInfo } from "../../contexts/GlobalContext";


interface IProps {
	children: JSX.Element;
}

export const Layout = ({ children }: IProps) => {
	const { user, company, permissions, isCompanyAdmin, isGecoAdmin } = useAuthorizationState();
	const authorizationDispatch = useAuthorizationDispatch();
	const { getUserData } = useApi(Config.baseUrl);
	const globalDispatch = useGlobalDispatch();
	const { isErrorSet, errorMessage, errorTitle, duration, isInfo } = useGlobalState();
	const { t } = useTranslation();
	const toast = useToast();
	const navigate = useNavigate();


	// on initial load of this component, lets get the
	// user data from the db and store it in our context
	useEffect(() => {
		const getAuthInfo = async () => {
			try {
				const data = await getUserData();
				if (data?.user && data?.company) {
					authorizationDispatch(setAuth(data?.user, data?.company, data.farmPermissions, data.isCompanyAdministrator, data.isGecoAdministrator));
					globalDispatch(globalClearError());
				} else {
					globalDispatch(globalSetInfo(t("companySetup.notSetupYet"), t("companySetup.setup"), 10000));
				}
			} catch (error) {
				console.error(error);
				globalDispatch(globalSetError(t("auth.titleNoUserData"), t("auth.messageNoUserData")));
				authorizationDispatch(clearAuth());
			}
		};

		if (!user || !company) {
			getAuthInfo();
		}
	}, [ ]);

	useEffect(() => {
		if (isErrorSet) {
			toast({
				title: errorTitle,
				description: errorMessage,
				status: isInfo ? "info" : "error",
				duration,
				isClosable: true,
				position: "top",
				onCloseComplete() {
					globalDispatch(globalClearError());
				},
			});
		}
	}, [ isErrorSet, toast, errorTitle, errorMessage, duration, globalDispatch, isInfo ]);


	const isDesktop = useBreakpointValue({ base: false, lg: true });
	return (
		<Flex
			as="section"
			direction={{ base: "column", lg: "row" }}
			height="100vh"
			bg="bg-canvas"
			overflowY="auto"
		>
			{isDesktop ? <Sidebar /> : <Navbar />}

			<Container py="8" flex="1" margin="0" maxWidth="100vw">
				{ children }
			</Container>
		</Flex>
	);
};
