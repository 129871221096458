import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Button,
	Container,
	FormControl,
	FormLabel,
	Grid,
	GridItem,
	HStack,
	Input,
	Progress,
	Stack,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react";


import { ICompany, IField, IFieldYearData, isIProblemDetails } from "../models";
import { useForm } from "react-hook-form";
import { useAuthorizationState } from "../contexts/AuthorizationContext";
import { useApi } from "../services";
import { Config } from "../config/Config";
import { useGlobalDispatch, globalSetError } from "../contexts/GlobalContext";




type FormValues = {
	crop: string;
	year: number;
	fieldId: number;
	plantDate: Date | null | undefined | string;
	harvestDate: Date | null | undefined | string;
	userId: number;
}

interface IProps {
	field: IField | null | undefined;
	history: IFieldYearData[];
}




export const FieldDetailsHistory = ({ field, history }: IProps): JSX.Element => {
	const { t } = useTranslation();
	const { user } = useAuthorizationState();
	const [ inAddMode, setInAddMode ] = useState(true);
	const { isLoading, updateFieldYearData, insertFieldYearData, readFieldYearData } = useApi(Config.baseUrl);
	const [ years, setYears ] = useState<IFieldYearData[]>([]);
	const globalDispatch = useGlobalDispatch();

	const defaultValues: FormValues = {
		crop: "",
		year: new Date().getFullYear(),
		fieldId: field?.id || 0,
		plantDate: null,
		harvestDate: null,
		userId: user?.id || 0
	};
	const { register, handleSubmit, setValue } = useForm<FormValues>({ defaultValues });


	useEffect(() => {
		setYears([ ...history.slice(0, 5)]);
	}, [ history ]);

	const onSubmit = handleSubmit(async (data) => {
		console.log(data);
		const year: IFieldYearData = {
			fieldId: data.fieldId,
			year: data.year,
			dateRecordedUtc: new Date(Date.now()),
			recordedBy: data.userId,
			crop: data.crop,
			plantDate: new Date(data.plantDate || new Date(Date.now())),
			harvestDate: data.harvestDate ? new Date(data.harvestDate) : undefined,
		};
		if (inAddMode) {
			const addResult = await insertFieldYearData(year);
			if (isIProblemDetails(addResult)) {
				globalDispatch(globalSetError(t("field.errorAddingHistory"), t("field.errorTitle")));
			}
		} else {
			const updateResult = await updateFieldYearData(year);
			if (isIProblemDetails(updateResult)) {
				globalDispatch(globalSetError(t("field.errorUpdatingHistory"), t("field.errorTitle")));
			}
		}

		const updates = await readFieldYearData(field?.id || 0);
		if (!isIProblemDetails(updates)) {
			setYears([ ...updates ]);
		}
	});

	const handleEdit = (y: IFieldYearData): void => {
		const pd = new Date(y.plantDate).toISOString().split("T")[0];
		console.log(pd);
		const hd = y.harvestDate ? new Date(y.harvestDate).toISOString().split("T")[0] : "";
		setValue("crop", y.crop);
		setValue("year", y.year);
		setValue("fieldId", y.fieldId);
		setValue("plantDate", pd);
		setValue("harvestDate", new Date(hd));
		setValue("userId", user?.id || 0);
		setInAddMode(false);
	};

	const handleClear = (): void => {
		setValue("crop", "");
		setValue("year", new Date().getFullYear());
		setValue("plantDate", null);
		setValue("harvestDate", null);
		setInAddMode(true);
	};


	return (
		<Grid
			templateAreas={`"display edits"`}
			templateRows={"1fr"}
			templateColumns={"1fr 1fr"}
			gap="1em"
		>
			<GridItem area={"display"}>
				<TableContainer>
					<Table size="sm" variant="striped" w="100%">
						<Thead>
							<Tr>
								<Th>{t("field.headerYear")}</Th>
								<Th>{t("field.headerCrop")}</Th>
								<Th>{t("field.headerPlantDate")}</Th>
								<Th>{t("field.headerHarvestDate")}</Th>
								<Th>&nbsp;</Th>
							</Tr>
						</Thead>
						<Tbody>
							{
								years.map((y, i) => (
									<Tr key={`year-${i}`}>
										<Td>{y.year}</Td>
										<Td>{y.crop}</Td>
										<Td>{y.plantDate.toString()}</Td>
										<Td>{y?.harvestDate?.toString() || ""}</Td>
										<Td>
											<Button variant="link" onClick={() => handleEdit(y)}>
												{t("field.buttonEdit")}
											</Button>
										</Td>
									</Tr>
								))
							}
						</Tbody>
					</Table>
				</TableContainer>
			</GridItem>
			<GridItem area={"edits"}>
				<Container>
					<Stack>
						<form onSubmit={onSubmit}>
							<input type="hidden" {...register("fieldId")} />
							<input type="hidden" {...register("userId")} />
							<FormControl>
								<FormLabel htmlFor="year" size="md">
									{t("field.labelYear")}
								</FormLabel>
								<Input
									id="year"
									size="md"
									type="text"
									data-peer
									{ ...register("year")}
								/>
							</FormControl>
							<FormControl mt="1em">
								<FormLabel htmlFor="crop" size="md">
									{t("field.labelCrop")}
								</FormLabel>
								<Input
									id="crop"
									size="md"
									type="text"
									data-peer
									{...register("crop")}
								/>
							</FormControl>
							<FormControl mt="1em">
								<FormLabel htmlFor="plantDate" size="md">
									{t("field.labelPlantDate")}
								</FormLabel>
								<Input
									id="plantDate"
									size="md"
									type="date"
									data-peer
									{...register("plantDate")}
								/>
							</FormControl>
							<FormControl mt="1em">
								<FormLabel htmlFor="harvestDate" size="md">
									{t("field.labelHarvestDate")}
								</FormLabel>
								<Input
									id="harvestDate"
									size="md"
									type="date"
									data-peer
									{...register("harvestDate")}
								/>
							</FormControl>
							<HStack mt="2em">
								{
									!isLoading && inAddMode &&
									<Button variant="primary" colorScheme="blue" type="submit">
										{t("field.buttonAddYear")}
									</Button>
								}
								{
									!isLoading && !inAddMode &&
									<Button variant="primary" colorScheme="blue" type="submit">
										{t("field.buttonEditYear")}
									</Button>
								}
								{
									!isLoading && 
									<Button variant="secondary" colorScheme="blue" onClick={handleClear}>
										{t("field.buttonClearYear")}
									</Button>
								}
								{
									isLoading && <Progress isIndeterminate size="lg" hasStripe colorScheme="blue" />
								}
							</HStack>
						</form>
					</Stack>
				</Container>
			</GridItem>
		</Grid>
	);
};
