import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons/lib";
import { useNavigate } from "react-router-dom";
import { Flex, Stack } from "@chakra-ui/react";
import { MdAndroid, MdContactPage, MdHome, MdInfo, MdLogin, MdMedication, MdStart } from "react-icons/md";
import { useMsal } from "@azure/msal-react";

import { NavButton } from "../layout/NavButton";
import { Config } from "../../config/Config";
import { loginRequest } from "../../config/msalConfig";


export const Sidebar = (): JSX.Element => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { instance } = useMsal();
	const [ currentPage, setCurrentPage ] = useState("/");

	useEffect(() => {
		const pathName = window.location.pathname;
		const segments = pathName.split("/");
		const lastSegment = `/${segments[segments.length - 1]}`;
		if (lastSegment !== currentPage) { setCurrentPage(lastSegment); }
	}, [ currentPage ]);


	const renderNavButton = (text: string, icon: IconType, path: string): JSX.Element => (
		<NavButton
			label={text}
			icon={icon}
			onClick={() => navigate(path)}
			aria-current={currentPage === path ? "page" : false}
		/>
	);

	const handleLogin = (): void => {
		instance.loginRedirect(loginRequest).catch((error) => console.log(error));
	};

	return (
		<Flex as="section" minH="100vh" bg="bg-canvas">
			<Flex
				flex="1"
				bg="bg-surface"
				overflow="auto"
				boxShadow="sm"
				maxW={{ base: "full", sm: "xs" }}
				py={{ base: "6", sm: "8" }}
				px={{ base: "4", sm: "6" }}
			>
				<Stack justify="space-between" spacing="1">
					<Stack spacing={{ base: "5", sm: "6" }} shouldWrapChildren>
						<img src="gecologo.png" width="100%" alt={t("logo") || ""} />
						<Stack spacing="1" marginTop="2em">
							{renderNavButton(t("landing.homeLink"), MdHome, "/")}
							{renderNavButton(t("landing.getStartedLink"), MdStart, "/get-started")}
							{renderNavButton(t("landing.weedLink"), MdAndroid, "/weed-prediction")}
							{renderNavButton(t("landing.herbicideLink"), MdMedication, "/herbicide-resistance")}
							{renderNavButton(t("landing.contactUsLink"), MdContactPage, "/contact-us")}
							{renderNavButton(t("landing.aboutUsLink"), MdInfo, "/about-us")}
							{
								Config.enableLogin &&
								<NavButton label={t("sidebar.login")} icon={MdLogin} onClick={handleLogin} />
							}
						</Stack>
					</Stack>
				</Stack>
			</Flex>

		</Flex>
	);
};
