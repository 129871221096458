import React from "react";
import {  CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";


const container = {
	display: "flex",
	"justifyContent": "center", /* Horizontally center the children */
	"alignItems": "center", /* Vertically center the children */
	height: "100vh"
};
  

interface IProps {
	label?: string;
	labelSize?: string;
	thickness?: string;
	size?: string;
	fullScreen?: boolean;
}

export const Loading = ({ label, labelSize, thickness, size, fullScreen = false }: IProps): JSX.Element => {
	const { t } = useTranslation();

	const text = label || t("loading");
	const textSize = labelSize || "xl";
	const circThickness = thickness || "8px";
	const circSize = size || "200px";
	const styles = fullScreen ? container : { };

	return (
		<div style={styles}>
			<CircularProgress isIndeterminate size={circSize} thickness={circThickness}>
				<CircularProgressLabel fontSize={textSize}>
					{text}
				</CircularProgressLabel>
			</CircularProgress>
		</div>
	);
};
