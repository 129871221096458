import { ReactNode, useRef, useState, useEffect } from "react";
import {
	Box,
	Button,
	Card,
	Checkbox,
	Container,
	FormControl,
	FormLabel,
	Heading,
	Input,
	InputGroup,
	Progress,
	Select,
	Stack,
} from "@chakra-ui/react";
import { useForm, UseFormRegisterReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";


import { Layout } from "../components/layout/Index";
import { TitleAndNav } from "../components/TitleAndNav";
import { useAuthorizationState } from "../contexts/AuthorizationContext";
import { useApi } from "../services";
import { Config } from "../config/Config";
import { IField, isIAllFieldData, isIProblemDetails } from "../models";
import { DropzonePreview, IFileListExt } from "../components/dropZone/DropzonePreview";
import { IDropzonePreviewItem } from "../components/dropZone/DropZonePreviewItem";
import { globalSetError, useGlobalDispatch } from "../contexts/GlobalContext";


export type FormValues = {
	file: FileList;
	customerDueDate: Date | null | undefined;
	gecoDueDate: Date | null | undefined;
	isGecoSupplied: boolean;
	isGecoPrivate: boolean;
	userId: number;
	companyId: number;
	farmId: number;
	fieldId: number;
	description: string;
	folder: string;
};


export const UploadFieldFilesPage = (): JSX.Element => {
	const { t } = useTranslation();
	const { isGecoAdmin } = useAuthorizationState();
	const { isLoading, loadFieldData, uploadFiles } = useApi(Config.baseUrl);
	const [ field, setField ] = useState<IField | undefined | null>(undefined);
	const [ dzFiles, setDzFiles ] = useState<IFileListExt[]>([]);
	const [ files, setFiles ] = useState<IDropzonePreviewItem[]>([]);
	const globalDispatch = useGlobalDispatch();

	const url = new URLSearchParams(window.location.search);
	const formDefaults = {
		isGecoSupplied: isGecoAdmin,
		isGecoPrivate: url.get("isGecoPrivate") === "true",
		userId: parseInt(url.get("user") || "0"),
		companyId: parseInt(url.get("company") || "0"),
		farmId: parseInt(url.get("farm") || "0"),
		fieldId: parseInt(url.get("field") || "0"),
		folder: "root",
	};
	const { register, handleSubmit } = useForm<FormValues>({ defaultValues: formDefaults });
	console.log(formDefaults);

	useEffect(() => {
		const loadData = async (id: number) => {
			const fieldRecord = await loadFieldData(id);
			if (isIAllFieldData(fieldRecord)) {
				setField(fieldRecord.field);
			}
		};

		const query = new URLSearchParams(window.location.search);
		const fieldIdStr = query.get("field");
		if (fieldIdStr) {
			const fieldId = parseInt(fieldIdStr);
			loadData(fieldId);
		}
	}, []);

	const updateFiles = (files: IFileListExt[]): void => {
		const newFilesList: IFileListExt[] = [
			...dzFiles,
			...files,
		];
		setDzFiles(newFilesList);
	};

	const deleteFile = (id: string): void => {
		const newFilesList = dzFiles.filter(f => f.id !== id);
		setDzFiles(newFilesList);
	};

	const onSubmit = handleSubmit(async (data) => {
		const gecoDueDateStr = data.gecoDueDate ? new Date(data.gecoDueDate).toISOString() : "";
		const custDueDateStr = data.customerDueDate ? new Date(data.customerDueDate).toISOString() : "";

		const formData = new FormData();
		formData.append("customerDueDate", custDueDateStr);
		formData.append("gecoDueDate", gecoDueDateStr);
		formData.append("isGecoSupplied", data.isGecoSupplied ? "true" : "false");
		formData.append("isGecoPrivate", data.isGecoPrivate ? "true" : "false");
		formData.append("userId", data.userId.toString());
		formData.append("companyId", data.companyId.toString());
		formData.append("farmId", data.farmId.toString());
		formData.append("fieldId", data.fieldId.toString());
		formData.append("description", data.description);
		formData.append("fileCount", files.length.toString());
		formData.append("folder", data.folder || "root");

		files.forEach((f, i) => {
			const cf = f.file;
			formData.append(`file${i}`, cf, f.filename);
		});

		formData.forEach(i => console.log(`${i}`));

		const result = await uploadFiles(formData);
		if (!isIProblemDetails(result)) {
			setFiles([]);
			return;
		}

		globalDispatch(globalSetError(t("upload.errorUploadingFiles"), t("upload.errorTitle")));
	});


	return (
		<Layout>
			<Container>
				<Stack spacing="10" mt="10">
					<TitleAndNav to={`/field?id=${field?.id}`} title={t("upload.title")} />
					{/* <Heading size="sm">{t("upload.title")}</Heading> */}
					<Card>
						<Heading size="xs" p="4">{t("upload.fieldCardTitle")}</Heading>
						<Input
							id="fieldName"
							size="md"
							placeholder=" "
							data-peer
							isReadOnly
							value={field?.name || ""}
							m="4"
							w="90%"
						/>
						<Input
							id="fieldDescription"
							size="md"
							placeholder=" "
							data-peer
							isReadOnly
							value={field?.description || ""}
							m="4"
							w="90%"
						/>
					</Card>

					<form onSubmit={onSubmit}>
						<FormControl mt="10">
							<Input
								id="description"
								size="md"
								type="text"
								data-peer
								placeholder=" "
								{ ...register("description")}
							/>
							<FormLabel htmlFor="description" variant="floating" size="md">
								{t("upload.description")}
							</FormLabel>
						</FormControl>


						<FormControl mt="10">
							<FormLabel htmlFor="folderSelect" variant="floating" size="lg">
								{t("upload.uploadFolder")}
							</FormLabel>
							<Select id="folderSelect" size="md" { ...register("folder")}>
								<option value="root">{t("fieldFolder.root")}</option>
								<option value="code">{t("fieldFolder.code")}</option>
								<option value="imagery">{t("fieldFolder.imagery")}</option>
								<option value="miscellaneous">{t("fieldFolder.miscellaneous")}</option>
								<option value="outputs">{t("fieldFolder.outputs")}</option>
								<option value="reports">{t("fieldFolder.reports")}</option>
								<option value="shapeFiles">{t("fieldFolder.shapeFiles")}</option>
								<option value="sprayMaps">{t("fieldFolder.sprayMaps")}</option>
							</Select>
						</FormControl>

						<FormControl mt="10">
							<Input
								id="customerDueDate"
								size="md"
								type="datetime-local"
								data-peer
								{ ...register("customerDueDate")}
							/>
							<FormLabel htmlFor="customerDueDate" variant="floating" size="md">
								{t("upload.custDueDate")}
							</FormLabel>
						</FormControl>

						{
							isGecoAdmin &&
							<FormControl mt="10">
								<Input
									id="gecoDueDate"
									size="md"
									type="datetime-local"
									data-peer
									{ ...register("gecoDueDate")}
								/>
								<FormLabel htmlFor="gecoDueDate" variant="floating" size="md">
									{t("upload.gecoDueDate")}
								</FormLabel>
							</FormControl>
						}
						{
							isGecoAdmin &&
							<FormControl mt="10">
								<Checkbox size="lg" colorScheme="blue" {...register("isGecoSupplied")}>
									{t("upload.gecoSupplied")}
								</Checkbox>
							</FormControl>
						}
						{
							isGecoAdmin &&
							<FormControl mt="10">
								<Checkbox size="lg" colorScheme="red" {...register("isGecoPrivate")}>
									{t("upload.keepPrivate")}
								</Checkbox>
							</FormControl>
						}

						<input type="hidden" {...register("userId")} />
						<input type="hidden" {...register("companyId")} />
						<input type="hidden" {...register("farmId")} />
						<input type="hidden" {...register("fieldId")} />

						<Box mb="30px">
							<DropzonePreview
								mt="1em"
								files={files}
								setFiles={setFiles}
								updateFiles={updateFiles}
								deleteFile={deleteFile}
							/>
						</Box>
						
						{ !isLoading && <Button mt="4" colorScheme="blue" type="submit">{t("upload.uploadButton")}</Button> }
						{ isLoading && <Progress w="100%" mt="2em" isIndeterminate colorScheme="blue" hasStripe /> }

					</form>
				</Stack>
				<Box p="2em" />
			</Container>
		</Layout>
	);
};
