import { useTranslation } from "react-i18next";

import { HerbicideResistanceDetails } from "../components/marketing-layout/HerbicideResistanceDetails"
import { CallToAction } from "../components/marketing-layout/CallToAction";
import { MarketingLayout } from "../components/marketing-layout";

export const MarketingHerbicideResistance = () => {
	const { t } = useTranslation();
		
	return (
		<MarketingLayout>
			<>
				<div style={{ marginTop: "1em" }} />
				<CallToAction
					headingTitle={t("herbResist.ctaTitle")}
					headingDescription={t("herbResist.ctaDescription1")}
					headingDescription2={t("herbResist.ctaDescription2") || undefined}
					leftButtonLink="/get-started"
					leftButtonText={t("herbResist.ctaLeftButton") || "Contact Us"}
					rightButtonLink="/contact-us"
					rightButtonText={t("herbResist.ctaRightButton") || "About Us"}
					ctaImageUrl="herbicide-resistance-cta.png"
					ctaImageAlt={t("herbResist.ctaImageAlt") || "Get Started Image"}
				/>
				<HerbicideResistanceDetails />
			</>
		</MarketingLayout>
	);
};
