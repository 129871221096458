import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Divider,
	Flex,
	HStack,
	Icon,
	Input,
	InputGroup,
	InputLeftElement,
	Progress,
	Stack,
	Text,
} from "@chakra-ui/react";
import {
	FiBarChart2,
	FiBookmark,
	FiCheckSquare,
	FiHelpCircle,
	FiHome,
	FiLogOut,
	FiSearch,
	FiSettings,
	FiUsers,
} from "react-icons/fi";
import { TbSpiral } from "react-icons/tb";
import { MdAdminPanelSettings, MdCalendarViewMonth, MdInfoOutline, MdOutlineGroup } from "react-icons/md";
import { useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType, PopupRequest } from "@azure/msal-browser";
import { AccountInfo } from "@azure/msal-browser";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuthorizationState } from "../../contexts/AuthorizationContext";

import { NavButton } from "./NavButton";
import { UserProfile } from "./UserProfile";
import { useApi } from "../../services";
import { Config } from "../../config/Config";
import { IconType } from "react-icons/lib";

export const Sidebar = (): JSX.Element => {
	const { t } = useTranslation();
	const api = useApi(Config.baseUrl);
	const { isGecoAdmin, company } = useAuthorizationState();
	const navigate = useNavigate();
	const [ currentPage, setCurrentPage ] = useState("/");

	const { instance } = useMsal();

	useEffect(() => {
		const pathName = window.location.pathname;
		console.log(pathName);
		const segments = pathName.split("/");
		console.log(segments);
		const lastSegment = `/${segments[segments.length - 1]}`;
		console.log(lastSegment);
		console.log(currentPage);
		if (lastSegment !== currentPage) { setCurrentPage(lastSegment); }
	}, [ currentPage ]);





	let account: AccountInfo | null | undefined;
	if (instance) {
		account = instance.getActiveAccount();
	}

	const handleLogout = (): void => {
		instance?.logoutRedirect();
	};


	const getTokenAsync = async (): Promise<void> => {
		console.log(account);

		try {
			const r = await instance.acquireTokenSilent({
				account: account || undefined,
				scopes: [ "https://gecodata.onmicrosoft.com/api/manage.farm", "openid", "offline_access" ],
			});
			console.log(r);
		} catch (error) {
			const r = await instance.acquireTokenPopup({
				account: account || undefined,
				scopes: [ "https://gecodata.onmicrosoft.com/api/manage.farm", "openid", "offline_access" ],
			});
			console.log("silent failed");
			console.log(r);
		}
	};

	const handleButtonClick = async () => {
		getTokenAsync();
		const data = await api.getUserData();
		console.log(data);
	};

	const handleNavigate = (path: string): void => {
		//setCurrentPage(path);
		navigate(path);
	};

	const renderNavButton = (text: string, icon: IconType, path: string): JSX.Element => {
		return (
			<NavButton
				label={text}
				icon={icon}
				onClick={() => handleNavigate(path)}
				aria-current={currentPage === path ? "page" : false}
			/>
		);
	};

	return (
		<Flex as="section" minH="100vh" bg="bg-canvas">
			<Flex
				flex="1"
				bg="bg-surface"
				overflowY="auto"
				boxShadow="sm"
				maxW={{ base: "full", sm: "xs" }}
				py={{ base: "6", sm: "8" }}
				px={{ base: "4", sm: "6" }}
			>
				<Stack justify="space-between" spacing="1">
					<Stack spacing={{ base: "5", sm: "6" }} shouldWrapChildren>
						<img src="gecologo.png" width="100%" alt={t("logo") || ""} />
						<Stack spacing="1" marginTop="2em">
							{ isGecoAdmin && renderNavButton(t("sidebar.admin"), MdAdminPanelSettings, "/admin") }
							{ renderNavButton(t("sidebar.customerData"), MdCalendarViewMonth, "/") }
							{ renderNavButton(t("sidebar.farms"), MdOutlineGroup, "/farms") }
							{ renderNavButton(t("sidebar.fields"), MdCalendarViewMonth, "/fields") }
							{ renderNavButton(t("sidebar.users"), MdOutlineGroup, `/users?companyId=${company?.id}`) }
							<NavButton label={t("sidebar.logout")} icon={FiLogOut} onClick={handleLogout} />
						</Stack>
					</Stack>
					<Stack spacing={{ base: "5", sm: "6" }}>
						<Stack spacing="1">
							{/* <NavButton label="Help" icon={FiHelpCircle} />
							<NavButton label="Settings" icon={FiSettings} /> */}
						</Stack>
						<Divider />
						<UserProfile
							name={account && account.name ? account.name : "Unknown"}
							email={account && account.username ? account.username : "Unknown"}
						/>
					</Stack>
				</Stack>
			</Flex>
		</Flex>
	);
};
