export interface IGlobalState {
	errorMessage: string;
	errorTitle: string;
	isErrorSet: boolean;
	isInfo: boolean;
	duration: number | null;
	adminAddedCompany: boolean;
}


export const initialGlobalState: IGlobalState = {
	isErrorSet: false,
	isInfo: false,
	errorMessage: "sample message",
	errorTitle: "sample title",
	duration: 5000,
	adminAddedCompany: false,
};
