import {
    Container,
    SimpleGrid,
    Card,
    CardHeader,
    Heading,
    Image,
    CardBody,
    CardFooter,
    Link,
    Text,
    Button,
} from "@chakra-ui/react"
import { Link as ReactLink } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { jsx } from "@emotion/react";
import { ImageContainer } from "../ImageContainer";
import { MdWidthFull } from "react-icons/md";
import { NavLink } from "react-router-dom";

interface IProductCardProps {
	heading: string;
	about: string;
	imgUrl: string;
	imgAlt: string;
	navUrl: string;
	buttonText?: string;
	width?: string;
	height?: string;
}

export const ProductCard = ({heading, about, imgUrl, imgAlt, navUrl, buttonText, width, height }: IProductCardProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Card>
			<CardHeader>
				<NavLink to={navUrl}>
					<Heading size='sm' textAlign='center'>{heading}</Heading>
				</NavLink>
			</CardHeader>
			<CardBody>
				<ImageContainer
					width={width}
					height={height}
					url={imgUrl}
					alt={imgAlt}
					center
				/>
				<Text mt='1em'>{about}</Text>
			</CardBody>
			<CardFooter>
				<Link as={ReactLink} to={navUrl}>
					<Button variant='secondary'>{buttonText || t('landing.learnMore')}</Button>
				</Link>
			</CardFooter>
		</Card>
	);
};

export const ProductCardWeedPrediction = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<ProductCard
			heading={t('landing.weedProtectionTitle')}
			about={t('landing.weedProtectionAbout')}
			imgUrl='weed-prediction-screenshot.png'
			imgAlt={t('landing.weedProtectionAlt')}
			navUrl='/weed-prediction'
		/>
	);
};


export const ProductCardHerbResistance = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<ProductCard
			heading={t('landing.herbResistTitle')}
			about={t('landing.herbResistAbout')}
			imgUrl='herbicide-resistance-screenshot.png'
			imgAlt={t('landing.herbResistAlt')}
			navUrl='/herbicide-resistance'
		/>
	);
};



export const Products = () => {
    const { t } = useTranslation();

    return (
        <Container py={{ base: '16', md: '2' }}>
            <SimpleGrid columns={2} spacing={10} mt="2em">
				<ProductCardWeedPrediction />
				<ProductCardHerbResistance />
            </SimpleGrid>
        </Container>
    );
}