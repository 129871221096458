import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Heading, Progress } from "@chakra-ui/react";

import { Layout } from "../components/layout/Index";
import { FarmList } from "../components/FarmsList";
import { useApi } from "../services";
import { Config } from "../config/Config";
import { isIUserTenantData } from "../models";
import { useTenantDispatch, setTenantAuthData } from "../contexts/TenantContext";

export const FarmsPage = (): JSX.Element => {
	const { t } = useTranslation();
	const { isLoading, loadTenant } = useApi(Config.baseUrl);
	const tenantDispatch = useTenantDispatch();

	useEffect(() => {
		const load = async () => {
			const data = await loadTenant();
			if (isIUserTenantData(data)) {
				tenantDispatch(setTenantAuthData(data.farms, data.authInfo));
			}
		}

		load();
	}, [ ]);



	return (
		<Layout>
			<>
				<Heading size="sm">{t("farms.title")}</Heading>

				<Box mt="1em">
					{ !isLoading && <FarmList /> }
					{ isLoading && <Progress isIndeterminate hasStripe size="lg" colorScheme="blue" />}
				</Box>
			</>
		</Layout>
	);
};
