import { useTranslation } from "react-i18next";

import { CallToAction } from "../components/marketing-layout/CallToAction";
import { ContactUsDetails } from "../components/marketing-layout/ContactUsDetails"
import { MarketingLayout } from "../components/marketing-layout";


export const MarketingContactUs = () => {
	const { t } = useTranslation();

	return (
        <MarketingLayout>
			<>
				<CallToAction
					headingTitle={t("contactUs.ctaTitle")}
					headingDescription={t("contactUs.ctaDescription")}
					leftButtonLink="/get-started"
					leftButtonText={t("contactUs.ctaLeftButton") || "Contact Us"}
					rightButtonLink="/about-us"
					rightButtonText={t("contactUs.ctaRightButton") || "About Us"}
					ctaImageUrl="contact-us.png"
					ctaImageAlt={t("contactUs.ctaImageAlt") || "Get Started Image"}
				/>
				<ContactUsDetails />
			</>
        </MarketingLayout>
	);
};
