import React, { useEffect, useState } from "react";
import { Box, Button, Progress, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";


import { IUser, IUserFarmPermission, isIProblemDetails } from "../models";
import { setNeedUpdate, setUserPermissions, useAdminUserDispatch, useAdminUserState } from "../contexts/AdminUserContext";
import { globalSetError, useGlobalDispatch } from "../contexts/GlobalContext";
import { useApi } from "../services";
import { Config } from "../config/Config";
import { useAuthorizationState } from "../contexts/AuthorizationContext";
import { UserListEdit } from "./UserListEdit";


function delay(ms: number) {
	return new Promise((resolve) => {
	  setTimeout(resolve, ms);
	});
}


export const UserList = (): JSX.Element => {
	const { t } = useTranslation();
	const [ selectedUser, setSelectedUser ] = useState(-1);
	const [ inEditMode, setInEditMode ] = useState(false);
	const globalDispatch = useGlobalDispatch();
	const { isLoading, userRemoveFromForm, userUpdateForFarm } = useApi(Config.baseUrl);
	const { isGecoAdmin, isCompanyAdmin, company } = useAuthorizationState();
	const adminUserDispatch = useAdminUserDispatch();
	const { userPermissions, selectedFarm } = useAdminUserState();



	useEffect(() => {
		console.log(userPermissions);
	}, [ userPermissions ]);

	const renderSeparator = (u: IUserFarmPermission): JSX.Element | null => {
		if (isLoading) { return null; }

		if (selectedUser === u.userId && inEditMode && !isLoading) {
			return null;
		}

		return (isGecoAdmin || isCompanyAdmin) ? <Box as="span" ml="0.5em" mr="0.5em">|</Box> : null;
	};


	const handleEditClick = (i: number, u: IUserFarmPermission): void => {
		console.log("edit user clicked");
		setSelectedUser(u.userId);
		setInEditMode(true);
	};

	const handleDeleteClick = async (i: number, u: IUserFarmPermission): Promise<void> => {
		setSelectedUser(u.userId);

		try {
			const result = await userRemoveFromForm(selectedFarm?.farm.id || -1, u.userId);
			if (isIProblemDetails(result)) {
				globalDispatch(globalSetError(t("userList.errorDeletingUser"), t("userList.errorTitle")));
				return;
			}
			adminUserDispatch(setNeedUpdate(true));
		} catch (e) {
			console.log(e);
		}

		setSelectedUser(-1);
	};

	const onEditSave = async (index: number, user: IUserFarmPermission, permission: string): Promise<void> => {
		const results = await userUpdateForFarm(company?.id || 0, user.farmId, user.userId, permission);
		if (isIProblemDetails(results)) {
			globalDispatch(globalSetError(t("userList.errorUpdating"), t("userList.errorTitle")));
			return;
		}

		const newUsers: IUserFarmPermission[] = [];
		userPermissions.forEach(item => {
			if (item.userId !== user.userId) {
				newUsers.push(item);
			} else {
				const newUser: IUserFarmPermission = { ...user, permission, };
				newUsers.push(newUser);
			}
		});
		adminUserDispatch(setUserPermissions(newUsers));

		setSelectedUser(-1);
		setInEditMode(false);
	};

	const editCancel = (index: number, user: IUserFarmPermission): void => {
		setSelectedUser(-1);
		setInEditMode(false);
	};

	const renderEdit = (i: number, u: IUserFarmPermission): JSX.Element | null => {
		if (!isGecoAdmin || !isCompanyAdmin) { return null; }
		if (isLoading && inEditMode) { return null; }

		if (selectedUser === u.userId && inEditMode && !isLoading) {
			return (
				<UserListEdit
					user={u}
					index={i}
					onCancel={editCancel}
					onEdit={onEditSave}
				/>
			);
		}

		return (
			<Button variant="link" onClick={() => handleEditClick(i, u)}>{t("userList.editUser")}</Button>
		);
	};

	const renderDelete = (i: number, u: IUserFarmPermission): JSX.Element | null => {
		//if (isLoading) { return null; }
		if (!isGecoAdmin || !isCompanyAdmin) { return null; }

		if (selectedUser === u.userId && inEditMode && !isLoading) {
			return null;
		}

		return selectedUser === u.userId && isLoading
			? <Progress isIndeterminate w="100px" size="sm" />
			: <Button variant="link" onClick={() => handleDeleteClick(i, u)}>{t("userList.deleteUser")}</Button>;
	};


	const renderUserRow = (i: number, u: IUserFarmPermission): JSX.Element => {
		return (
			<Tr key={u.userId.toString()}>
				<Td>
					<NavLink to={`mailto:${u.email}`}>
						{u.email}
					</NavLink>
				</Td>
				<Td>{u.firstName}</Td>
				<Td>{u.lastName}</Td>
				<Td>{t(`userList.${u.permission}`)}</Td>
				<Td>
					{ renderDelete(i, u) } { renderSeparator(u) } { renderEdit(i, u) }
				</Td>
			</Tr>
		);
	};


	if (userPermissions.length === 0) {
		return (
			<Box mt="1em">
				<Text>{t("userList.noUsersToDisplay")}</Text>
			</Box>
		);
	}


	return (
		<TableContainer>
			<Table variant="striped" size="md">
				<Thead>
					<Tr>
						<Th>{t("userList.email")}</Th>
						<Th>{t("userList.firstName")}</Th>
						<Th>{t("userList.lastName")}</Th>
						<Th>{t("userList.permission")}</Th>
						<Th>&nbsp;</Th>
					</Tr>
				</Thead>
				<Tbody>
					{
						userPermissions.map((u, i) => renderUserRow(i, u))
					}
				</Tbody>
			</Table>
		</TableContainer>
	);
};
