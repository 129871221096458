import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Heading, Progress, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";


import { AdminProvider, useAdminDispatch, useAdminState, setCompanies } from "../contexts/AdminContext";
import { useApi } from "../services";
import { Config } from "../config/Config";
import { ICompanyAdminExt } from "../models";
import { globalSetAdminAddedCompany, useGlobalDispatch, useGlobalState } from "../contexts/GlobalContext";

type SortIndex = "id" | "name" | "location" | "farms" | "fields" | "files" | "admin" | "status" | "date";
interface ISortCol {
	id: boolean;
	name: boolean;
	location: boolean;
	farms: boolean;
	fields: boolean;
	files: boolean;
	admin: boolean;
	status: boolean;
	date: boolean;
	current: SortIndex; //"id" | "name" | "location" | "farms" | "fields" | "files" | "admin" | "status" | "date";
}

const initialSort: ISortCol = {
	id: true,
	name: true,
	location: true,
	farms: true,
	fields: true,
	files: true,
	admin: true,
	status: true,
	date: true,
	current: "date",
}


function sortFieldString(s1: string, s2: string, sortAscending: boolean): number {
	console.log(`${s1} vs ${s2}`);

	let v1: string = "";
	let v2: string = "";

	if (sortAscending) {
		v1 = s1.toLocaleLowerCase();
		v2 = s2.toLocaleLowerCase();
	} else {
		v1 = s2.toLocaleLowerCase();
		v2 = s1.toLocaleLowerCase();
	}

	if (v1 < v2) { return -1; }
	if (v1 > v2) { return 1; }
	return v1.localeCompare(v2);
}

function sortFieldNumber(s1: number, s2: number, sortAscending: boolean): number {
	let v1: number = 0;
	let v2: number = 0;

	if (sortAscending) {
		v1 = s1;
		v2 = s2;
	} else {
		v1 = s2;
		v2 = s1;
	}

	if (v1 < v2) { return -1; }
	if (v1 > v2) { return 1; }
	return 0;
}

function sortFieldDate(s1: Date, s2: Date, sortAscending: boolean): number {
	let v1: number;
	let v2: number;

	if (sortAscending) {
		v1 = new Date(s1).getTime();
		v2 = new Date(s2).getTime();
	} else {
		v1 = new Date(s2).getTime();
		v2 = new Date(s1).getTime();
	}

	if (v1 < v2) { return -1; }
	if (v1 > v2) { return 1; }
	return 0;
}


const AdminCompanyListInner = (): JSX.Element => {
	const { t } = useTranslation();
	const { isLoading, adminGetAllCompanies } = useApi(Config.baseUrl);
	const { companies } = useAdminState();
	const adminDispatch = useAdminDispatch();
	const { adminAddedCompany } = useGlobalState();
	const globalDispatch = useGlobalDispatch();
	const [ firstLoad, setFirstLoad ] = useState(true);
	const [ sort, setSort ] = useState<ISortCol>(initialSort);
	

	useEffect(() => {
		const load = async () => {
			const cs = await adminGetAllCompanies("Name", false, 1, 10000);
			console.log(cs);
			adminDispatch(setCompanies(cs));
		}

		if (!isLoading && (adminAddedCompany || firstLoad)) {
			globalDispatch(globalSetAdminAddedCompany(false));
			load();
			if (firstLoad) { setFirstLoad(false); }
		}

	}, [ adminAddedCompany, firstLoad ]);


	const renderRow = (c: ICompanyAdminExt): JSX.Element => {
		return (
			<Tr key={c.id.toString()}>
				<Td>{c.id}</Td>
				<Td>{c.name}</Td>
				<Td>{c.location}</Td>
				<Td>{c.farmCount}</Td>
				<Td>{c.fieldCount}</Td>
				<Td>{c.fileCount}</Td>
				<Td>
					{
						c.adminEmail
							? <NavLink to={`mailto:${c.adminEmail}`}>{c.adminEmail}</NavLink>
							: c.id
					}
				</Td>
				<Td>{ c.status && c.status !== "" ? t(`fieldStatus.${c.status}`) : ""}</Td>
				<Td>{ c.due?.toString() || "" }</Td>
				<Td fontWeight="bold">
					<NavLink to={`/adminCompany?id=${c.id}`}>
						{t("companyList.headerActionEdit")}
					</NavLink>
				</Td>
			</Tr>
		);
	};

	const handleColumnSort = (col: string): void => {
		const newSort = { ...sort };
		newSort.current = col as SortIndex;

		if (newSort.current === col) { newSort[col] = !newSort[col]; }

		const newCompanies = [ ...companies ];
		const ascending: boolean = newSort[col as SortIndex];

		switch (col) {
			case "id":
				newCompanies.sort((c1, c2) => sortFieldNumber(c1.id, c2.id, ascending));
				break;
			case "name":
				newCompanies.sort((c1, c2) => sortFieldString(c1.name, c2.name, ascending));
				break;
			case "location":
				newCompanies.sort((c1, c2) => sortFieldString(c1.location || "", c2.location || "", ascending));
				break;
			case "numFarms":
				newCompanies.sort((c1, c2) => sortFieldNumber(c1.farmCount, c2.farmCount, ascending));
				break;
			case "numFields":
				newCompanies.sort((c1, c2) => sortFieldNumber(c1.fieldCount, c2.fieldCount, ascending));
				break;
			case "numFiles":
				newCompanies.sort((c1, c2) => sortFieldNumber(c1.fileCount, c2.fileCount, ascending));
				break;
			case "admin":
				newCompanies.sort((c1, c2) => sortFieldString(c1.adminEmail || "", c2.adminEmail || "", ascending));
				break;
			case "status":
				newCompanies.sort((c1, c2) => sortFieldString(c1.status || "", c2.status || "", ascending));
				break;
			case "date":
				newCompanies.sort((c1, c2) => sortFieldDate(c1.due || new Date(0), c2.due || new Date(0), ascending));
				break;
			default:
				console.log("no sort");
				break;
		}

		setSort(newSort);
		adminDispatch(setCompanies(newCompanies));
	};

	if (isLoading) {
		return (<Progress isIndeterminate hasStripe size="lg" colorScheme="blue" />);
	}

	return (
		<Card p="2em">
			<Heading size="sm">{t("companyList.title")}</Heading>
			<TableContainer mt="2em">
				<Table variant="striped" size="md">
					<Thead>
						<Tr>
							<Th><Button variant="link" onClick={() => handleColumnSort("id")}>{t("companyList.headerId")}</Button></Th>
							<Th><Button variant="link" onClick={() => handleColumnSort("name")}>{t("companyList.headerName")}</Button></Th>
							<Th><Button variant="link" onClick={() => handleColumnSort("location")}>{t("companyList.headerLocation")}</Button></Th>
							<Th><Button variant="link" onClick={() => handleColumnSort("numFarms")}>{t("companyList.headerNumFarms")}</Button></Th>
							<Th><Button variant="link" onClick={() => handleColumnSort("numFields")}>{t("companyList.headerNumFields")}</Button></Th>
							<Th><Button variant="link" onClick={() => handleColumnSort("numFiles")}>{t("companyList.headerNumFiles")}</Button></Th>
							<Th><Button variant="link" onClick={() => handleColumnSort("admin")}>{t("companyList.headerAdmin")}</Button></Th>
							<Th><Button variant="link" onClick={() => handleColumnSort("status")}>{t("companyList.headerStatus")}</Button></Th>
							<Th><Button variant="link" onClick={() => handleColumnSort("date")}>{t("companyList.headerDate")}</Button></Th>
							<Th>&nbsp;</Th>
						</Tr>
					</Thead>
					<Tbody>
						{ companies.map(c => renderRow(c)) }
					</Tbody>
				</Table>
			</TableContainer>
		</Card>
	);
};


export const AdminCompanyList = (): JSX.Element => (
	<AdminProvider>
		<AdminCompanyListInner />
	</AdminProvider>
);
