import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Progress } from "@chakra-ui/react";

import { Layout } from "../components/layout/Index";
import { useTenantState } from "../contexts/TenantContext";
import { useApi } from "../services";
import { Config } from "../config/Config";
import { ICompany, IField, IFieldAllData, IFieldFile, IFieldYearData, IFolderContents, isIAllFieldData } from "../models";
import { FieldDetails } from "../components/FieldDetails";
import { FieldFileList } from "../components/FieldFileList";
import { useAuthorizationState } from "../contexts/AuthorizationContext";
import { TitleAndNav } from "../components/TitleAndNav";
import { FoldersList } from "../components/FolderFileList";


export const FieldPage = (): JSX.Element => {
	const { t } = useTranslation();
	const { farms } = useTenantState();
	const { isLoading, loadFieldData } = useApi(Config.baseUrl);
	const [ field, setField ] = useState<IField | undefined>(undefined);
	const [ company, setCompany ] = useState<ICompany | undefined>(undefined);
	const [ userFiles, setUserFiles ] = useState<IFieldFile[]>([]);
	const [ gecoFiles, setGecoFiles ] = useState<IFieldFile[]>([]);
	const { isGecoAdmin, isCompanyAdmin } = useAuthorizationState();
	const [ needsUpdate, setNeedsUpdate ] = useState(false);
	const [ folderFiles, setFolderFiles ] = useState<IFolderContents[]>([]);
	const [ gecoPrivateFolderFiles, setGecoPrivateFolderFiles ] = useState<IFolderContents[]>([]);
	const [ fieldHistory, setFieldHistory ] = useState<IFieldYearData[]>([]);
	

	useEffect(() => {
		console.log(farms);
		const load = async (field: number) => {
			const data = await loadFieldData(field);
			if (isIAllFieldData(data)) {
				const fd = data as IFieldAllData;
				setField(fd.field);
				setUserFiles(fd.files);
				setGecoFiles(fd.gecoPrivateFiles);
				setFolderFiles(fd.folderFiles);
				setGecoPrivateFolderFiles(fd.gecoPrivateFolderFiles);
				setFieldHistory(fd.history);
			}
			console.log(data);
		}
		const urlParams = new URLSearchParams(window.location.search);
		const idString = urlParams.get("id");
		if (idString) {
			load(parseInt(idString));
		}
	}, []);

	useEffect(() => {
		console.log("needs an update");
		const load = async (field: number) => {
			const data = await loadFieldData(field);
			if (isIAllFieldData(data)) {
				const fd = data as IFieldAllData;
				setField(fd.field);
				setUserFiles(fd.files);
				setGecoFiles(fd.gecoPrivateFiles);
				setFolderFiles(fd.folderFiles);
				setGecoPrivateFolderFiles(fd.gecoPrivateFolderFiles);
				setFieldHistory(fd.history);
			}
			console.log(data);
		};
		
		const urlParams = new URLSearchParams(window.location.search);
		const idString = urlParams.get("id");
		if (idString) {
			load(parseInt(idString));
		}
	}, [ needsUpdate ]);

	const onDelete = () => setNeedsUpdate(true);

	return (
		<Layout>
			<div>
				<TitleAndNav to={`/fields?id=${field?.farmId}`} title={field?.name || t("field.noFieldData")} />
				{
					!isLoading &&
					<>
						<Box mt="1em">
							<FieldDetails field={field} company={company} history={fieldHistory} />
						</Box>
						<FoldersList
							mt="2em"
							title={t("fieldFiles.custFilesTitle")}
							field={field}
							company={company}
							folders={folderFiles}
							isGecoAdmin={isGecoAdmin}
							isCompanyAdmin={isCompanyAdmin}
							onDelete={onDelete}
						/>
						{
							isGecoAdmin &&
							<FoldersList
								mt="2em"
								title={t("fieldFiles.gecoFilesTitle")}
								field={field}
								company={company}
								folders={gecoPrivateFolderFiles}
								isGecoAdmin={isGecoAdmin}
								isGecoPrivate={true}
								onDelete={onDelete}
							/>
						}
					</>
				}
				{
					isLoading &&
					<Box mt="1em" p="5em">
						<Progress isIndeterminate hasStripe colorScheme="blue" size="lg" />
					</Box>
				}
				<Box p="2em" />
			</div>
		</Layout>
	);
};
