import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Card, FormControl, FormLabel, Heading, Input, Progress, Stack } from "@chakra-ui/react";


import { Config } from "../config/Config";
import { useApi } from "../services";
import { ICompanyUpdate } from "../models";
import { updateCompanyInfo, useAuthorizationDispatch, useAuthorizationState } from "../contexts/AuthorizationContext";
import { useForm } from "react-hook-form";
import { useAdminState } from "../contexts/AdminContext";




type FormValues = {
	companyName: string;
	companyLocation?: string;
}



export const AdminCompanyEditor = (): JSX.Element | null => {
	const { t } = useTranslation();
	const { isGecoAdmin } = useAuthorizationState();
	const { isLoading, updateCompany } = useApi(Config.baseUrl);
	const { company } = useAdminState();
	const authDispatch = useAuthorizationDispatch();

	console.log(company);



	const defaultValues: FormValues = {
		companyName: company?.name || "",
		companyLocation: company?.location || "",
	};
	const {
		setValue,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>({ defaultValues });

	const onSubmit = handleSubmit((data) => {
		const companyData: ICompanyUpdate = {
			id: company?.id || -1,
			name: data.companyName,
			location: data.companyLocation || "",
		};

		updateCompany(companyData);
		authDispatch(updateCompanyInfo(companyData.name, companyData?.location || undefined));
	});

	useEffect(() => {
		console.log("updating editor");
		setValue("companyName", company?.name || "");
		setValue("companyLocation", company?.location || "");
	}, [ company, setValue]);


	if (!isGecoAdmin) { return null; }

	return (
		<Stack spacing="10" mt="10">
			<form onSubmit={onSubmit}>
				<Card p="2em">
					<Heading size="xs">{t("companyEditor.title")}</Heading>
					<FormControl mt="10" isInvalid={!!errors.companyName} isRequired>
						<Input
							id="companyName"
							size="md"
							type="text"
							placeholder=" "
							data-peer
							{ ...register("companyName")}
						/>
						<FormLabel htmlFor="companyName" variant="floating" size="md">
							{t("companyEditor.labelCompanyName")}
						</FormLabel>
					</FormControl>
					<FormControl mt="10">
						<Input
							id="companyLocation"
							size="md"
							placeholder=" "
							type="text"
							data-peer
							{ ...register("companyLocation")}
						/>
						<FormLabel htmlFor="companyLocation" variant="floating" size="md">
							{t("companyEditor.labelCompanyLocation")}
						</FormLabel>
					</FormControl>
					<Box w="100%" textAlign="center">
						{
							!isLoading &&
							<Button mt="4" variant="primary" w="150px" h="50px" mr="2em" ml="2em" type="submit">{t("companyEditor.save")}</Button>
						}
						{ isLoading && <Progress isIndeterminate mt="2em" size="lg" colorScheme="blue" hasStripe w="100%" /> }
					</Box>
				</Card>
			</form>
		</Stack>
	);
};
