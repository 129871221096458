import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, TableContainer, Tbody, Thead, Th, Td, Tr } from "@chakra-ui/react";


import { useAdminDispatch, useAdminState, setSelectedFarm } from "../contexts/AdminContext";
import { IFarmWithField, IField } from "../models";



function getFarm(farms: IFarmWithField[], i: number): IFarmWithField | null {
	farms.forEach(f => {
		if (f.id === i) { return f; }
	});

	return null;
}



export const AdminFarmList = (): JSX.Element => {
	const { t } = useTranslation();
	const { farms, selectedFarm } = useAdminState();
	const adminDispatch = useAdminDispatch();

	useEffect(() => {
		console.log("selected farm changed");
	}, [ selectedFarm ])

	const onFarmClick = (farm: IFarmWithField): void => {
		console.log(farm.id);
		adminDispatch(setSelectedFarm(farm));
	};

	const renderTableRow = (f: IFarmWithField): JSX.Element => {
		f.fields.sort((f1, f2) => {
			if (f2 < f1) { return -1; }
			if (f2 > f1) { return 1; }
			return 0;
		});

		let acres = 0;
		f.fields.forEach(field => acres += field.acres || 0);

		return (
			<Tr key={f.id.toString()} style={{ cursor: "pointer"}}>
				<Td onClick={() => onFarmClick(f)}>{f.id}</Td>
				<Td onClick={() => onFarmClick(f)}>{f.name}</Td>
				<Td>{f.farmAddress}</Td>
				<Td>{f.latitude}</Td>
				<Td>{f.longitude}</Td>
				<Td>{f.fields.length}</Td>
				<Td>{acres}</Td>
				<Td>{f.fields.length > 0 ? f.fields[0].dueDate?.toString() : "&nbsp;"}</Td>
			</Tr>
		);
	};


	return (
		<TableContainer>
			<Table variant="striped" size="md">
				<Thead>
					<Tr>
						<Th>{t("farmsList.columnId")}</Th>
						<Th>{t("farmsList.columnName")}</Th>
						<Th>{t("farmsList.columnLocation")}</Th>
						<Th>{t("farmsList.columnLat")}</Th>
						<Th>{t("farmsList.columnLng")}</Th>
						<Th>{t("farmsList.columnNumFields")}</Th>
						<Th>{t("farmsList.columnAcres")}</Th>
						<Th>{t("farmsList.columnDueDate")}</Th>
					</Tr>
				</Thead>
				<Tbody>
					{ farms.map(f => renderTableRow(f))}
				</Tbody>
			</Table>
		</TableContainer>
	);
};
