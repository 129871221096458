import { ICompany } from "../../models";
import { IAuthorizationState, initialAuthorizationState } from "./IAuthorizationState";
import { AuthAction, AuthActionTypes } from "./actions";

export const reducer = (state: IAuthorizationState = initialAuthorizationState, action: AuthAction): IAuthorizationState => {
	switch (action.type) {
		case AuthActionTypes.SET_AUTH:
			return {
				...state,
				user: action.user || null,
				company: action.company || null,
				permissions: action.permissions || [],
				isCompanyAdmin: action.isCompanyAdmin || false,
				isGecoAdmin: action.isGecoAdmin || false,
			};
		case AuthActionTypes.CLEAR_AUTH:
			return {
				...state,
				user: action.user || null,
				company: action.company || null,
				permissions: [],
				isCompanyAdmin: false,
				isGecoAdmin: false,
			};
		case AuthActionTypes.UPDATE_COMPANY:
			if (!state.company) { return state; }
			const newCompany: ICompany = {
				...state.company,
				name: action.companyName || state.company.name,
				location: action.companyLocation || state.company.location,
			};
			return {
				...state,
				company: newCompany,
			}
		case AuthActionTypes.UPDATE_ADMIN_COMPANY:
			return {
				...state,
				adminCompanyId: action.adminCompanyId || state.adminCompanyId,
			};
		default:
			return state;
	}
};
