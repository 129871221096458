import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication, EventType, EventMessage, AccountInfo, AuthenticationResult } from "@azure/msal-browser";

import reportWebVitals from "./reportWebVitals";
import { App } from "./App";
import { theme } from "./theme";
import "./i18n";
import { msalConfig } from "./config/msalConfig";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);
const allAccounts = msalInstance.getAllAccounts();

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && allAccounts.length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
	console.log("setting active account");
	console.log(allAccounts[0]);
    msalInstance.setActiveAccount(allAccounts[0]);
} else {
	console.log("in index.tsx, no accounts");
}

msalInstance.addEventCallback((event: EventMessage) => {
	console.log(`inside of msalInstance event callback: ${event.eventType}`);
	const typedPayload = event?.payload as AuthenticationResult;
    if (
			(event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || event.eventType === EventType.SSO_SILENT_SUCCESS) &&
			typedPayload?.account
		) {
		console.log(typedPayload.account);
        msalInstance.setActiveAccount(typedPayload.account);
		console.log(msalInstance.getActiveAccount());
    }
});


const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<div>
		<BrowserRouter>
			<ChakraProvider theme={theme}>
				<App instance={msalInstance} />
			</ChakraProvider>
		</BrowserRouter>
	</div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
