import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Heading, Progress } from "@chakra-ui/react";


import { Layout } from "../components/layout/Index";
import { useApi } from "../services";
import { Config } from "../config/Config";
import { IUserTenantData, isIUserTenantData } from "../models";
import { useTenantDispatch, useTenantState, setTenantAuthData } from "../contexts/TenantContext";
import { FieldList } from "../components/FieldList";



export const FieldsPage = (): JSX.Element => {
	const { t } = useTranslation();
	const { isLoading, loadTenant } = useApi(Config.baseUrl);
	const { farmFields, authInfo } = useTenantState();
	const tenantDispatch = useTenantDispatch();

	useEffect(() => {
		const load = async () => {
			const data = await loadTenant();
			if (isIUserTenantData(data)) {
				tenantDispatch(setTenantAuthData(data.farms, data.authInfo));
			}
		};

		load();
	}, []);

	return (
		<Layout>
			<div>
				<Heading size="sm">{t("fields.title")}</Heading>
				{ isLoading && <Progress isIndeterminate width="100%" /> }
				{ !isLoading && <FieldList /> }
			</div>
		</Layout>
	);
};
