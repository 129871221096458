import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	BoxProps,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Select,
	Spacer,
	Stack,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react";

import { setNeedUpdate, useAdminUserDispatch, useAdminUserState } from "../contexts/AdminUserContext";
import { useApi } from "../services";
import { Config } from "../config/Config";
import { useGlobalDispatch, globalSetError, globalSetInfo } from "../contexts/GlobalContext";
import { IUserInviteEdit, isIProblemDetails } from "../models";
import { useAuthorizationState } from "../contexts/AuthorizationContext";
import { UpdateButton } from "./UpdateButton";


interface IPermissionSelectProps {
	farmId: number;
	onChange: (farmId: number, permission: string) => void;
}

const PermissionSelect = ({ farmId, onChange }: IPermissionSelectProps): JSX.Element => {
	const { t } = useTranslation();

	const onChangeValue = (e: any): void => {
		onChange(farmId, e.target.value);
	};

	return (
		<Select defaultValue="-1" onChange={onChangeValue} size="sm">
			<option value="-1">{t("inviteUser.none")}</option>
			<option value="0">{t("inviteUser.read")}</option>
			<option value="1">{t("inviteUser.write")}</option>
			<option value="2">{t("inviteUser.admin")}</option>
		</Select>
	);
};


export interface IEditFarmPermission {
	farmId: number;
	farmName: string;
	permission: string;
}


function convertPermission(permId: string): string {
	if (permId === "0") { return "read"; }
	if (permId === "1") { return "write"; }
	if (permId === "2") { return "admin"; }

	//invalid permission value
	return "";
}


interface IProps extends BoxProps {
	showTitle?: boolean;
}


export const InviteUser = ({ showTitle, ...rest }: IProps): JSX.Element => {
	const { t } = useTranslation();
	const { company } = useAuthorizationState();
	const { selectedFarm, farms } = useAdminUserState();
	const [ email, setEmail ] = useState("");
	const [ firstName, setFirstName ] = useState("");
	const [ lastName, setLastName ] = useState("");
	const [ username, setUsername ] = useState("");
	const [ editFarms, setEditFarms ] = useState<IEditFarmPermission[]>([]);
	const { isLoading, inviteNewUser } = useApi(Config.baseUrl);
	const globalDispatch = useGlobalDispatch();
	const adminUserDispatch = useAdminUserDispatch();

	
	useEffect(() => {
		const l: IEditFarmPermission[] = [];
		farms.forEach(f => l.push({
			farmId: f.farm.id,
			farmName: f.farm.name,
			permission: "-1",
		}));
		setEditFarms(l);
	}, [ farms ]);

	const handlePermissionChange = (farmId: number, permission: string): void => {
		console.log(`${farmId} => ${permission}`);
		const newFarms: IEditFarmPermission[] = [];
		editFarms.forEach(f => {
			if (f.farmId === farmId) {
				newFarms.push({
					farmId: f.farmId,
					farmName: f.farmName,
					permission,
				})
			} else {
				newFarms.push(f);
			}
		});

		setEditFarms(newFarms);
	};

	const handleInviteClick = async (): Promise<void> => {
		if (!checkIfUserSpecified()) {
			globalDispatch(globalSetError(t("inviteUser.userNotSpecified"), t("inviteUser.errorTitle")));
			return;
		}
		if (!checkIfFarmsSelected()) {
			globalDispatch(globalSetError(t("inviteUser.noFarmsSpecified"), t("inviteUser.errorTitle")));
			return;
		}

		const invite: IUserInviteEdit = {
			firstName,
			lastName,
			userName: username,
			email,
			editUserIds: [],
			farmIds: [],
			permissions: [],
		};

		editFarms.forEach(f => {
			if (f.permission !== "-1") {
				invite.farmIds.push(f.farmId);
				invite.permissions.push({
					companyId: company?.id || 0,
					farmId: f.farmId,
					userId: 0,
					permission: convertPermission(f.permission),
				});
			}
		});

		const result = await inviteNewUser(company?.id || 0, invite);
		if (isIProblemDetails(result)) {
			globalDispatch(globalSetError(t("inviteUser.cantAddUser"), t("inviteUser.errorTitle")));
			return;
		}

		globalDispatch(globalSetInfo(t("inviteUser.userAdded"), t("inviteUser.infoTitle")));
		adminUserDispatch(setNeedUpdate(true));
	};

	const checkIfFarmsSelected = (): boolean => {
		let farmSelected = false;
		editFarms.forEach(f => {
			console.log(f.permission);
			if (f.permission !== "-1") { farmSelected = true; }
		});

		return farmSelected;
	};

	const checkIfUserSpecified = (): boolean => {
		if (username === "") { return false; }
		if (email === "") { return false; }
		return true;
	};

	return (
		<Box { ...rest }>
			<Stack spacing="10">
				{
					showTitle &&
					<Heading size="xs">
						{t("inviteUser.title")}
					</Heading>
				}
				<FormControl mt="2em">
					<Input
						id="email"
						type="email"
						size="md"
						placeholder=" "
						data-peer
						value={email}
						onChange={e => setEmail(e.target.value)}
					/>
					<FormLabel htmlFor="email" size="md" variant="floating">
						{t("inviteUser.emailLabel")}
					</FormLabel>
				</FormControl>
				<FormControl>
					<Input
						id="first"
						size="md"
						placeholder=" "
						data-peer
						value={firstName}
						onChange={e => setFirstName(e.target.value)}
					/>
					<FormLabel htmlFor="first" size="md" variant="floating">
						{t("inviteUser.firstNameLabel")}
					</FormLabel>
				</FormControl>
				<FormControl>
					<Input
						id="last"
						size="md"
						placeholder=" "
						data-peer
						value={lastName}
						onChange={e => setLastName(e.target.value)}
					/>
					<FormLabel htmlFor="last" size="md" variant="floating">
						{t("inviteUser.lastNameLabel")}
					</FormLabel>
				</FormControl>
				<FormControl>
					<Input
						id="username"
						size="md"
						placeholder=" "
						data-peer
						value={username}
						onChange={e => setUsername(e.target.value)}
					/>
					<FormLabel htmlFor="username" size="md" variant="floating">
						{t("inviteUser.usernameLabel")}
					</FormLabel>
				</FormControl>
				<TableContainer>
					<Table variant="striped" size="md">
						<Thead>
							<Tr>
								<Th>{t("inviteUser.farmIdColumn")}</Th>
								<Th>{t("inviteUser.farmNameColumn")}</Th>
								<Th>{t("inviteUser.permissionColumn")}</Th>
							</Tr>
						</Thead>
						<Tbody>
							{
								editFarms.map(f => (
									<Tr key={f.farmId}>
										<Td>{f.farmId}</Td>
										<Td>{f.farmName}</Td>
										<Td><PermissionSelect farmId={f.farmId} onChange={handlePermissionChange} /></Td>
									</Tr>
								))
							}
						</Tbody>
					</Table>
				</TableContainer>
				<Flex flex="row">
					<Spacer />
					{/* <Button variant="solid" colorScheme="blue" w="150px" h="50px" onClick={handleInviteClick}>
						{t("inviteUser.sendInvite")}
					</Button> */}
					<UpdateButton
						caption={t("inviteUser.sendInvite") || "SEND INVITE"}
						isLoading={isLoading}
						onClick={handleInviteClick}
						w="150px" h="50px"
					/>
					<Spacer />
				</Flex>
			</Stack>
		</Box>
	);
};
