import { ITenantState, initialTenantState } from "./ITenantState";
import { TenantAction, TenantActionTypes } from "./actions";


export const reducer = (state: ITenantState = initialTenantState, action: TenantAction): ITenantState => {
	switch (action.type) {
		case TenantActionTypes.SET_TENANT_DATA:
			return {
				...state,
				farms: action.farms || [],
				fields: action.fields || [],
			};
		case TenantActionTypes.SET_TENANT_AUTH_DATA:
			return {
				...state,
				farmFields: action.farmFields || [],
				authInfo: action.authInfo || state.authInfo,
			};
		case TenantActionTypes.SET_SELECTED_FARM:
			return {
				...state,
				selectedFarm: action.selectedFarm,
			};
		case TenantActionTypes.SET_SELECTED_FIELD:
			return {
				...state,
				selectedField: action.selectedField,
			};
		default:
			return state;
	}
};
