import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
	Box,
	FormControl,
	FormLabel,
	Heading,
	Select,
	TabList,
	Tab,
	Tabs,
	TabPanels,
	TabPanel,
} from "@chakra-ui/react";


import { Layout } from "../components/layout/Index";
import { AdminUserProvider, setUsers, setFarms, setSelectedFarm, useAdminUserDispatch, useAdminUserState, setNeedUpdate, setUserPermissions } from "../contexts/AdminUserContext";
import { useApi } from "../services";
import { Config } from "../config/Config";
import { isIProblemDetails, IUser, IFarmUserData, IUserFarmPermission } from "../models";
import { globalSetError, useGlobalDispatch } from "../contexts/GlobalContext";
import { UpdateButton } from "../components/UpdateButton";
import { InviteUser } from "../components/InviteUser";
import { UserList } from "../components/UserList";



const UsersPageInner = (): JSX.Element => {
	const { t } = useTranslation();
	const location = useLocation();
	const { getUsersForCompany, isLoading } = useApi(Config.baseUrl);
	const adminUserDispatch = useAdminUserDispatch();
	const { selectedFarm, farms, needUpdate } = useAdminUserState();
	const [ farmSelect, setFarmSelect ] = useState("-1");
	const globalDispatch = useGlobalDispatch();


	useEffect(() => {
		console.log("running useEffect on needsUpdate");
		const load = async (id: string) => {
			const result = await getUsersForCompany(parseInt(id));
			if (isIProblemDetails(result)) {
				globalDispatch(globalSetError(t("userDash.noFarmSelected"), t("userDash.error")));
				return;
			}

			console.log(result);

			const farms: IFarmUserData[] = [];
			result.farmUserData.forEach(f => farms.push(f));
			adminUserDispatch(setFarms(farms));

			const index = parseInt(farmSelect);
			if (!isNaN(index) && index > -1) {
				const newUserList: IUser[] = [];
				result.farmUserData[index].users.forEach(nu => newUserList.push(nu));
				adminUserDispatch(setUsers(newUserList));
				const newUserPerms: IUserFarmPermission[] = [];
				result.farmUserData[index].permissions.forEach(nu => newUserPerms.push(nu));
				adminUserDispatch(setUserPermissions(newUserPerms));
			} else {
				console.log("something wrong with index, don't do anything");
			}

			adminUserDispatch(setNeedUpdate(false));
		};

		const searchParams = new URLSearchParams(location.search);
		const companyId = searchParams.get("companyId");
		if (!companyId) { return; }
		if (needUpdate) {
			load(companyId);
		}
	}, [ needUpdate, location.search ]);


	const handleSelectChange = (v: string): void => {
		console.log(v);
		setFarmSelect(v);
		const index = parseInt(v);
		if (!isNaN(index) && index > -1) {
			const farmUsers = farms[index].permissions;
			adminUserDispatch(setUserPermissions([ ...farmUsers ]));
			adminUserDispatch(setSelectedFarm(farms[index]));
		} else {
			adminUserDispatch(setUserPermissions([]));
			adminUserDispatch(setSelectedFarm(null));
		}
	};

	return (
		<Layout>
			<>
				<Heading size="sm">{t("userDash.title")} - </Heading>
				<form>
					<Box mt="10">
						<FormControl>
							<FormLabel htmlFor="farmSelect" size="md">
								{t("userDash.selectFarm")}
							</FormLabel>
							<Select id="farmSelect" variant="outline" size="md" defaultValue="-1" onChange={e => handleSelectChange(e.target.value)}>
								<option key="-1" value="-1">{t("userDash.farmSelect")}</option>
								{
									farms.map((f, i) => <option key={i} value={i.toString()}>{f.farm.name}</option>)
								}
							</Select>
						</FormControl>
					</Box>
				</form>
				<Tabs mt="1em">
					<TabList>
						<Tab>{t("userDash.tabManage")}</Tab>
						<Tab>{t("userDash.tabAdd")}</Tab>
					</TabList>
					<TabPanels>
						<TabPanel><UserList /></TabPanel>
						<TabPanel><InviteUser /></TabPanel>
					</TabPanels>
				</Tabs>
			</>
		</Layout>
	);
};


export const UsersPage = (): JSX.Element => {

	return (
		<AdminUserProvider>
			<UsersPageInner />
		</AdminUserProvider>
	);
};
