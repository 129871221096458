import React from "react";
import { Container } from "@chakra-ui/react";


import { Navbar } from "./Navbar";
import { Footer } from "./Footer";


interface IProps {
	children: JSX.Element;
}

export const MarketingLayout = ({ children }: IProps): JSX.Element => {

	return (
		<Container>
			<Navbar />
				{children}
			<Footer />
		</Container>
	);
};