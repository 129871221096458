import { IFarmUserData, IUser, IUserFarmPermission } from "../../models";


export interface IAdminUserState {
	users: IUser[];
	farms: IFarmUserData[];
	selectedFarm: IFarmUserData | null;
	needUpdate: boolean;
	userPermissions: IUserFarmPermission[];
}


export const initialState: IAdminUserState = {
	users: [],
	farms: [],
	selectedFarm: null,
	needUpdate: true,
	userPermissions: [],
};

