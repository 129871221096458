import React from "react";
import { Heading, Stack, Text } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

import { IArticle } from "../../models";

interface IProps {
	a: IArticle;
}

export const Article = ({ a }: IProps): JSX.Element => {
	return (
		<Stack spacing={{ base: "6", md: "8" }}>
			<Heading size="xs">
				<ExternalLinkIcon mr="0.5em" />
				<a href={a.url} target="_blank" rel="noreferrer">
					{a.title}
				</a>
			</Heading>
			<Text textStyle={{ base: "sm", md: "md" }} color="fg.muted">
				{a.description}
			</Text>
		</Stack>
	);
};
