import { Box, Card, CardBody, CardHeader, Container, Heading, Image, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BottomOfPageContactUs } from "../BottomOfPageContactUs";
import { YouTubeEmbed } from "./YouTubeEmbed";

export const HerbicideResistanceDetails = (): JSX.Element => {
	const { t } = useTranslation();
	return (
		<Container py={{ base: "16", md: "12" }}>
			<Card>
				<CardHeader>
					<Heading size="sm">{t("herbResist.detail1Title")}</Heading>
				</CardHeader>
				<CardBody>
					<Text>{t("herbResist.detail1")}</Text>
					<Box p="2" boxShadow="lg" borderRadius="xl">
						<Image
							align="center"
							src="herbicide-resistance-scouting.png"
							alt={t("herbResist.scoutAlt") || "Herbicide Resistance Scouting"}
						/>
					</Box>
				</CardBody>
			</Card>

			<Card mt="2em">
				<CardHeader>
					<Heading size="sm">{t("herbResist.detail2Title")}</Heading>
				</CardHeader>
				<CardBody>
					<Text>{t("herbResist.detail2")}</Text>
					<Box borderWidth="1px" borderRadius="xl" p="2" boxShadow="lg">
						<Image
							align="center"
							src="herbicide-resistance-workflow.png"
							alt={t("herbResist.resistScoutAlt") || "Herbicide Resistance Scouting"}
						/>
					</Box>
					<Text mt="1.5em">{t("herbResist.videoDemoText")}</Text>

					<YouTubeEmbed src="https://www.youtube.com/embed/apqSjsVa0VY" title={t("herbResist.videoDemoText") || undefined} center />
				</CardBody>
			</Card>

			<BottomOfPageContactUs />

		</Container>
	);
};
