import { useTranslation } from "react-i18next";

import { CallToAction } from "../components/marketing-layout/CallToAction";
import { Team } from "../components/marketing-layout/Team"
import { MarketingLayout } from "../components/marketing-layout";
import { NewsList } from "../components/newsList";


export const MarketingAboutUs = () => {
	const { t } = useTranslation();

	return (
        <MarketingLayout>
			<>
				<CallToAction
					headingTitle={t("aboutUs.ctaTitle")}
					headingDescription={t("aboutUs.ctaDescription")}
					leftButtonLink="/get-started"
					leftButtonText={t("aboutUs.ctaLeftButton") || "Contact Us"}
					rightButtonLink="/contact-us"
					rightButtonText={t("aboutUs.ctaRightButton") || "About Us"}
					ctaImageUrl="about-us.png"
					ctaImageAlt={t("aboutUs.ctaImageAlt") || "Get Started Image"}
				/>
	            <Team />
				<NewsList mt="2em" />
			</>
        </MarketingLayout>
    );
}
