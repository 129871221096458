import { IFarmUserData, IUser, IUserFarmPermission } from "../../models";


export enum AdminUserActionTypes {
	SET_FARMS = "@adminUsers/setFarms",
	SET_USERS = "@adminUsers/setUsers",
	SET_SELECTED_FARM = "@adminUsers/setSelectedFarm",
	SET_NEED_UPDATE = "@adminUsers/setNeedUpdate",
	SET_USER_PERMISSIONS = "@adminUsers/setUserPerms",
}

export type AdminUserAction = {
	type: AdminUserActionTypes;
	farms?: IFarmUserData[];
	users?: IUser[];
	selectedFarm?: IFarmUserData | null;
	needUpdate?: boolean;
	userPermissions?: IUserFarmPermission[];
}

export const setSelectedFarm = (selectedFarm: IFarmUserData | null): AdminUserAction => ({
	type: AdminUserActionTypes.SET_SELECTED_FARM,
	selectedFarm,
});

export const setFarms = (farms: IFarmUserData[]): AdminUserAction => ({
	type: AdminUserActionTypes.SET_FARMS,
	farms,
});

export const setUsers = (users: IUser[]): AdminUserAction => ({
	type: AdminUserActionTypes.SET_USERS,
	users,
});

export const setNeedUpdate = (needUpdate: boolean): AdminUserAction => ({
	type: AdminUserActionTypes.SET_NEED_UPDATE,
	needUpdate,
});

export const setUserPermissions = (userPermissions: IUserFarmPermission[]): AdminUserAction => ({
	type: AdminUserActionTypes.SET_USER_PERMISSIONS,
	userPermissions,
});
