import { IFarm } from "./IFarm";
import { IField } from "./IField";
import { IFieldFile, IFolderContents } from "./IFieldFile";
import { IFieldYearData } from "./IFieldYearData";

export interface IFieldAllData {
	farm: IFarm;
	field: IField;
	files: IFieldFile[];
	gecoPrivateFiles: IFieldFile[];

	history: IFieldYearData[];

	folderFiles: IFolderContents[];
	gecoPrivateFolderFiles: IFolderContents[];
}

export const isIAllFieldData = (i: any): i is IFieldAllData => {
	if (!i) { return false; }
	return (
		"farm" in i &&
		"field" in i &&
		"files" in i &&
		"gecoPrivateFiles" in i
	);
};

