import { ICompany } from "../../models";
import { IAdminState, initialAdminState } from "./IAdminState";
import { AdminAction, AdminActionTypes } from "./actions";


export const reducer = (state: IAdminState = initialAdminState, action: AdminAction): IAdminState => {
	switch (action.type) {
		case AdminActionTypes.SET_COMPANIES:
			return {
				...state,
				companies: action.companies || [],
			};
		case AdminActionTypes.SET_ALL_COMPANY_DATA:
			return {
				...state,
				company: action.company || state.company,
				farms: action.farms || [],
				users: action.users || [],
			};
		case AdminActionTypes.SET_SELECTED_FARM:
			return {
				...state,
				selectedFarm: action.selectedFarm,
			};
		default:
			return state;
	}
};
