import React from "react";


import { MarketingLayout } from "../components/marketing-layout";


export const MarketingTestPage = (): JSX.Element => {

	return (
		<MarketingLayout>
			<div>test</div>
		</MarketingLayout>
	);
};
