import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, TableContainer, Tbody, Thead, Th, Td, Tr, TableCaption } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import { useTenantState } from "../contexts/TenantContext";
import { IField } from "../models";

export const FieldList = (): JSX.Element => {
	const { t } = useTranslation();
	const { farmFields } = useTenantState();
	const [ fields, setFields ] = useState<IField[]>([]);


	useEffect(() => {
		const flattenFields = (): IField[] => {
			const list: IField[] = [];
			if (farmFields) {
				farmFields.forEach(ff => {
					list.push(...ff.fields || []);
				});
			}

			return list;
		};
	
		setFields(flattenFields());
	}, [ farmFields ]);

	const renderFieldRow = (key: string, f: IField): JSX.Element => {

		return (
			<Tr key={key}>
				<Td>
					<NavLink to={`/field?id=${f.id}`}>{f.id}</NavLink>
				</Td>
				<Td>
					<NavLink to={`/field?id=${f.id}`}>{f.name}</NavLink>
				</Td>
				<Td>{f.description}</Td>
				<Td>{f.latitude}</Td>
				<Td>{f.longitude}</Td>
			</Tr>
		);
	};

	return (
		<TableContainer>
			<Table variant="simple" size="md">
				<TableCaption>{t("fields.caption")}</TableCaption>
				<Thead>
					<Tr>
						<Th>{t("fields.idHeader")}</Th>
						<Th>{t("fields.nameHeader")}</Th>
						<Th>{t("fields.descriptionHeader")}</Th>
						<Th>{t("fields.latHeader")}</Th>
						<Th>{t("fields.lngHeader")}</Th>
						<Th>{t("fields.fileStatusHeader")}</Th>
					</Tr>
				</Thead>
				<Tbody>
					{
						fields.map((f, i) => renderFieldRow(i.toString(), f))
					}
				</Tbody>
			</Table>
		</TableContainer>
	);
};




