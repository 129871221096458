import React from "react";
import { Route, Routes } from "react-router-dom";
import { PublicClientApplication, } from "@azure/msal-browser";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, } from "@azure/msal-react";

import { MainPage } from "./pages/MainPage";
import { AdministratorHomePage } from "./pages/AdministratorHomePage";
import { AuthorizationProvider } from "./contexts/AuthorizationContext";
import { GlobalProvider } from "./contexts/GlobalContext";
import { TenantProvider } from "./contexts/TenantContext";
import { FarmsPage } from "./pages/FarmsPage";
import { FieldsPage } from "./pages/FieldsPage";
import { UsersPage } from "./pages/UsersPage";
import { FieldPage } from "./pages/FieldPage";
import { MarketingHomePage } from "./pages/MarketingHomePage";
import { MarketingGetStarted } from "./pages/MarketingGetStarted";
import { MarketingWeedPrediction } from "./pages/MarketingWeedPrediction";
import { MarketingHerbicideResistance } from "./pages/MarketingHerbicideResistance";
import { MarketingAboutUs } from "./pages/MarketingAboutUs";
import { MarketingContactUs } from "./pages/MarketingContactUs";
import { MarketingTestPage } from "./pages/MarketingTestPage";
import { CompanyPage } from "./pages/CompanyPage";
import { AdministratorCompanyPage } from "./pages/AdministratorCompanyPage";
import { FarmPage } from "./pages/FarmPage";
import { UploadFieldFilesPage } from "./pages/UploadFieldFilesPage";
import { EditFilePage } from "./pages/EditFilePage";
import { UserProfilePage } from "./pages/UserProfilePage";
import { AdministratorFieldPage } from "./pages/AdminFieldPage";
import { NotAuthorizedPage } from "./pages/NotAuthorizedPage";

interface IProps {
	instance: PublicClientApplication;
}

export const App = ({ instance }: IProps): JSX.Element => {
	return (
		<MsalProvider instance={instance}>
			<AuthenticatedTemplate>
				<GlobalProvider>
					<AuthorizationProvider>
						<TenantProvider>
							<Routes>
								<Route path="/" element={<MainPage />} />
								<Route path="/admin" element={<AdministratorHomePage />} />
								<Route path="/adminCompany" element={<AdministratorCompanyPage />} />
								<Route path="/adminField" element={<AdministratorFieldPage />} />
								<Route path="/company" element={<CompanyPage />} />
								<Route path="/farms" element={<FarmsPage />} />
								<Route path="/fields" element={<FieldsPage />} />
								<Route path="/field" element={<FieldPage />} />
								<Route path="/users" element={<UsersPage />} />
								<Route path="/upload" element={<UploadFieldFilesPage />} />
								<Route path="/farm" element={<FarmPage />} />
								<Route path="/editFile" element={<EditFilePage />} />
								<Route path="/profile" element={<UserProfilePage />} />
								<Route path="/notAuthorized" element={<NotAuthorizedPage />} />
							</Routes>
						</TenantProvider>
					</AuthorizationProvider>
				</GlobalProvider>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<Routes>
					<Route path="/" element={<MarketingHomePage />} />
					<Route path="/get-started" element={<MarketingGetStarted />} />
					<Route path="/weed-prediction" element={<MarketingWeedPrediction />} />
					<Route path="/herbicide-resistance" element={<MarketingHerbicideResistance />} />
					<Route path="/contact-us" element={<MarketingContactUs />} />
					<Route path="/about-us" element={<MarketingAboutUs />} />
					<Route path="/test" element={<MarketingTestPage />} />
				</Routes>
			</UnauthenticatedTemplate>
		</MsalProvider>
	);
};
