import { ICompany, ICompanyAdminExt, ICompanyContainer, IFarmWithField, IUserAuthInfo } from "../../models";

export enum AdminActionTypes {
	SET_COMPANIES = "@admin/setCompanies",
	SET_COMPANY_CONTAINER = "@admin/setContainer",
	SET_ALL_COMPANY_DATA = "@admin/setAllData",
	SET_SELECTED_FARM = "@admin/setSelectedFarm",
}


export type AdminAction = {
	type: AdminActionTypes;
	companies?: ICompanyAdminExt[];
	companyContainer?: ICompanyContainer;
	company?: ICompany;
	farms?: IFarmWithField[];
	users?: IUserAuthInfo[];
	selectedFarm?: IFarmWithField;
}



export const setCompanies = (companies: ICompanyAdminExt[]): AdminAction => ({
	type: AdminActionTypes.SET_COMPANIES,
	companies,
});

export const setCompanyContainer = (companyContainer: ICompanyContainer): AdminAction => ({
	type: AdminActionTypes.SET_COMPANY_CONTAINER,
	companyContainer,
});


export const setAllCompanyData = (company: ICompany, farms: IFarmWithField[], users: IUserAuthInfo[]): AdminAction => ({
	type: AdminActionTypes.SET_ALL_COMPANY_DATA,
	company,
	farms,
	users,
});


export const setSelectedFarm = (farm: IFarmWithField | undefined): AdminAction => ({
	type: AdminActionTypes.SET_SELECTED_FARM,
	selectedFarm: farm,
});
