import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	Checkbox,
	Table,
	TableContainer,
	Thead,
	Tbody,
	Td,
	Th,
	Tr,
} from "@chakra-ui/react";

import { IMruField } from "../models";
import { useAuthorizationState } from "../contexts/AuthorizationContext";


interface IProps {
	fields?: IMruField[];
}

export const DashboardFieldList = ({ fields = [] }: IProps): JSX.Element => {
	const { t } = useTranslation();
	const { isGecoAdmin } = useAuthorizationState();


	const renderFieldRow = (f: IMruField): JSX.Element => {
		const creator = f.createdByUserName;

		return (
			<Tr key={f.id}>
				<Td>
					<NavLink to={`/field?id=${f.id}`}>
						{f.id}
					</NavLink>
				</Td>
				<Td>
					<NavLink to={`/field?id=${f.id}`}>
						{f.fieldName}
					</NavLink>
				</Td>
				<Td>
					<NavLink to={`/field?id=${f.id}`}>
						{f.description}
					</NavLink>
				</Td>
				<Td>
					<NavLink to={`/farm?id=${f.farmId}`}>
						{f.farmName}
					</NavLink>
				</Td>
				<Td>
					<a href={`mailto:${f.createdByEmail}`}>
						{creator}
					</a>
				</Td>
				<Td>{f.createdDate.toLocaleString()}</Td>
				<Td>{f.acres || "0"}</Td>
				<Td>{f.dueDate?.toString()}</Td>
				<Td>{f.numberOfFiles}</Td>
				<Td><Checkbox defaultChecked={f.hasGecoAnalysis} /></Td>
				{ isGecoAdmin && <Td><Checkbox defaultChecked={f.hasGecoFiles} /></Td> }
			</Tr>
		);
	};

	return (
		<TableContainer>
			<Table variant="striped" size="md">
				<Thead>
					<Tr>
						<Th>{t("mruFields.id")}</Th>
						<Th>{t("mruFields.fieldName")}</Th>
						<Th>{t("mruFields.description")}</Th>
						<Th>{t("mruFields.farmName")}</Th>
						<Th>{t("mruFields.createdBy")}</Th>
						<Th>{t("mruFields.createDate")}</Th>
						<Th>{t("mruFields.acres")}</Th>
						<Th>{t("mruFields.dueDate")}</Th>
						<Th>{t("mruFields.numFiles")}</Th>
						<Th>{t("mruFields.hasGecoAnalysis")}</Th>
						{ isGecoAdmin && <Th>{t("mruFields.hasGecoPrivate")}</Th> }
					</Tr>
				</Thead>
				<Tbody>
					{ fields.map(f => renderFieldRow(f)) }
				</Tbody>
			</Table>
		</TableContainer>
	);
};
