import React, { useEffect, useState, } from "react";
import {
	Box,
	Button,
	Container,
	FormControl,
	FormHelperText,
	FormLabel,
	Heading,
	HStack,
	Input,
	Progress,
	Stack,
} from "@chakra-ui/react";
import { Card } from "../components/layout/Card";
import { setAuth, useAuthorizationDispatch } from "../contexts/AuthorizationContext";
import { useGlobalDispatch, globalSetError, globalSetInfo, useGlobalState, globalSetAdminAddedCompany } from "../contexts/GlobalContext";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { IProblemDetails, ISetupTenant, IUserAllData } from "../models";
import { useApi } from "../services";
import { Config } from "../config/Config";
import { UpdateButton } from "./UpdateButton";

const isSuccessfulTenantSetupResult = (i: any): i is IUserAllData => {
	if (!i) { return false; }
	return (
		"user" in i &&
		"company" in i &&
		"farmPermissions" in i &&
		"isCompanyAdministrator" in i &&
		"isGecoAdministrator" in i
	);
};


function decodeJwt(token: string): any {
	const segments = token.split(".");
	const payload = segments[1].replace(/-/g, '+').replace(/_/, '/');
	const json = decodeURIComponent(
		Array.from(atob(payload))
			.map(c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
			.join("")
	);

	return JSON.parse(json);
}


interface IProps {
	isInAdmin?: boolean;
}

export const CompanySetup = ({ isInAdmin = false }: IProps): JSX.Element => {
	const { t } = useTranslation();
	const { instance } = useMsal();
	const authDispatch = useAuthorizationDispatch();
	const [ editCompany, setEditCompany ] = useState("");
	const [ editLocation, setEditLocation ] = useState("");
	const { isLoading, setupTenant } = useApi(Config.baseUrl);
	const [ editFirstName, setEditFirstName ] = useState("");
	const [ editLastName, setEditLastName ] = useState("");
	const [ editPhone, setEditPhone ] = useState("");
	const [ userName, setUserName ] = useState("");
	const [ email, setEmail ] = useState("");
	const [ editFarmName, setEditFarmName ] = useState("");
	const [ editNumberOfFields, setEditNumberOfFields ] = useState<string>("");
	const [ editTotalAcres, setEditTotalAcres ] = useState<string>("");
	const [ editFarmAddress, setEditFarmAddress ] = useState<string>("");
	const globalDispatch = useGlobalDispatch();
	const { adminAddedCompany } = useGlobalState();

	useEffect(() => {
		// if we are in admin provision, we don't want to
		// prepopulate with administrator user values
		if (isInAdmin) { return; }

		const account = instance.getActiveAccount();
		console.log(account?.idToken || "no token");
		setEmail(account?.username || "");
		setUserName(account?.name || "");
		if (account?.idToken) {
			const idData = decodeJwt(account.idToken);
			setEditFirstName(idData?.given_name || "");
			setEditLastName(idData?.family_name || "");
			console.log(idData);
		}
	}, [ instance, userName, email, isInAdmin ]);

	const handleCompanyChange = (s: string) => setEditCompany(s);
	const handleLocationChange = (s: string) => setEditLocation(s);
	const handleFirstNameChange = (s: string) => setEditFirstName(s);
	const handleLastNameChange = (s: string) => setEditLastName(s);
	const handlePhoneChange = (s: string) => setEditPhone(s);
	const handleFarmName = (s: string) => setEditFarmName(s);
	const handleEmail = (s: string) => setEmail(s);


	const handleProvisionButton = async (): Promise<void> => {
		const account = instance.getActiveAccount();
		console.log(account);
		const setupInfo: ISetupTenant = {
			email, 
			username: userName,
			companyName: editCompany === "" ? editFarmName : editCompany,
			location: editLocation || "",
			phone: editPhone || "",
			firstName: editFirstName,
			lastName: editLastName,
			farmName: editFarmName,
			farmAddress: editFarmAddress,
			numberOfAcres: 0,
			numberOfFields: 0,
		};

		try {
			console.log("setupTenant");
			console.log(setupInfo);
			const tenantResult = await setupTenant(setupInfo);

			if (!tenantResult) {
				globalDispatch(globalSetError(t("api.noResponse"), t("info.errorTitle")));
			}
			else if (isSuccessfulTenantSetupResult(tenantResult)) {
				if (tenantResult.company && tenantResult.user) {
					authDispatch(setAuth(
						tenantResult.user,
						tenantResult.company,
						tenantResult.farmPermissions,
						tenantResult.isCompanyAdministrator,
						tenantResult.isGecoAdministrator
					));

					globalDispatch(globalSetAdminAddedCompany(true));
				}
			} else {
				const pd = tenantResult as IProblemDetails;
				console.log(pd);
				globalDispatch(globalSetError(t("setupTenant.error"), t("info.errorTitle")));
			}
		} catch (error: any) {
			globalDispatch(globalSetError(error.toString(), t("info.errorTitle")));
		}
	};

	const isCompanyMissing = !editCompany || editCompany === "";
	const isPhoneMissing = !editPhone || editPhone === "";
	const isEmailMissing = !email || email === "";
	const isFarmNameMissing = !editFarmName || editFarmName === "";
	const isNumFieldsInvalid = !editNumberOfFields || parseInt(editNumberOfFields) < 1;
	const isNumAcresInvalid = !editTotalAcres || parseInt(editTotalAcres) <= 0;
	const isFarmAddressMissing = !editFarmAddress || editFarmAddress === "";

	return (
		<Card p="4">
			<div>
				<Heading size={{ base: "xs", lg: "sm" }}>
					{ isInAdmin ? t("companySetup.sectionTitleAdmin") : t("companySetup.sectionTitle") }
				</Heading>
			</div>
			<Container>
				<Stack spacing="10" mt="10">
					<Heading size="xs">{t("companySetup.userInfoTitle")}</Heading>
					<FormControl>
						<Input
							id="firstName"
							size="lg"
							placeholder=" "
							data-peer
							value={editFirstName}
							onChange={e => handleFirstNameChange(e.target.value)}
						/>
						<FormLabel htmlFor="firstName" variant="floating" size="lg">
							{t("companySetup.labelFirstName")}
						</FormLabel>
					</FormControl>
					<FormControl>
						<Input
							id="lastName"
							size="lg"
							placeholder=" "
							data-peer
							value={editLastName}
							onChange={e => handleLastNameChange(e.target.value)}
						/>
						<FormLabel htmlFor="lastName" variant="floating" size="lg">
							{t("companySetup.labelLastName")}
						</FormLabel>
					</FormControl>
					<FormControl>
					{ /* put in a blank placeholder value */ }
						<Input
							id="email"
							size="lg"
							placeholder=" "
							data-peer
							value={email}
							isReadOnly={!isInAdmin}
							onChange={e => handleEmail(e.target.value)}
							isInvalid={isEmailMissing}
						/>
						<FormLabel htmlFor="email" variant="floating" size="lg">
							{t("companySetup.labelEmail")}
						</FormLabel>
						{ isEmailMissing && <FormHelperText color="red.300">{t("companySetup.emailIsRequired")}</FormHelperText> }
					</FormControl>
					{
						!isInAdmin &&
						<FormControl>
							<Input
								id="phone"
								size="lg"
								placeholder=" "
								data-peer
								value={editPhone}
								onChange={e => handlePhoneChange(e.target.value)}
								errorBorderColor="red.300"
								isInvalid={isPhoneMissing}
							/>
							<FormLabel htmlFor="phone" variant="floating" size="lg">
								{t("companySetup.labelPhone")}
							</FormLabel>
							{ isPhoneMissing && <FormHelperText color="red.300">{t("companySetup.phoneIsRequired")}</FormHelperText>}
						</FormControl>
					}
					{
						!isInAdmin &&
						<>
							<Heading size="xs">{t("companySetup.companyInfoTitle")}</Heading>
							<FormControl>
								{/* put in a blank placeholder value */}
								<Input
									id="companyName"
									size="lg"
									placeholder=" "
									data-peer value={editCompany}
									onChange={e => handleCompanyChange(e.target.value)}
								/>
								<FormLabel htmlFor="companyName" variant="floating" size="lg">
									{t("companySetup.labelCompanyName")}
								</FormLabel>
								<FormHelperText>{t("companySetup.defaultToFarmName")}</FormHelperText>
							</FormControl>
							<FormControl>
								<Input
									id="location"
									size="lg"
									placeholder=" "
									data-peer value={editLocation}
									onChange={e => handleLocationChange(e.target.value)}
								/>
								<FormLabel htmlFor="location" variant="floating" size="lg">
									{t("companySetup.labelLocation")}
								</FormLabel>
								<FormHelperText>{t("companySetup.locationIsOptional")}</FormHelperText>
							</FormControl>
						</>
					}

					<Heading size="xs">{t("companySetup.farmInfoTitle")}</Heading>
					<FormControl>
						<Input
							id="farmName"
							size="lg"
							placeholder=" "
							data-peer
							value={editFarmName}
							onChange={e => handleFarmName(e.target.value)}
							isInvalid={isFarmNameMissing}
						/>
						<FormLabel htmlFor="farmName" variant="floating" size="lg">
							{t("companySetup.labelFarmName")}
						</FormLabel>
						{ isFarmNameMissing && <FormHelperText color="red.300">{t("companySetup.farmNameIsRequired")}</FormHelperText>}
					</FormControl>

					<UpdateButton isLoading={isLoading} onClick={handleProvisionButton} caption={t("companySetup.buttonSave") || "save"} />
				</Stack>
			</Container>
		</Card>
	);
};

