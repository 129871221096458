import React, { useEffect, useState } from "react";
import {
	Box,
	Flex,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Heading,
	Input,
	Progress,
	Card,
	Accordion,
	AccordionItem,
	AccordionPanel,
	AccordionButton,
	AccordionIcon,
	Icon
} from "@chakra-ui/react";
import { useForm, UseFormRegisterReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AddButton } from "../components/AddButton";
import { Layout } from "../components/layout/Index";
import { setSelectedFarm, setTenantAuthData, useTenantDispatch, useTenantState } from "../contexts/TenantContext";
import { useAuthorizationState } from "../contexts/AuthorizationContext";
import { useApi } from "../services";
import { Config } from "../config/Config";
import { isIUserTenantData } from "../models";
import { TitleAndNav } from "../components/TitleAndNav";
import { FarmEditor } from "../components/FarmEditor";
import { FieldList } from "../components/FieldListProps";
import { AddFieldForm } from "../components/AddFieldForm";
import { AddIcon, CopyIcon, EditIcon } from "@chakra-ui/icons";


export const FarmPage = (): JSX.Element => {
	const { selectedFarm } = useTenantState();
	const { company } = useAuthorizationState();
	const { isLoading, loadTenant } = useApi(Config.baseUrl);
	const tenantDispatch = useTenantDispatch();
	const [ needsUpdate, setNeedsUpdate ] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		const load = async (id: string) => {
			const td = await loadTenant();
			console.log(td);
			if (isIUserTenantData(td)) {
				tenantDispatch(setTenantAuthData(td.farms, td.authInfo));
				console.log(td.farms[0]);
				tenantDispatch(setSelectedFarm(td.farms[0]));
			}
		};

		const qps = new URLSearchParams(window.location.search);
		if (!selectedFarm && qps.has("id")) {
			console.log("loading tenant data");
			load(qps.get("id") || "");
		}
	}, []);

	const onSave = (): void => {
		setNeedsUpdate(true);
	};

	useEffect(() => {
		console.log("inside need update");
		const load = async (id: string) => {
			console.log("loading tenant");
			const td = await loadTenant();
			console.log(td);
			if (isIUserTenantData(td)) {
				tenantDispatch(setTenantAuthData(td.farms, td.authInfo));
				console.log("updating selected farm");
				console.log(td.farms[0]);
				tenantDispatch(setSelectedFarm(td.farms[0]));
			}
		};

		if (needsUpdate) {
			console.log("needs an update");
			setNeedsUpdate(false);
			load(selectedFarm?.id.toString() || "0");
		}
	}, [ needsUpdate ]);

	return (
		<Layout>
			<>
				<TitleAndNav to="/farms" title={`${company?.name} - ${selectedFarm?.name}`} />
				{
					isLoading &&
					<Box mt="1em">
						<Progress isIndeterminate hasStripe colorScheme="blue" size="lg" />
					</Box>
				}
				{
					!isLoading &&
					<>
						<Heading size="xs" mt="2em">
							<EditIcon mr="0.5em" />
							{selectedFarm?.name || "no name"}
							{t("farm.edit")}
						</Heading>
						<FarmEditor mt="0.5em" selectedFarm={selectedFarm} onSave={() => onSave()} />
					</>				
				}
				{
					!isLoading &&
					<>
						<Box mt="1em">
							<Heading size="xs" mb="0.5em">
								<CopyIcon mr="0.5em" />
								{t("farm.fieldList")}
							</Heading>
							<FieldList fields={selectedFarm?.fields || []} />
						</Box>
					</>
				}
				{
					!isLoading &&
					<Accordion allowToggle mt="10">
						<AccordionItem>
							<AccordionButton>
								<Box as="span" flex="1" textAlign="left">
									<Heading size="xs">
										<AddIcon mr="0.5em" />
										{t("farm.addField")}
									</Heading>
								</Box>
								<AccordionIcon />
							</AccordionButton>
							<AccordionPanel>
								<AddFieldForm
									companyId={selectedFarm?.companyId || 0}
									farmId={selectedFarm?.id || 0} mt="10"
									onSave={() => onSave()}
								/>
							</AccordionPanel>
						</AccordionItem>
					</Accordion>
				}
			</>
		</Layout>
	);
};
