import { IGlobalState, initialGlobalState } from "./IGlobalState";
import { GlobalAction, GlobalActionTypes } from "./actions";

export const reducer = (state: IGlobalState = initialGlobalState, action: GlobalAction): IGlobalState => {
	switch (action.type) {
		case GlobalActionTypes.CLEAR_ERROR:
			return {
				...state,
				errorMessage: "",
				errorTitle: "",
				isErrorSet: false,
				isInfo: false,
			};
		case GlobalActionTypes.SET_ERROR:
			return {
				...state,
				errorMessage: action.errorMessage || "Unknown error occurred",
				errorTitle: action.errorTitle || "Error",
				isErrorSet: true,
				duration: action.duration || null,
				isInfo: action.isInfo,
			};
		case GlobalActionTypes.SET_INFO:
			return {
				...state,
				errorMessage: action.errorMessage || "Unknown error occurred",
				errorTitle: action.errorTitle || "Error",
				isErrorSet: true,
				duration: action.duration || null,
				isInfo: action.isInfo,
			};
		case GlobalActionTypes.SET_ADMIN_ADDED_COMPANY:
			return {
				...state,
				adminAddedCompany: action.adminAddedCompany || state.adminAddedCompany,
			};
		default:
			return state;
	}
};
