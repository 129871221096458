import React from "react";
import { Button, Link, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link as ReactLink } from "react-router-dom";



export const BottomOfPageContactUs = (): JSX.Element => {
	const { t } = useTranslation();


	return (
		<Stack direction={{ base: "column", md: "row" }} spacing="3" mt="1em" justifyContent="center">
			<Link as={ReactLink} to="/contact-us">
				<Button variant="primary" size={{ base: "lg", md: "xl" }}>
					{t("landing.contactUsLink")}
				</Button>
			</Link>
		</Stack>
	);
};

