import React, { ChangeEvent, useState } from "react";
import { Box, BoxProps, Card, Input, position } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import shortId from "shortid";


import "./DropZonePreview.scss";
import { DropzonePreviewItem, IDropzonePreviewItem } from "./DropZonePreviewItem";


export interface IFileListExt {
	id: string;
	file: File;
}


interface IProps extends BoxProps {
	updateFiles?: (tfs: IFileListExt[]) => void;
	deleteFile?: (id: string) => void;
	setFiles: React.Dispatch<React.SetStateAction<IDropzonePreviewItem[]>>;
	files: IDropzonePreviewItem[];
}


export const DropzonePreview = ({ files, setFiles, updateFiles, deleteFile, ...rest }: IProps): JSX.Element => {
	const { t } = useTranslation();
	//const [ files, setFiles ] = useState<IDropzonePreviewItem[]>([]);

	const calcFileSize = (bytes: number, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

	const fileInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
		const tfs = e.target.files;
		if (!tfs) { return; }
		const images: File[] = [];

		for (let i = 0; i < tfs.length; i++) {
			const fileId = shortId.generate();
			images.push(tfs[i]);
			const reader = new FileReader();
			reader.onloadend = () => {

				setFiles(prevState => {
					return [
						...prevState,
						{
							id: shortId.generate(),
							fileSize: calcFileSize(tfs[i].size),
							fileImage: reader.result,
							filename: tfs[i].name,
							filetype: tfs[i].type,
							datetime: new Date(tfs[i].lastModified),
							alt: "",
							file: tfs[i],
						},
					]
				});
			};
			if (tfs[i]) {
				reader.readAsDataURL(tfs[i]);
				if (updateFiles) {
					const ext: IFileListExt = {
						file: tfs[i],
						id: fileId,
					};
					console.log(ext);
					updateFiles && updateFiles([ ext ]);
				}
			}
		}

		console.log(files);
	};

	const removeFile = (id: string): void => {
		console.log(id);
		deleteFile && deleteFile(id);
		const newFileList = files.filter(f => f.id !== id);
		setFiles(newFileList);
	};

	return (
		<Box {...rest}>
			<Box className="file-upload-box">
				<input type="file" className="file-upload-input" onChange={fileInputChange} multiple  />
				<Box as="span">
					{t("dropzonePreview.instructions1")}<Box as="span" className="file-link">{t("dropzonePreview.instructions2")}</Box>
				</Box>

			</Box>
			{
				files.map((f, i) => (
					<DropzonePreviewItem
						id={f.id}
						key={i.toString()}
						fileImage={f.fileImage}
						alt=""
						filename={f.filename}
						filetype={f.filetype}
						datetime={f.datetime}
						fileSize={f.fileSize}
						deleteHandler={removeFile}
						file={f.file}
					/>
				))
			}
		</Box>
	);
};
