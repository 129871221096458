import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Progress } from "@chakra-ui/react";


import { useAuthorization } from "../services";
import { Layout } from "../components/layout/Index";
import { Config } from "../config/Config";
import { TitleAndNav } from "../components/TitleAndNav";
import { ICompany, IField, IFieldAllData, IFieldYearData, IFolderContents, isIAllFieldData } from "../models";
import { useApi } from "../services";
import { useAuthorizationState } from "../contexts/AuthorizationContext";
import { FoldersList } from "../components/FolderFileList";
import { FieldDetails } from "../components/FieldDetails";

export const AdministratorFieldPage = (): JSX.Element => {
	const { t } = useTranslation();
	const auth = useAuthorization();
	const [ isAdmin, setIsAdmin ] = useState(false);
	const { isLoading, loadFieldData } = useApi(Config.baseUrl);
	const [ field, setField ] = useState<IField | undefined>(undefined);
	const [ company, setCompany ] = useState<ICompany | undefined>(undefined);
	const { isGecoAdmin, isCompanyAdmin } = useAuthorizationState();
	const [ needsUpdate, setNeedsUpdate ] = useState(false);
	const [ gecoPrivateFolderFiles, setGecoPrivateFolderFiles ] = useState<IFolderContents[]>([]);
	const [ folderFiles, setFolderFiles ] = useState<IFolderContents[]>([]);
	const [ fieldHistory, setFieldHistory ] = useState<IFieldYearData[]>([]);


	useEffect(() => {
		setIsAdmin(auth.isAdministrator());
		console.log(auth.isAdministrator());
	}, [ setIsAdmin, auth ]);

	useEffect(() => {
		const load = async (id: number) => {
			const data = await loadFieldData(id);
			if (isIAllFieldData(data)) {
				const fd = data as IFieldAllData;
				setField(fd.field);
				setFolderFiles(fd.folderFiles);
				setGecoPrivateFolderFiles(fd.folderFiles);
				setFieldHistory(fd.history);
			}
		};

		const qps = new URLSearchParams(window.location.search);
		if (qps.has("id")) {
			console.log("loading tenant data");
			load(parseInt(qps.get("id") || "0"));
		}
	}, []);


	useEffect(() => {
		const load = async (field: number) => {
			const data = await loadFieldData(field);
			if (isIAllFieldData(data)) {
				const fd = data as IFieldAllData;
				setField(fd.field);
				setFolderFiles(fd.folderFiles);
				setGecoPrivateFolderFiles(fd.folderFiles);
				setFieldHistory(fd.history);
			}
		};

		const urlParams = new URLSearchParams(window.location.search);
		const idString = urlParams.get("id");
		if (idString) {
			load(parseInt(idString));
		}
		
	}, [ needsUpdate ]);



	const onDelete = () => setNeedsUpdate(true);

	if (!isAdmin) {
		return (<div>{t("adminPage.notPermitted")}</div>);
	}

	return (
		<Layout>
			<div>
				<TitleAndNav to={`/adminCompany?id=${field?.companyId}&farm=${field?.farmId}`} title={field?.name || t("field.noFieldData")} />
				{
					!isLoading &&
					<>
						<Box mt="1em">
							<FieldDetails field={field} company={company} history={fieldHistory} />
						</Box>
						<FoldersList
							mt="2em"
							title={t("fieldFiles.custFilesTitle")}
							field={field}
							company={company}
							folders={folderFiles}
							isGecoAdmin={isGecoAdmin}
							isCompanyAdmin={isCompanyAdmin}
							onDelete={onDelete}
						/>
						{
							isGecoAdmin &&
							<FoldersList
								mt="2em"
								title={t("fieldFiles.gecoFilesTitle")}
								field={field}
								company={company}
								folders={gecoPrivateFolderFiles}
								isGecoAdmin={isGecoAdmin}
								isGecoPrivate={true}
								onDelete={onDelete}
							/>
						}
					</>
				}
				{
					isLoading &&
					<Box mt="1em" p="5em">
						<Progress isIndeterminate hasStripe colorScheme="blue" size="lg" />
					</Box>
				}
			</div>
		</Layout>
	);
};

