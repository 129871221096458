import React, { useEffect, useState } from "react";
import {
	Box,
	BoxProps,
	Container,
	Heading,
	Stack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { IArticle } from "../../models";
import { useApi } from "../../services";
import { Config } from "../../config/Config";
import { Article } from "./Article";


export const NewsList = ({...rest}: BoxProps): JSX.Element => {
	const { t } = useTranslation();
	const [ articles, setArticles ] = useState<IArticle[]>([]);
	const { isLoading, getArticles } = useApi(Config.baseUrl);

	useEffect(() => {
		const load = async () => {
			const articleList = await getArticles(100);
			setArticles(articleList);
		}

		load();
	}, [ ]);

	return (
		<Box as="section" py={{ base: "16", md: "24" }}>
			<Heading size="sm" mb="1em">
				{t("articles.heading")}
			</Heading>
			<Container>
				{
					!isLoading &&
					<Stack direction={{ base: "column", lg: "row" }} spacing="16">
						{
							articles.map((article) => <Article key={article.id} a={article} />)
						}
					</Stack>
				}
			</Container>
		</Box>
	);
};

