import { Container, Divider, Stack, Text } from '@chakra-ui/react'


export const Footer = () => (
    <Container as="footer" role="contentinfo" py={{ base: '12', md: '16' }}>
        <Divider borderColor="bg.accent.subtle" />
        <Stack
            pt="8"
            pb="12"
            justify="space-between"
            direction={{ base: 'column-reverse', md: 'row' }}
            align="center"
        >
            <Text fontSize="sm" color="fg.accent.subtle">
                &copy; {new Date().getFullYear()} Geco Engineering, Inc. All rights reserved.
            </Text>
        </Stack>
    </Container>
)