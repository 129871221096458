import { useTranslation } from "react-i18next";

import { GetStartedSteps } from "../components/marketing-layout/GetStartedSteps"
import { CallToAction } from "../components/marketing-layout/CallToAction";
import { MarketingLayout } from "../components/marketing-layout";


export const MarketingGetStarted = () => {
	const { t } = useTranslation();

	return (
		<MarketingLayout>
			<>
				<CallToAction
					headingTitle={t("getStarted.ctaTitle")}
					headingDescription={t("getStarted.ctaDescription")}
					leftButtonLink="/contact-us"
					leftButtonText={t("getStarted.ctaLeftButton") || "Contact Us"}
					rightButtonLink="/about-us"
					rightButtonText={t("getStarted.ctaRightButton") || "About Us"}
					ctaImageUrl="get-started-cta.png"
					ctaImageAlt={t("getStarted.ctaImageAlt") || "Get Started Image"}
				/>
				<GetStartedSteps />
			</>
		</MarketingLayout>
	);
};
