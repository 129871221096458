import React, { Dispatch, createContext, useReducer, useContext } from "react";

import { IAdminState, initialAdminState } from "./IAdminState";
import { AdminAction } from "./actions";
import { reducer } from "./reducer";


const AdminStateContext = createContext<IAdminState | undefined>(undefined);
const AdminDispatchContext = createContext<Dispatch<AdminAction> | undefined>(undefined);


interface IProps {
	data?: IAdminState;
	children: JSX.Element;
}

export const AdminProvider = ({ data = initialAdminState, children }: IProps): JSX.Element => {
	const [ state, dispatch ] = useReducer(reducer, data);

	return (
		<AdminStateContext.Provider value={state}>
			<AdminDispatchContext.Provider value={dispatch}>
				{children}
			</AdminDispatchContext.Provider>
		</AdminStateContext.Provider>
	);
};


export const useAdminState = (): IAdminState => {
	const context = useContext(AdminStateContext);
	if (context === undefined) { throw new Error("must be used within AdminProvider"); }
	return context;
};

export const useAdminDispatch = (): Dispatch<AdminAction> => {
	const context = useContext(AdminDispatchContext);
	if (context === undefined) { throw new Error("must be used within AdminProvider"); }
	return context;
};

